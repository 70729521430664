import { MergeTag } from '@phrasee/phrasee-typings'

import { MergeTagListItem } from './api'

export const mapBEMergeTag = (mergeTag: MergeTag): MergeTagListItem => ({
  id: mergeTag._id,
  name: mergeTag.name,
  description: mergeTag.description,
  integration: mergeTag.integration,
  category: mergeTag.category,
  code: mergeTag.code,
  lastModified: mergeTag.last_updated,
  created: mergeTag.created,
  exampleValues: mergeTag.example_values || [],
})

export const mapMergeTag = (mergeTag: MergeTagListItem): MergeTag => ({
  _id: mergeTag.id,
  name: mergeTag.name,
  description: mergeTag.description,
  integration: mergeTag.integration,
  category: mergeTag.category,
  code: mergeTag.code,
  last_updated: mergeTag.lastModified,
  example_values: mergeTag.exampleValues,
  created: mergeTag.created,
})
