import {
  components,
  GroupBase,
  MultiValueProps,
  PlaceholderProps,
} from 'react-select'

import { Add as AddIcon } from 'common/icons'

import type { MultiSelectProps } from '../MultiSelect/MultiSelect'
import MultiSelect, { MultiSelectValue } from '../MultiSelect/MultiSelect'
import Tag from '../tags/Tag'
import Tooltip from '../Tooltip'

import { customStyles } from './InputComboxStyles'

type Props<T> = MultiSelectProps<T>

const PlaceholderComponent = <T,>(
  props: PlaceholderProps<T, true, GroupBase<T>>
) => {
  // @ts-ignore
  const { isFocused } = props.selectProps

  if (isFocused) {
    return <></>
  }
  return (
    <components.Placeholder {...props}>
      <AddIcon isDefaultColor={false} size={4} />
      <span className="ml-2">Add</span>
    </components.Placeholder>
  )
}

const MultiValueComponent = <T,>(
  props: MultiValueProps<T, true, GroupBase<T>>
) => {
  const { label, value, tooltip } = props.data as any

  return (
    <Tooltip overlay={tooltip} placement="top" show={!!tooltip}>
      <Tag
        label={label}
        value={value}
        className="mr-2"
        onRemoveClick={props.removeProps.onClick}
      />
    </Tooltip>
  )
}

const InputCombobox = (props: Props<MultiSelectValue>) => {
  return (
    <MultiSelect<MultiSelectValue>
      {...props}
      isFooterHidden={true}
      MultiValueComponent={MultiValueComponent}
      PlaceholderComponent={PlaceholderComponent}
      customStyles={customStyles()}
      classNames={{
        indicatorsContainer: () => 'hidden',
        control: () => 'border-gold-400 bg-gold-40',
      }}
    />
  )
}

export default InputCombobox
