import { useStackOneHub } from '@stackone/react-hub'
import { useFlags } from 'launchdarkly-react-client-sdk'

import type { IntegrationProvider } from 'common/api/integrationsApi'
import { getIntegrationName } from 'common/cepUtils'
import { useAppSelector } from 'common/hooks/redux'
import { getSelectedAccountName } from 'features/auth/store/authSlice'

import { CepTemplate } from '../../api'
import useCreateIntegration from '../../api/mutations/useCreateIntegration'
import useSetupIntegrationConnection from '../../api/mutations/useSetupIntegrationConnection'

import DefaultIntegrationCard from './integrationCard/DefaultIntegrationCard'
import IntegrationCardCepIntegration from './integrationCard/IntegrationCardCepIntegration'
import { CepIntegration } from './AccountsPage'
import IntegrationCard from './integrationCard'

type Props = {
  filteredIntegrations?: CepTemplate[] | IntegrationProvider[]
  onSuccess: () => void
}

const IntegrationGrid = ({ children }) => (
  <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6">
    {children}
  </div>
)

const CepIntegrationCards = ({ integrations, onSubmit }) => {
  return integrations.map((cep) => (
    <IntegrationCardCepIntegration
      cep={cep}
      key={cep._id}
      name={getIntegrationName(cep.cep_name)}
      className="px-6 pb-3 pt-4"
      onSubmit={onSubmit}
    />
  ))
}

const ProviderIntegrationCards = ({
  integrations,
  isS1NameEnabled,
  onSubmit,
  isLoading,
  currentProvider,
}) => {
  return integrations.map(({ name, logoUrl, id }) => {
    if (isS1NameEnabled) {
      return (
        <IntegrationCard
          key={id}
          name={name}
          logoUrl={logoUrl}
          onSubmit={(label, onClose) => onSubmit(id, label, onClose)}
          isLoading={isLoading && id === currentProvider}
        />
      )
    }

    return (
      <DefaultIntegrationCard
        key={id}
        name={name}
        logoUrl={logoUrl}
        onClick={() => onSubmit(id, '', () => {})}
      />
    )
  })
}

const IntegrationsList = ({ filteredIntegrations, onSuccess }: Props) => {
  const flags = useFlags()
  const isSimplifiedProjectIntegrationOn = flags?.simplifiedProjectIntegrations
  const { mutate, isLoading, variables } = useSetupIntegrationConnection()
  const { mutate: mutateIntegrationCep } = useCreateIntegration()

  const { startConnect } = useStackOneHub()
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const accountName = useAppSelector(getSelectedAccountName)
  const firstName = useAppSelector((state) => state.authStates.firstName)
  const lastName = useAppSelector((state) => state.authStates.lastName)

  const onClick = async (id: string, label: string, onClose: () => void) => {
    if (accountName) {
      mutate(
        {
          provider: id,
          accountId,
          accountName,
          userName: `${firstName} ${lastName}`,
          label,
        },
        {
          onSuccess: (sessionToken) => {
            onClose?.()
            startConnect({
              sessionToken,
              onSuccess,
            })
          },
        }
      )
    }
  }

  const onClickCepIntegration = (payload: any) => {
    const integration: CepIntegration = {
      name: payload.name,
      account_id: accountId,
      cep_name: payload.cep_name,
      distribution_channel: payload.distributionChannel,
      type_project: 'experiment',
      message_type: payload.messageType,
      testing_methodology: payload.testingMethod,
      credentials: payload.integrationCredentials,
      created_at: new Date().toISOString(),
      tracking_metrics:
        payload?.trackingMetrics.map((tracking) => tracking.value) || [],
    }
    mutateIntegrationCep(
      {
        ...integration,
      },
      {
        onSuccess: () => {
          onSuccess?.()
        },
      }
    )
  }

  return (
    <IntegrationGrid>
      {isSimplifiedProjectIntegrationOn ? (
        <CepIntegrationCards
          integrations={filteredIntegrations as CepTemplate[]}
          onSubmit={onClickCepIntegration}
        />
      ) : (
        <ProviderIntegrationCards
          integrations={filteredIntegrations as IntegrationProvider[]}
          isS1NameEnabled={flags?.s1NameIntegration}
          onSubmit={onClick}
          isLoading={isLoading}
          currentProvider={variables?.provider}
        />
      )}
    </IntegrationGrid>
  )
}

export default IntegrationsList
