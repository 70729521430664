import { FC, useEffect } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'

import FormItem from 'common/components/formItem'
import Select from 'common/components/Select'
import { default as Option } from 'common/components/SelectOption'
import { useAppSelector } from 'common/hooks/redux'
import useListAccountsIntegrationQuery from 'features/admin/accountSettings/api/queries/useListAccountsIntegrationQuery'
import { ListCepIntegrationResponse } from 'features/admin/accountSettings/integrations/components/AccountsPage'

import FormCard, { FormCardTitle } from '../formCard'

type Props = {
  accountsIntegration?: ListCepIntegrationResponse
}

const CepIntegrationCard: FC<Props> = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const { data: accountsIntegration } =
    useListAccountsIntegrationQuery(accountId)

  const formState = useFormState()
  const { change: formChange } = useForm()
  const { cepIntegration } = formState.values

  useEffect(() => {
    if (cepIntegration) {
      formChange('cepIntegration', cepIntegration)
    }
  }, [cepIntegration, formChange])

  return (
    <FormCard data-cy="cep-integration-card" data-testid="cep-integration-card">
      <FormCardTitle title="My Integration" />
      <Field<any> name="cepIntegrationId">
        {({ input, meta }) => (
          <FormItem label="Integration">
            <Select
              className="w-full"
              placeholder="Select..."
              value={input.value}
              onChange={(integrationId) => {
                formChange('cepIntegrationId', integrationId)
              }}
            >
              {accountsIntegration?.map((value) => (
                <Option key={value._id} value={value?._id!}>
                  {value.name}
                </Option>
              ))}
            </Select>
          </FormItem>
        )}
      </Field>
    </FormCard>
  )
}

export default CepIntegrationCard
