import {
  Adobe,
  Bloomreach,
  Braze,
  CircleSlash,
  Cordial,
  Emarsys,
  Epsilon,
  Ibm,
  Iterable,
  Leanplum,
  MessageGears,
  Moengage,
  Movableink,
  Optimizely,
  Oracle,
  Sailthru,
} from 'common/icons'
import { integrations as integrationsConstants } from 'features/admin/accountSettings/mergeTags/constants'

export const mapCepToIcon = (cep: string, size: number = 10) => {
  switch (cep) {
    case 'braze':
      return <Braze size={size} />
    case 'adobe':
      return <Adobe size={size} />
    case 'leanplum':
      return <Leanplum size={size} />
    case 'ibm':
      return <Ibm size={size} />
    case 'message_gears':
      return <MessageGears size={size} />
    case 'bloomreach':
      return <Bloomreach size={size} />
    case 'cordial':
      return <Cordial size={size} />
    case 'emarsys':
      return <Emarsys size={size} />
    case 'epsilon':
      return <Epsilon size={size} />
    case 'optimizely':
      return <Optimizely size={size} />
    case 'movable_ink':
      return <Movableink size={size} />
    case 'sailthru':
      return <Sailthru size={size} />
    case 'iterable':
      return <Iterable size={size} />
    case 'responsys':
      return <Oracle size={size} />
    case 'moengage':
      return <Moengage size={size} />
    default:
      return <CircleSlash size={size} />
  }
}

export const getIntegrationName = (cepName: string) => {
  const integration = integrationsConstants.find(
    (integration) => integration.value === cepName
  )
  return integration?.label ?? cepName
}
