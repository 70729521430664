import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { listAccounts } from '../integrationsApi'
import { integrationsKeys } from '../queryKeys/integrationsKeys'

const useListAccountsQuery = (
  accountId: string,
  options?: UseQueryOptions,
  provider?: string
) => {
  const getIntegrationsQuery = useQuery(
    integrationsKeys.accounts(accountId, provider),
    () => listAccounts({ accountId, provider }),
    { enabled: options?.enabled }
  )
  return getIntegrationsQuery
}

export default useListAccountsQuery
