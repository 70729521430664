import React, { ReactNode } from 'react'
import cx from 'classnames'
import isString from 'lodash/isString'

import {
  CrossedCircle as CrossedCircleIcon,
  Square as SquareIcon,
} from 'common/icons'

export type IconTitleGroupProps = {
  className?: string
  title: string | ReactNode
}

const IconTitleGroup = ({ className, title }: IconTitleGroupProps) => {
  return (
    <div className={cx('flex items-center justify-start', className)}>
      <SquareIcon
        isDefaultColor={false}
        className="w-3 h-3 text-base-700 mr-1"
      />
      <CrossedCircleIcon
        isDefaultColor={false}
        className="w-3 text-base-700 mr-1"
      />
      {isString(title) ? (
        <p
          className="text-sm font-medium text-base-700 uppercase"
          data-testid="title"
        >
          {title}
        </p>
      ) : (
        title
      )}
    </div>
  )
}

export default IconTitleGroup
