import {
  Connector,
  ConnectSessionsRequest,
  ConnectSessionsResponse,
  IntegrationAccount,
  MergeTag,
} from '@phrasee/phrasee-typings'

import { connectInternalApi } from 'common/api'
import type { IntegrationProvider } from 'common/api/integrationsApi'

import { ListCepIntegrationResponse } from '../integrations/components/AccountsPage'

import { mapBEMergeTag } from './helpers'

export const getAvailableIntegrations = async (): Promise<
  IntegrationProvider[]
> => {
  const result = await connectInternalApi.get<Connector[]>(
    'v1/content-integrations/connectors'
  )

  return result.data.map((integration) => ({
    id: integration.provider,
    name: integration.provider_name,
    category: integration.category,
    logoUrl: integration.resources?.images?.logo_url,
  }))
}

export const getReactHubToken = async ({
  provider,
  accountId,
  accountName,
  userName,
  label,
}: ConnectSessionsRequest): Promise<string> => {
  const result = await connectInternalApi.post<ConnectSessionsResponse>(
    'v1/content-integrations/connect_sessions',
    {
      provider,
      accountId,
      accountName,
      userName,
      label,
    }
  )

  return result.data.token
}

export const updateIntegration = async ({
  id,
  label,
}: Pick<IntegrationAccount, 'id' | 'label'>) => {
  const result = await connectInternalApi.patch<ConnectSessionsResponse>(
    `v1/content-integrations/accounts/${id}`,
    { label }
  )

  return result.data.token
}

export const deleteIntegration = async ({
  id,
}: Pick<IntegrationAccount, 'id'>) => {
  const result = await connectInternalApi.delete<IntegrationAccount>(
    `v1/content-integrations/accounts/${id}`
  )

  return result.data
}

export type MergeTagListItem = {
  id: string
  name: string
  description: string
  integration: string
  category: string
  code: string
  created: string
  lastModified: string
  exampleValues?: string[]
}

export const getMergeTags = async ({
  accountId,
}: {
  accountId: string
}): Promise<MergeTagListItem[]> => {
  const result = await connectInternalApi.get<MergeTag[]>(
    `v1/core/monorail/accounts/${accountId}/merge-tags`
  )

  return result.data.map(mapBEMergeTag)
}

export const getSystemMergeTags = async (): Promise<MergeTagListItem[]> => {
  const result = await connectInternalApi.get<MergeTag[]>(
    `v1/core/monorail/merge-tag-templates`
  )

  return result.data.map(mapBEMergeTag)
}

export const createMergeTag = async ({
  accountId,
  name,
  description,
  category,
  code,
  example_values,
  integration,
}: Omit<MergeTag, '_id' | 'last_updated' | 'created'> & {
  accountId: string
}) => {
  await connectInternalApi.post(
    `v1/core/monorail/accounts/${accountId}/merge-tags`,
    {
      name,
      description,
      category,
      code,
      example_values,
      integration,
    }
  )
}

export const deleteMergeTag = async ({
  tagId,
  accountId,
}: {
  tagId: string
  accountId: string
}) => {
  await connectInternalApi.delete(
    `v1/core/monorail/accounts/${accountId}/merge-tags/${tagId}`
  )
}

export const updateMergeTag = async ({
  accountId,
  name,
  description,
  code,
  example_values,
  tagId,
}: Omit<MergeTag, '_id' | 'last_updated' | 'category' | 'integration'> & {
  accountId: string
  tagId: string
}) => {
  await connectInternalApi.patch(
    `v1/core/monorail/accounts/${accountId}/merge-tags/${tagId}`,
    {
      name,
      description,
      code,
      example_values,
    }
  )
}

export type SelectionMetricObject = {
  label?: string
  value?: string
}

export type DistributionTypeObject = {
  label?: string
  value?: string
}

export type TrackingMetricObject = {
  label?: string
  value?: string
  default?: boolean
}

export type CepTemplate = {
  _id: any
  cep_name: string
  distribution_channel: DistributionChannel[]
}

export type TestingMethodology = {
  dynamic_realtime?: any
  test_and_send?: any
}

export type DistributionChannel = {
  name: string
  type_project: string[]
  message_type: DistributionTypeObject[]
  selection_metrics: SelectionMetricObject[]
  tracking_metrics: TrackingMetricObject[]
  testing_methodology: TestingMethodology[]
}

export const getCepTemplate = async (
  accountId: string
): Promise<CepTemplate[]> => {
  return (
    await connectInternalApi.get(
      `v1/core/marge/accounts/${accountId}/ceptemplate`
    )
  ).data.response.data
}

export const createIntegration = async (payload: any) => {
  await connectInternalApi.post(
    `v1/core/marge/accounts/${payload.account_id}/integration`,
    payload
  )
}

export const listAccountIntegrations = async (accountId: string) => {
  const result = await connectInternalApi.get<ListCepIntegrationResponse>(
    `v1/core/marge/accounts/${accountId}/integrations`
  )

  return result.data
}
