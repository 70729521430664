import { Link } from 'react-router-dom'
import { generatePath } from 'react-router-dom'
import cx from 'classnames'

import ButtonWithTooltip from 'common/components/ButtonWithTooltip'
import { useAppSelector } from 'common/hooks/redux'
import { Add as AddIcon } from 'common/icons'

import { PAGES } from '../interfaces'

interface Props {
  selectedPagePath?: string
}

// TODO: Reuse components from UF.
const PagesColumn = ({ selectedPagePath }: Props) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  return (
    <div className="border-r border-gold-400 shrink-0 pr-6 flex flex-col gap-2">
      <h1 className="text-2xl font-medium text-base-700 whitespace-nowrap tracking-tight mb-6">
        Reporting Dashboard
      </h1>
      <div className="flex items-center justify-between py-1">
        <h2 className="px-2 text-sm font-medium">PAGES</h2>
        <ButtonWithTooltip
          tooltip
          tooltipText="Add new page"
          size="small"
          prefixIcon={
            <AddIcon
              isDefaultColor={false}
              className="text-gold-600"
              size={4}
            />
          }
        />
      </div>
      {PAGES.map((page) => (
        <Link
          key={page.path}
          to={generatePath(page.path, { accountId })}
          className={cx(
            'py-1 px-2 cursor-pointer text-sm text-base-700 font-normal',
            {
              'bg-yellow-200': selectedPagePath === page.path,
              'hover:bg-yellow-50': selectedPagePath !== page.path,
            }
          )}
        >
          {page.name}
        </Link>
      ))}
    </div>
  )
}

export default PagesColumn
