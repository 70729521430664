import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { getAvailableIntegrations } from '../api'
import { integrationsKeys } from '../queryKeys'

const useGetAvailableIntegrationsQuery = (options?: UseQueryOptions) => {
  const getIntegrationsQuery = useQuery(
    integrationsKeys.list(),
    getAvailableIntegrations,
    { enabled: options?.enabled }
  )
  return getIntegrationsQuery
}

export default useGetAvailableIntegrationsQuery
