import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { fetchMilestoneMetrics } from '../api'
import { contentKeys } from '../queryKeys'

const useGetMilestoneMetricsQuery = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const getMilestoneMetricsQuery = useQuery(
    contentKeys.milestoneMetrics(accountId),
    () => fetchMilestoneMetrics(accountId),
    {
      enabled: !!accountId,
    }
  )

  return getMilestoneMetricsQuery
}

export default useGetMilestoneMetricsQuery
