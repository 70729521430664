import { useMemo, useState } from 'react'

import Autocomplete from 'common/components/autocomplete'
import Button from 'common/components/button'
import Catalog from 'common/components/catalog/Catalog'
import ConfirmationModal from 'common/components/confirmationModal/ConfirmationModal'
import EmptyState from 'common/components/emptyState'
import Loader from 'common/components/loaders/Loader'
import SingleSelect from 'common/components/singleSelect'
import Table, { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import { errorToast } from 'common/components/toastNotification'
import { formatDate } from 'common/helpers/date'
import { useAppSelector } from 'common/hooks/redux'

import useGetProductCatalogCategoriesQuery, {
  ProductCatalog,
} from '../api/queries/useGetProductCatalogCategoriesQuery'

type Props = {
  catalogId: string
  productCatalogs?: {
    name: string
    id: string
    createdAt: string
    lastUpdated: string
    region: string
  }[]
  onCatalogChange?: (catalogId: string) => void
  onUploadClick?: () => void
}

const ProductCatalogTable = ({
  catalogId,
  productCatalogs = [],
  onCatalogChange,
  onUploadClick,
}: Props) => {
  // Search will be added in https://phraseephoundry.atlassian.net/browse/HG-4689
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchValue, setSearchValue] = useState<string | undefined>()

  const isUploading = useAppSelector(
    (state) => state.dataSource.fileStates.product.isUploading
  )

  const productCatalogQuery = useGetProductCatalogCategoriesQuery({
    catalogId,
  })
  const productCatalog = productCatalogQuery.data
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState<boolean>(false)

  const maxDepthCategories = useMemo(() => {
    const getMaxDepth = (obj: Object, depth: number): number => {
      if (typeof obj !== 'object' || obj === null) {
        return depth
      }
      return Object.values(obj).reduce(
        (max, value) => Math.max(max, getMaxDepth(value, depth + 1)),
        depth
      )
    }

    return getMaxDepth(productCatalog?.categories ?? {}, 0)
  }, [productCatalog])

  const selectedProductCatalog = productCatalogs.find(
    (catalog) => catalog.id === catalogId
  )

  const autocompleteOptions = useMemo(() => {
    const extractCategoryNames = (
      obj: Object,
      categoryNames: Set<string> = new Set()
    ): Set<string> => {
      if (typeof obj !== 'object' || obj === null) {
        return categoryNames
      }

      if (Array.isArray(obj)) {
        obj.forEach((item) => extractCategoryNames(item, categoryNames))
        return categoryNames
      }

      Object.keys(obj).forEach((key) => {
        categoryNames.add(key)
        extractCategoryNames(obj[key], categoryNames)
      })

      return categoryNames
    }

    const categoryNames = extractCategoryNames(productCatalog?.categories ?? {})
    return [...categoryNames]
      .filter(String)
      .sort((a, b) => a.localeCompare(b))
      .map((option) => ({
        label: option,
        value: option,
      }))
  }, [productCatalog])

  const columns: Column<ProductCatalog>[] = useMemo(() => {
    const categoryColumns = Array.from(
      { length: maxDepthCategories },
      (_, index) => ({
        Header: `Category ${index + 1}`,
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        headerClassName: 'flex-none',
        Cell: () => {
          return (
            <BaseCell className="bg-coolGray-50 w-100">
              Category {index + 1}
            </BaseCell>
          )
        },
        disableSortBy: true,
      })
    )

    return [
      ...categoryColumns,
      {
        disableSortBy: true,
        Header: '',
        id: 'empty',
      },
    ]
  }, [maxDepthCategories])

  if (!productCatalog) {
    return null
  }
  return (
    <div>
      {productCatalogs.length > 0 && (
        <Loader isLoading={productCatalogQuery.isFetching}>
          <div className="flex gap-4 items-center mb-4 justify-between content-between">
            <div className="flex gap-4 items-center">
              <SingleSelect
                className="max-w-50"
                isDisabled={productCatalogs.length === 1}
                options={productCatalogs.map((catalog) => ({
                  label: catalog.name,
                  value: catalog.id,
                }))}
                value={catalogId}
                onChange={(value) => onCatalogChange?.(value?.value ?? '')}
              />
              <div>Last update: </div>
              <div className="text-gold-500 text-xs">
                {selectedProductCatalog
                  ? formatDate(
                      selectedProductCatalog.lastUpdated ??
                        selectedProductCatalog.createdAt,
                      'd MMM yyyy'
                    )
                  : ''}
              </div>
            </div>
            <div className="flex gap-4">
              <Autocomplete
                className="w-60"
                placeholder="Filter"
                data-cy="table-widget-search"
                data-testid="table-widget-search"
                options={autocompleteOptions}
                value={
                  searchValue
                    ? { value: searchValue, label: searchValue }
                    : undefined
                }
                defaultInputValue={searchValue}
                onSearch={(_) =>
                  errorToast('The search is not implemented yet')
                }
                backspaceRemovesValue={true}
              />
              <Button variant="primary" onClick={onUploadClick}>
                Upload
              </Button>
            </div>
          </div>
          <div className="sm:-ml-6 sm:-mr-6">
            {columns.length > 1 && (
              <>
                <Table
                  columns={columns}
                  data={[]}
                  className="mt-6"
                  rowClassName="bg-coolGray-50 "
                />
                {!isUploading && (
                  <Catalog
                    items={productCatalog.categories}
                    searchValue={searchValue}
                    onApply={alert}
                  />
                )}
              </>
            )}
            {columns.length === 1 && (
              <EmptyState
                className="mt-6"
                title="No categories found"
                description="Please upload a product catalog to see the categories."
              />
            )}
          </div>
        </Loader>
      )}

      <ConfirmationModal
        title="Delete this file?"
        confirmationText="Are you sure you want to delete this file?"
        confirmButtonText="Delete file"
        open={isConfirmDeleteOpen}
        onCancel={() => setIsConfirmDeleteOpen(false)}
        onConfirm={() => {
          setIsConfirmDeleteOpen(false)
          errorToast('Not implemented yet')
        }}
      />
    </div>
  )
}

export default ProductCatalogTable
