import { useCallback, useMemo, useState } from 'react'
import { IntegrationAccount } from '@phrasee/phrasee-typings'
import { IntegrationCredentialsFieldNameCollection } from '@phrasee/phrasee-typings/typings/project/project-configuration.types'
import { useFlags } from 'launchdarkly-react-client-sdk'

import ActionIcon from 'common/components/ActionIcon'
import Button from 'common/components/button'
import SortMenu from 'common/components/sortMenu'
import {
  sortByOptions,
  SortOptions,
  SortOptionsValue,
} from 'common/components/sortMenu/SortMenu'
import Tooltip from 'common/components/Tooltip'
import { useLocalStorage } from 'common/hooks/useLocalStorage'
import { Grid, ListIcon } from 'common/icons'
import { Add } from 'common/icons'

import AccountGridCepIntegration from './AccountGridCepIntegration'
import AccountsGrid from './AccountsGrid'
import AccountsList from './AccountsList'
import IntegrationsDeleteModal from './IntegrationsDeleteModal'
import IntegrationsSearchbox from './IntegrationSearchbox'
import IntegrationsUpdateModal from './IntegrationsUpdateModal'

export type ListCepIntegrationResponse = CepIntegration[]

export type TestingMethodology = 'test_and_send' | 'dynamic_realtime'
export interface CepIntegration {
  _id?: string
  name: string
  account_id: string
  cep_name: string
  distribution_channel: string
  type_project: string
  message_type: string
  testing_methodology: TestingMethodology
  credentials?: IntegrationCredentialsFieldNameCollection
  created_at: string
  tracking_metrics?: any[]
}

const ACCOUNTS_PAGE_VIEW_KEY = `accounts-page-view`

const sortOptionsAccount: SortOptions = [
  {
    label: 'Recent',
    value: {
      property: 'createdAt',
      isAscending: false,
    },
  },
  {
    label: 'Oldest',
    value: {
      property: 'createdAt',
      isAscending: true,
    },
  },
  {
    label: 'Ascending',
    value: { property: 'provider', isAscending: true },
  },
  {
    label: 'Descending',
    value: { property: 'provider', isAscending: false },
  },
]

const sortOptionsAccountIntegrations: SortOptions = [
  {
    label: 'Recent',
    value: {
      property: 'created_at',
      isAscending: false,
    },
  },
  {
    label: 'Oldest',
    value: {
      property: 'created_at',
      isAscending: true,
    },
  },
  {
    label: 'Ascending',
    value: { property: 'name', isAscending: true },
  },
  {
    label: 'Descending',
    value: { property: 'name', isAscending: false },
  },
]

type Props = {
  accounts: IntegrationAccount[] | CepIntegration[]
  onAddClick: () => void
}

const AccountsPage = ({ accounts, onAddClick }: Props) => {
  const flags = useFlags()
  const isSimplifiedProjectIntegrationOn = flags?.simplifiedProjectIntegrations

  const [integrationAccountToDelete, setIntegrationAccountToDelete] =
    useState<IntegrationAccount | null>(null)
  const [integrationAccountToUpdate, setIntegrationAccountToUpdate] =
    useState<IntegrationAccount | null>(null)

  const [isTableView, setIsTableView] = useLocalStorage(
    ACCOUNTS_PAGE_VIEW_KEY,
    false
  )

  const [sortOptions, setSortOptions] = useState<SortOptionsValue>({
    property: 'createdAt',
    isAscending: false,
  })

  const [sortOptionsCep, setSortOptionsCep] = useState<SortOptionsValue>({
    property: 'created_at',
    isAscending: false,
  })
  const [searchValue, setSearchValue] = useState<string>('')

  const filterIntegrationAccountByName = useCallback(
    (account: CepIntegration) =>
      account.name
        ?.replaceAll(' ', '')
        ?.toLowerCase()
        ?.includes(searchValue.replaceAll(' ', '').toLowerCase()),
    [searchValue]
  )

  const filterByName = useCallback(
    ({ provider }: IntegrationAccount) =>
      provider
        ?.replaceAll(' ', '')
        ?.toLowerCase()
        ?.includes(searchValue.replaceAll(' ', '').toLowerCase()),
    [searchValue]
  )

  const filteredAccounts = useMemo(() => {
    if (isSimplifiedProjectIntegrationOn) {
      const filtredData = ((accounts as CepIntegration[]) ?? []).filter(
        filterIntegrationAccountByName
      )
      const sortedData = sortByOptions(filtredData, sortOptionsCep)
      return sortedData
    }
    const filtredData = ((accounts as IntegrationAccount[]) ?? []).filter(
      filterByName
    )
    const sortedData = sortByOptions(filtredData, sortOptions)
    return sortedData
  }, [
    isSimplifiedProjectIntegrationOn,
    accounts,
    filterByName,
    sortOptions,
    filterIntegrationAccountByName,
    sortOptionsCep,
  ])

  const searchOptions = useMemo(() => {
    const accountsList = accounts ?? []
    return isSimplifiedProjectIntegrationOn
      ? (accountsList as CepIntegration[]).map((account) => ({
          value: account._id ?? '',
          label: account.name,
        }))
      : (accountsList as IntegrationAccount[]).map((account) => ({
          value: account.id ?? '',
          label: account.provider,
        }))
  }, [accounts, isSimplifiedProjectIntegrationOn])

  const renderAccounts = () => {
    const commonProps = {
      accounts: filteredAccounts,
      onUpdate: (integrationAccount: IntegrationAccount | CepIntegration) =>
        setIntegrationAccountToUpdate(integrationAccount as IntegrationAccount),
      onDelete: (integrationAccount: IntegrationAccount | CepIntegration) =>
        setIntegrationAccountToDelete(integrationAccount as IntegrationAccount),
    }

    if (isSimplifiedProjectIntegrationOn && isTableView) {
      return <div>{JSON.stringify(filteredAccounts)}</div>
    }

    if (isSimplifiedProjectIntegrationOn) {
      return (
        <AccountGridCepIntegration
          {...commonProps}
          accounts={filteredAccounts as CepIntegration[]}
        />
      )
    }

    const Component = isTableView ? AccountsList : AccountsGrid
    return (
      <Component
        {...commonProps}
        accounts={filteredAccounts as IntegrationAccount[]}
      />
    )
  }

  return (
    <>
      <div className="flex items-center my-8 self-end">
        <ActionIcon
          aria-label="Toggle view button"
          onClick={() => setIsTableView(!isTableView)}
        >
          {isTableView ? (
            <Tooltip overlay="Tile view">
              <Grid />
            </Tooltip>
          ) : (
            <Tooltip overlay="List view">
              <ListIcon />
            </Tooltip>
          )}
        </ActionIcon>
        <Tooltip overlay="Sort">
          <SortMenu
            className="text-base pt-3"
            sortOptions={
              isSimplifiedProjectIntegrationOn
                ? sortOptionsAccountIntegrations
                : sortOptionsAccount
            }
            onSortClick={
              isSimplifiedProjectIntegrationOn
                ? setSortOptionsCep
                : setSortOptions
            }
          />
        </Tooltip>
        <Button
          variant="primary"
          prefixIcon={<Add isDefaultColor={false} />}
          onClick={onAddClick}
        >
          Integration
        </Button>
        <IntegrationsSearchbox
          placeholder="Find integration"
          data-cy="integrations-search-box"
          data-testid="integrations-search-box"
          onSearch={setSearchValue}
          options={searchOptions}
        />
      </div>
      {renderAccounts()}
      {!!integrationAccountToUpdate && (
        <IntegrationsUpdateModal
          accountId={integrationAccountToUpdate.accountId}
          integrationId={integrationAccountToUpdate.id}
          label={integrationAccountToUpdate.label ?? ''}
          accountName={integrationAccountToUpdate.accountName}
          logoUrl={integrationAccountToUpdate.logoUrl}
          onCancel={() => {
            setIntegrationAccountToUpdate(null)
          }}
          onClose={() => {
            setIntegrationAccountToUpdate(null)
          }}
        />
      )}
      {!!integrationAccountToDelete && (
        <IntegrationsDeleteModal
          accountId={integrationAccountToDelete.accountId}
          integrationId={integrationAccountToDelete.id}
          onSuccessDelete={() => {
            setIntegrationAccountToDelete(null)
          }}
          onCancel={() => {
            setIntegrationAccountToDelete(null)
          }}
        />
      )}
    </>
  )
}

export default AccountsPage
