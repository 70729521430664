import cx from 'classnames'
import clamp from 'lodash/clamp'

const BASE_RADIUS = 50
const SMALL_RADIAL_CONFIG = {
  strokeWidth: 24,
  sizeClass: 'h-6 w-6',
}
const LARGE_RADIAL_CONFIG = {
  strokeWidth: 10,
  sizeClass: 'h-22 w-22',
}

type Props = {
  value: number
  className?: string
  size?: 'sm' | 'lg'
}

const RadialProgress = ({ value, className, size = 'lg' }: Props) => {
  // Clamp value between 0-100
  const normalizedValue = clamp(value, 0, 100)

  const { strokeWidth, sizeClass } =
    size === 'sm' ? SMALL_RADIAL_CONFIG : LARGE_RADIAL_CONFIG

  // Calculate radius accounting for stroke width to prevent clipping
  const radius = BASE_RADIUS - strokeWidth / 2

  // Calculate total length of circle's perimeter
  const circumference = 2 * Math.PI * radius

  // Calculate how much of circle to hide based on percentage
  // When value is 0%: offset = circumference (full circle hidden)
  // When value is 100%: offset = 0 (nothing hidden)
  const strokeDashoffset =
    circumference - (normalizedValue / 100) * circumference

  return (
    <div className={cx('relative', sizeClass, className)}>
      <svg className="h-full w-full" viewBox="0 0 100 100">
        {/* Background circle */}
        <circle
          fill="none"
          stroke="#e0c797"
          cx={BASE_RADIUS}
          cy={BASE_RADIUS}
          r={radius}
          strokeWidth={strokeWidth}
        />
        {/* Progress circle */}
        <circle
          className="transform -rotate-90 origin-center"
          fill="none"
          stroke="#95336F"
          cx={BASE_RADIUS}
          cy={BASE_RADIUS}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
        />
        {size === 'lg' && (
          <text
            x={BASE_RADIUS}
            y={BASE_RADIUS + 2}
            className="font-medium text-2xl text-base-700"
            dominantBaseline="middle"
            textAnchor="middle"
          >
            {Math.round(normalizedValue)}%
          </text>
        )}
      </svg>
    </div>
  )
}

export default RadialProgress
