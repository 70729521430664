import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { listAccountIntegrations } from 'features/admin/accountSettings/api'
import { cepIntegrationsKeys } from 'features/admin/accountSettings/api/queryKeys'

const useListAccountsIntegrationQuery = (
  accountId: string,
  options?: UseQueryOptions
) => {
  const getIntegrationsQuery = useQuery(
    cepIntegrationsKeys.listAccountIntegrations(accountId),
    () => listAccountIntegrations(accountId),
    { enabled: options?.enabled }
  )
  return getIntegrationsQuery
}

export default useListAccountsIntegrationQuery
