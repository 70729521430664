import { AuthType, EmbedConfig } from '@thoughtspot/visual-embed-sdk'

export const ThoughtSpotConfig = {
  thoughtSpotHost: 'jacquard.thoughtspot.cloud',
  authType: AuthType.EmbeddedSSO,
  customizations: {
    style: {
      customCSS: {
        variables: {
          '--ts-var-root-background': '#fbf7f0',
          '--ts-var-spotter-prompt-background': '#fbf7f0',
        },
      },
    },
  },
} as EmbedConfig
