import { Fragment } from 'react'
import { useFormState } from 'react-final-form'

import { SelectValue } from 'common/components/BaseSelect'
import IconTitleGroup from 'common/components/iconTitleGroup'
import useGetSystemMergeTagsQuery from 'features/admin/accountSettings/api/queries/useGetSystemMergeTagsQuery'

import Category from './Category'
import CategoryDescription from './CategoryDescription'
import CodeBlockField from './CodeBlockField'
import ExampleValues from './ExampleValues'
import Integrations from './Integrations'
import MergeTagName from './Name'

type Props = {
  isEditMode?: boolean
}

const FormContent = ({ isEditMode }: Props) => {
  const formState = useFormState()
  const { data: systemMergeTags, isLoading: isLoadingSystemMergeTags } =
    useGetSystemMergeTagsQuery()

  const selectedCategory = formState.values.merge_tag_category as SelectValue

  return (
    <>
      <div className="flex flex-col text-base-700 pb-5 border-b border-gold-400">
        <div className="text-2xl font-medium font-relais mb-4 px-6 pt-6">
          {isEditMode ? 'Edit merge tag' : 'Create a new merge tag'}
        </div>
        <div className="text-sm px-6">
          {isEditMode
            ? 'Edit an existing merge tag to update the personalised data it uses, allowing you to refine how your messages are tailored.'
            : 'Create a new merge tag to personalise your messages by configuring specific data.'}
        </div>
      </div>
      <div className="flex flex-col p-6 overflow-auto h-full justify-start">
        <IconTitleGroup title="Details" className="pb-6" />
        <MergeTagName />
        <Integrations
          isLoading={isLoadingSystemMergeTags}
          isDisabled={isEditMode}
        />
        <hr className="w-full mt-2 mb-6 border-gold-400" />
        <Category systemMergeTags={systemMergeTags} isDisabled={isEditMode} />
        {selectedCategory && (
          <Fragment key={selectedCategory.value}>
            <CategoryDescription
              systemMergeTags={systemMergeTags}
              selectedCategory={selectedCategory}
              isDisabled={isEditMode}
            />
            <hr className="w-full mt-2 mb-6 border-gold-400" />
            <IconTitleGroup title="Code" className="mb-6" />
            <span className="text-base-700 text-xs mb-2">
              Enter the code snippet that your platform needs to populate this
              tag.
            </span>
            <CodeBlockField
              systemMergeTags={systemMergeTags}
              selectedCategory={selectedCategory}
            />
            <hr className="w-full my-6 border-gold-400" />
            <IconTitleGroup title="Example values" className="mb-1" />
            <span className="text-base-700 text-xs mb-6">
              Enter placeholder copy to act as replacers in your generations - 3
              max.
            </span>
            <ExampleValues
              systemMergeTags={systemMergeTags}
              selectedCategory={selectedCategory}
            />
          </Fragment>
        )}
      </div>
    </>
  )
}

export default FormContent
