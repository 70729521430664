import { Fragment, useRef } from 'react'
import { Form } from 'react-final-form'

import Button from 'common/components/button'
import IconTitleGroup from 'common/components/iconTitleGroup'
import { useMoveIntercomButton } from 'common/components/intercom/useMoveIntercomButton'
import { successToast } from 'common/components/toastNotification'
import type { IntegrationProviderConfig } from 'features/unifiedFlow/contentPage/integrations/centerSection/api/api'

import useAssets from '../../hooks/useAssets'
import useGetLogsQuery from '../api/queries/useGetLogsQuery'

import useUpsertAssetIsMutating from './api/mutations/useUpsertAssetIsMutating'
import IntegrationAssetField from './components/IntegrationAssetField'
import UnsupportedAssetField from './components/UnsupportedAssetField'
import { FormValues, IntegrationAssetFieldRef } from './interfaces'

type IntegrationAssetsProps = {
  integrationAccountId: string
  contentId: string
  accountId: string
}

const IntegrationAssets = ({
  integrationAccountId,
  contentId,
  accountId,
}: IntegrationAssetsProps) => {
  useMoveIntercomButton()

  const { data: assets = [] } = useAssets()
  const assetsRefs = useRef<(IntegrationAssetFieldRef | null)[]>([])
  const areAllAssetsDisabled = assets.every((asset) => asset.isDisabled)
  const isAssetsMutating = useUpsertAssetIsMutating()
  const { refetch: refetchLogs } = useGetLogsQuery(contentId)

  const handleSaveAssets = async (values: FormValues) => {
    const upsertPromises = assetsRefs.current?.map((ref) => {
      if (ref) {
        return ref.upsertAsset(values, {
          contentId,
          accountId,
          fromUnifiedFlow: true,
        })
      }

      return Promise.resolve()
    })

    Promise.all(upsertPromises)
      .then(() => successToast('Successfully connected your assets!'))
      .finally(() => refetchLogs())
  }

  const getState = (method?: IntegrationProviderConfig['method']) => {
    if (method && ['create', 'update'].includes(method)) {
      return 'assetSupported'
    } else {
      return 'assetUnsupported'
    }
  }

  return (
    <Form
      key={integrationAccountId}
      onSubmit={handleSaveAssets}
      render={({ handleSubmit, pristine, form, invalid }) => {
        const isSubmitDisabled =
          !integrationAccountId ||
          pristine ||
          areAllAssetsDisabled ||
          invalid ||
          isAssetsMutating

        return (
          <form onSubmit={handleSubmit} className="text-sm">
            <div className="flex flex-col">
              <IconTitleGroup title="Assets" className="pb-3" />
              <span className="text-base text-base-700 mb-8">
                Confirm the names of the assets you’re sending
              </span>
              {assets.map(
                (
                  { label, id, isDisabled, integration, variant, elementId },
                  index
                ) => {
                  const fieldState = form.getFieldState(`element-${id}`)
                  if (isDisabled && fieldState) {
                    fieldState.change(undefined)
                    form.resetFieldState(`element-${id}`)
                  }

                  const state = getState(integration?.method)

                  return (
                    <Fragment key={`element-${id}`}>
                      {
                        {
                          assetSupported: (
                            <IntegrationAssetField
                              label={label}
                              name={`element-${id}`}
                              ref={(el) => (assetsRefs.current[index] = el)}
                              isDisabled={isDisabled}
                              integration={integration!}
                              stackOneAccountId={integrationAccountId}
                              variant={variant ?? ''}
                              elementId={elementId}
                            />
                          ),
                          assetUnsupported: (
                            <UnsupportedAssetField label={label} />
                          ),
                        }[state]
                      }
                    </Fragment>
                  )
                }
              )}
            </div>
            <div className="flex justify-end items-center gap-4 mt-8">
              <Button
                className="cta-button"
                loading={isAssetsMutating}
                type="submit"
                variant="primary"
                disabled={isSubmitDisabled}
                data-cy="content-integration-connect-button"
                data-testid="content-integration-connect-button"
              >
                Connect
              </Button>
            </div>
          </form>
        )
      }}
    />
  )
}

export default IntegrationAssets
