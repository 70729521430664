import { useRouteMatch } from 'react-router-dom'

import HeaderBanner from 'common/components/headerBanner'
import PageContainer from 'common/components/PageContainer'
import { useDocumentTitle } from 'common/hooks/custom'

import { DataSearch } from './components/DataSearch'
import { Liveboard } from './components/Liveboard'
import PagesColumn from './components/PagesColumn'
import { Spotter } from './components/Spotter'
import { useInitializeThoughtSpot } from './hooks/useInitializeThoughtSpot'
import { PAGES, PATH } from './interfaces'

export default function Dashboard() {
  const { path } = useRouteMatch()
  const selectedPage = PAGES.find((page) => page.path === path)

  useDocumentTitle(`Reports - ${selectedPage?.name} | Jacquard`)

  useInitializeThoughtSpot()

  return (
    <>
      <HeaderBanner title={selectedPage?.name} />
      <div className="flex gap-8 p-8 h-full">
        <PagesColumn selectedPagePath={selectedPage?.path} />
        <PageContainer>
          {selectedPage?.path === PATH.Dashboard && <Liveboard />}
          {selectedPage?.path === PATH.DataSearch && <DataSearch />}
          {selectedPage?.path === PATH.SpotterAI && <Spotter />}
        </PageContainer>
      </div>
    </>
  )
}
