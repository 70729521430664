import { generatePath, useHistory } from 'react-router-dom'

import { ContentTypeIcon } from 'common/components/ContentTypeIcon'
import IconTitleGroup from 'common/components/iconTitleGroup'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { clickCreateCampaign } from 'features/campaigns/store/campaignSlice'
import ListWidget, {
  ListWidgetAction,
  ListWidgetItem,
} from 'features/homePage/components/listWidget'

import { ResponseCampaign } from '../api'

type Props = {
  campaigns: ResponseCampaign[]
  status: 'error' | 'success' | 'loading'
}

const RecentExperiments = ({ campaigns, status }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { accountId } = useAppSelector((state) => state.authStates)

  const createAction: ListWidgetAction = {
    label: 'Create an experiment',
    onClick: () => {
      history.push(generatePath('/campaigns', { accountId }))
      dispatch(clickCreateCampaign())
    },
  }

  return (
    <ListWidget
      title={<IconTitleGroup title="Recent experiments" />}
      className="w-full h-full border-none"
      bgClassName="bg-transparent"
      action={createAction}
      isLoading={status === 'loading'}
      error={
        status === 'error' ? (
          <div className="px-4 py-2">Loading Recent Experiments failed</div>
        ) : null
      }
    >
      <hr className="w-full mt-3 mb-6 border-gold-400" />
      <ul className="overflow-auto max-h-52">
        {campaigns.map((item, index) => (
          <ListWidgetItem
            key={item.id}
            onClick={() => {
              history.push(generatePath(`/campaigns/${item.id}`))
            }}
            data-testid="recent-experiments-list-item"
            className="bg-gold-100 hover:bg-gold-400 cursor-pointer"
          >
            <span data-testid="list-item-icon">
              <ContentTypeIcon
                className="text-base-700"
                contentType={item.campaign_configuration.distribution_channel}
              />
            </span>
            <span className="text-base-700 ml-3" data-testid="list-item-name">
              {item.name}
            </span>
          </ListWidgetItem>
        ))}
      </ul>
    </ListWidget>
  )
}

export default RecentExperiments
