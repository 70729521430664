import { useEffect } from 'react'

import { useAppSelector } from 'common/hooks/redux'
import useWebsockets from 'common/hooks/useWebsockets'

const useDataIngestionWebsocketSubscription = (topic: string) => {
  const {
    lastJsonMessage: wsResponse,
    sendJsonMessage,
    isConnectionReady,
  } = useWebsockets()
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const wsTopic = `csv-ingestion-status/${topic}/${accountId}`

  useEffect(() => {
    if (isConnectionReady) {
      sendJsonMessage({ action: 'subscribe', topics: [wsTopic] })
    }
  }, [isConnectionReady, sendJsonMessage, wsTopic])

  return wsResponse
}

export default useDataIngestionWebsocketSubscription
