/* eslint-disable max-lines*/
import { useCallback, useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import cx from 'classnames'
import arrayMutators from 'final-form-arrays'

import useGetAccountPrivacyRegionsQuery from 'common/api/queries/useGetAccountPrivacyRegionsQuery'
import Button from 'common/components/button'
import CheckboxComponent from 'common/components/checkbox/Checkbox'
import FormItem from 'common/components/formItem'
import Input from 'common/components/input'
import Loader from 'common/components/loaders/Loader'
import Modal from 'common/components/Modal'
import SingleSelect from 'common/components/singleSelect'
import Table, { Column } from 'common/components/table'
import { errorToast } from 'common/components/toastNotification'
import Tooltip from 'common/components/Tooltip'
import { Add as AddIcon } from 'common/icons'
import { ReactComponent as RemoveIcon } from 'common/icons/remove/error.svg'
import { PrivacyRegion } from 'features/accounts/api'
import useGetProductCatalogSettingsQuery from 'features/admin/dataSources/api/queries/useGetProductCatalogSettingsQuery'
import useDataIngestionWebsocketSubscription from 'features/admin/dataSources/useDataIngestionWebsocketSubscription'

import CategoryLevelFormItem from './CsvProductPreview/CategoryLevelFormItem'
import FileInfo from './CsvProductPreview/FileInfo'
import UploadDisclaimer from './UploadDisclaimer'

type Props = {
  isOpen: boolean
  fileName: string
  fileSize: number
  numberOfRows: number
  data: string[][]
  onCancel: () => void
  onImport: (values: FormValues) => void
  hasHeaderColumn: boolean
  onChangeHeaderColumn: (value: boolean) => void
  productCatalogs: { name: string; id: string; region: string }[]
}

export type FormValues = {
  productCatalogName: string
  dataSeparator: string | undefined
  categoryLevel: string[]
  categoryLevelDescription: string[]
  productIdColumn: string | undefined
  productNameColumn: string | undefined
  descriptionColumn?: string
  delimiter: string | undefined
  categoryColumn: string
  categoryColumnDescription: string
  columnsToExclude: string[]
  privacyRegion: PrivacyRegion | undefined
  isRegionConfirmed: boolean
  productCatalogId: string | undefined
}

const validateNotEmptyString = (value: string | undefined) =>
  value?.trim() === undefined || value.trim().length === 0
    ? 'Required'
    : undefined

const validateNotUndefined = (value: string | undefined) =>
  value === undefined ? 'Required' : undefined

const validatePrivacyRegion = (value: PrivacyRegion | undefined) =>
  value === undefined ? 'Required' : undefined

const processRow = (row: string[], headers: string[]) => {
  return row.reduce((acc, value, index) => {
    return {
      ...acc,
      [headers[index]]: value,
    }
  }, {})
}

const generateColumnHeaders = (columnCount: number) => {
  return Array.from({ length: columnCount }, (_, i) => `Column ${i + 1}`)
}

const generateHeaders = ({
  data,
  hasHeaderColumn,
}: {
  data: string[][]
  hasHeaderColumn: boolean
}) => {
  let headers: string[]
  let startIndex: number
  const headerErrors = {
    emptyHeaders: false,
    duplicateHeaders: [] as {
      original: string
      normalizedValue: string
      firstIndex: number
      duplicateIndex: number
    }[],
  }

  if (hasHeaderColumn) {
    headers = data[0].filter(Boolean)
    startIndex = 1
  } else {
    headers = generateColumnHeaders(data[0].length).filter(Boolean)
    startIndex = 0
  }

  if (!headers.length) {
    return {
      data: [],
      headers: [],
      errors: {
        ...headerErrors,
        noHeaders: true,
      },
    }
  }

  const hasNoHeaders = headers.length === 0
  if (hasNoHeaders) {
    headerErrors.emptyHeaders = headers.length === 0
  }

  const normalizedHeaders = headers
    .map((header) => header.trim().toLowerCase())
    .filter(Boolean)
  normalizedHeaders.forEach((header, index) => {
    const firstIndex = normalizedHeaders.indexOf(header)
    if (firstIndex !== index) {
      headerErrors.duplicateHeaders.push({
        original: headers[index],
        normalizedValue: header,
        firstIndex,
        duplicateIndex: index,
      })
    }
  })

  return {
    data:
      headerErrors.emptyHeaders || headerErrors.duplicateHeaders.length > 0
        ? []
        : (data
            .slice(startIndex, 11)
            .map((row) => processRow(row, headers)) as any),
    headers,
    errors: headerErrors,
  }
}

const getInitialFormState = (fileName: string): FormValues => ({
  productCatalogName:
    fileName.substring(0, fileName.lastIndexOf('.')) || fileName,
  dataSeparator: 'columns',
  categoryLevel: [''],
  categoryLevelDescription: [''],
  productNameColumn: undefined,
  descriptionColumn: undefined,
  delimiter: undefined,
  categoryColumn: '',
  categoryColumnDescription: '',
  columnsToExclude: [],
  privacyRegion: undefined,
  productCatalogId: '',
  isRegionConfirmed: false,
  productIdColumn: undefined,
})

const CsvProductPreview = ({
  isOpen,
  fileName,
  fileSize,
  numberOfRows,
  data: rowData,
  onCancel,
  onImport,
  hasHeaderColumn,
  onChangeHeaderColumn,
  productCatalogs,
}: Props) => {
  const {
    data,
    headers: csvHeaders,
    errors,
  } = generateHeaders({
    data: rowData,
    hasHeaderColumn,
  })

  const accountQuery = useGetAccountPrivacyRegionsQuery()
  useDataIngestionWebsocketSubscription('product')

  const [formState, setFormState] = useState<FormValues>(
    getInitialFormState(fileName)
  )

  const [hasShownErrorToast, setHasShownErrorToast] = useState(false)

  const productCatalogSettingsQuery = useGetProductCatalogSettingsQuery({
    productCatalogId: formState.productCatalogId,
    region: formState.privacyRegion ?? '',
  })

  const isProductCatalogSelected = !!formState.productCatalogId

  const headers =
    isProductCatalogSelected &&
    productCatalogSettingsQuery.data?.sourceFieldNames
      ? productCatalogSettingsQuery.data.sourceFieldNames
      : csvHeaders

  const updateFormState = useCallback(
    (updateFn: (prevState: FormValues) => FormValues) => {
      setFormState((prevState) => updateFn(prevState))
    },
    []
  )

  useEffect(() => {
    // When product catalog settings are loaded, populate the form with the settings
    if (productCatalogSettingsQuery.data && formState.productCatalogId) {
      const settings = productCatalogSettingsQuery.data

      updateFormState((prevState) => ({
        ...prevState,
        productNameColumn: settings.productNameColumnName,
        productIdColumn: settings.productIdColumnName,
        descriptionColumn: settings.productDescriptionColumnName,
        dataSeparator: settings.separatedBy,
        delimiter: settings.categoryDelimiter,
        ...(settings.separatedBy === 'delimiter' && {
          categoryColumn: settings.categoryColumnName,
          categoryColumnDescription: settings.categoryDescriptionColumnName,
        }),
        ...(settings.separatedBy === 'columns' && {
          categoryLevel: settings.categoryLevelsColumnNames || [''],
          categoryLevelDescription:
            settings.categoryDescriptionLevelsColumnNames || [''],
        }),
      }))
    }
  }, [
    productCatalogSettingsQuery.data,
    formState.productCatalogId,
    updateFormState,
  ])

  const columns: Column[] = headers.map((value) => {
    const isDisabled = formState.columnsToExclude.includes(value)

    return {
      Header: value,
      accessor: value,
      width: 200,
      isDisabled,
      columnAction:
        value !== formState.productIdColumn &&
        value !== formState.productNameColumn &&
        !formState.categoryLevel.includes(value) ? (
          <Tooltip
            overlay={
              isDisabled
                ? 'Excluded column from import / use'
                : 'Exclude column from import / use'
            }
            placement="top"
          >
            <Button
              variant="icon"
              data-cy="exclude-column-button"
              onClick={(event) => {
                event.stopPropagation()
                setFormState((prevState) => {
                  if (prevState.columnsToExclude.includes(value)) {
                    return {
                      ...prevState,
                      columnsToExclude: prevState.columnsToExclude.filter(
                        (excludedColumn) => excludedColumn !== value
                      ),
                    }
                  }
                  return {
                    ...prevState,
                    columnsToExclude: [...prevState.columnsToExclude, value],
                  }
                })
              }}
            >
              <RemoveIcon
                height={16}
                width={16}
                className={cx('group-hover:visible hover:text-maroon-200', {
                  invisible: !isDisabled,
                  'text-maroon-200': isDisabled,
                })}
              />
            </Button>
          </Tooltip>
        ) : null,
    }
  })

  const handleSave = (values) => {
    onImport(values)
  }

  useEffect(() => {
    if (accountQuery.isError && !hasShownErrorToast) {
      const errorMessage =
        accountQuery.error instanceof Error
          ? accountQuery.error.message
          : 'Could not retrieve privacy regions, please try again'
      errorToast(errorMessage, { autoClose: false })
      setHasShownErrorToast(true)
    }

    if (!accountQuery.isError) {
      setHasShownErrorToast(false)
    }
  }, [accountQuery, hasShownErrorToast])

  useEffect(() => {
    if (accountQuery.data?.length === 1) {
      setFormState((prevState) => {
        const newPrivacyRegion = accountQuery.data?.[0]
        if (prevState.privacyRegion !== newPrivacyRegion) {
          return {
            ...prevState,
            privacyRegion: newPrivacyRegion,
          }
        }
        return prevState
      })
    }
  }, [accountQuery])

  const filteredHeaders = headers
    .filter((header) => !formState.columnsToExclude.includes(header))
    .map((value) => {
      return {
        label: value,
        value,
      }
    })

  return (
    <Form<FormValues>
      onSubmit={() => handleSave(formState)}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={formState}
      render={({ handleSubmit, hasValidationErrors }) => {
        return (
          <Modal
            visible={isOpen}
            centered
            closable={false}
            onClose={onCancel}
            isResponsive
            className="block"
            style={{
              maxWidth: '80vw',
              minWidth: 700,
            }}
            data-testid="csv-product-preview-modal"
            footer={
              <div className="my-3">
                <Button
                  data-testid="cancel-button"
                  className="mr-4 pointer-events-auto"
                  ghost
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="import-button"
                  variant="primary"
                  onClick={(event) => {
                    handleSubmit(event)
                  }}
                  disabled={
                    isProductCatalogSelected
                      ? !formState.isRegionConfirmed
                      : hasValidationErrors ||
                        !formState.isRegionConfirmed ||
                        !formState.privacyRegion ||
                        (errors &&
                          (errors.emptyHeaders ||
                            errors.duplicateHeaders.length > 0))
                  }
                >
                  Import
                </Button>
              </div>
            }
          >
            <Loader
              isLoading={
                accountQuery.isLoading || productCatalogSettingsQuery.isFetching
              }
              data-testid="loader"
            >
              <div
                className="text-base-700 text-2xl font-medium"
                data-testid="import-data-title"
              >
                Import data
              </div>
              <div className="text-base-700 text-sm leading-tight">
                Complete the setup below to successfully import your data into
                your Jacquard account.
              </div>
              <div className="mt-6 flex justify-between mb-6">
                <div>
                  <div className="flex gap-4">
                    <FormItem
                      label="Is that a new record?"
                      htmlFor="new-record"
                      className="max-w-55"
                    >
                      <SingleSelect
                        id="newRecord"
                        data-testid="new-record"
                        options={[
                          {
                            label: 'Create a new record',
                            value: '',
                          },
                          ...productCatalogs.map((catalog) => ({
                            label: catalog.name,
                            value: catalog.id,
                          })),
                        ]}
                        value={formState.productCatalogId}
                        onChange={(val) => {
                          if (val?.value === '') {
                            // Reset form state when "Create a new record" is selected
                            setFormState((prevState) => ({
                              ...getInitialFormState(fileName),
                            }))
                          } else {
                            setFormState((prevState) => ({
                              ...prevState,
                              productCatalogName: val?.label ?? '',
                              productCatalogId: val?.value ?? '',
                            }))
                          }
                        }}
                      />
                    </FormItem>

                    <Field<string>
                      name="productCatalogName"
                      validate={validateNotEmptyString}
                      render={({ meta }) => (
                        <FormItem
                          label="Name"
                          htmlFor="uploadedFileName"
                          className="max-w-55"
                          error={meta.error}
                        >
                          <Input
                            type="text"
                            id="uploadedFileName"
                            data-testid="file-name-input"
                            value={formState.productCatalogName}
                            onChange={(event) => {
                              setFormState((prevState) => ({
                                ...prevState,
                                productCatalogName: event.target.value,
                              }))
                            }}
                            maxLength={50}
                            required
                            disabled={isProductCatalogSelected}
                          />
                        </FormItem>
                      )}
                    />
                  </div>
                  <FormItem
                    htmlFor="isNewRecord"
                    className="flex items-center gap-4"
                  >
                    <CheckboxComponent
                      isChecked={hasHeaderColumn}
                      label="Import file has column headers"
                      data-testid="header-column-checkbox"
                      onChange={() => onChangeHeaderColumn(!hasHeaderColumn)}
                      isDisabled={isProductCatalogSelected}
                    />
                  </FormItem>
                </div>
                <FileInfo
                  fileName={fileName}
                  fileSize={fileSize}
                  numberOfRows={numberOfRows}
                  numberOfColumns={headers.length}
                />
              </div>
              <div className="flex gap-10">
                <div className="flex flex-col text-base-700 flex-1">
                  <div className="font-medium pb-2">
                    Choose data storage location
                  </div>
                  <div className="text-sm font-normal leading-tight mb-4">
                    Select the geographic location where client data will be
                    stored. <br />
                    Choose based on compliance with local data protection
                    regulations and your operational requirements.
                  </div>
                  <div>
                    <Field<string>
                      name="privacyRegion"
                      validate={() =>
                        isProductCatalogSelected
                          ? undefined
                          : validatePrivacyRegion(formState.privacyRegion)
                      }
                      render={({ meta }) => {
                        return (
                          <FormItem
                            htmlFor="privacyRegion"
                            className="max-w-75"
                            error={meta.error}
                          >
                            <SingleSelect
                              placeholder="Select your privacy region"
                              data-testid="privacy-region"
                              value={formState.privacyRegion}
                              options={accountQuery.data?.map((region) => ({
                                label: region,
                                value: region,
                              }))}
                              onChange={(val) => {
                                if (val) {
                                  updateFormState((prevState) => ({
                                    ...prevState,
                                    privacyRegion: val.value as PrivacyRegion,
                                  }))
                                }
                              }}
                              isDisabled={isProductCatalogSelected}
                            />
                          </FormItem>
                        )
                      }}
                    />
                  </div>
                </div>
                <div className="text-base-700 flex-1">
                  <div className="text-base font-medium mb-2">Import type</div>
                  <div className="text-sm font-normal leading-tight mb-4">
                    Select the format that matches your CSV. <br /> This ensures
                    dates are interpreted and displayed correctly.
                  </div>

                  <Field<string>
                    name="dataSeparator"
                    validate={() =>
                      isProductCatalogSelected
                        ? undefined
                        : validateNotUndefined(formState.dataSeparator)
                    }
                    render={({ meta }) => (
                      <FormItem
                        htmlFor="dataSeparator"
                        className="max-w-55"
                        error={meta.error}
                      >
                        <SingleSelect
                          id="dataSeparator"
                          data-testid="data-separator"
                          onChange={(val: any) => {
                            updateFormState((prevState) => ({
                              ...prevState,
                              dataSeparator: val?.value,
                            }))
                          }}
                          value={formState.dataSeparator}
                          isSearchable={true}
                          options={[
                            { label: 'Column', value: 'columns' },
                            {
                              label: 'Delimiter (e.g. ; or >)',
                              value: 'delimiter',
                            },
                          ]}
                          isDisabled={isProductCatalogSelected}
                        />
                      </FormItem>
                    )}
                  />
                  {formState.dataSeparator === 'delimiter' && (
                    <Field<string>
                      name="delimiter"
                      validate={() =>
                        isProductCatalogSelected
                          ? undefined
                          : validateNotUndefined(formState.delimiter)
                      }
                      render={({ meta }) => (
                        <FormItem
                          htmlFor="delimiter"
                          className="max-w-55"
                          error={meta.error}
                        >
                          <SingleSelect
                            id="delimiter"
                            data-testid="delimiter"
                            onChange={(val: any) => {
                              updateFormState((prevState) => ({
                                ...prevState,
                                delimiter: val?.value,
                              }))
                            }}
                            value={formState.delimiter}
                            isSearchable={true}
                            options={[
                              { label: ';', value: ';' },
                              { label: '>', value: '>' },
                            ]}
                            isDisabled={isProductCatalogSelected}
                          />
                        </FormItem>
                      )}
                    />
                  )}
                </div>
              </div>
              <div className="flex items-center gap-2 my-6">
                <Field<boolean>
                  name="isRegionConfirmed"
                  validate={() =>
                    !formState.isRegionConfirmed ? 'Required' : undefined
                  }
                  render={({ meta }) => (
                    <FormItem error={meta.error} htmlFor="isRegionConfirmed">
                      <CheckboxComponent
                        isChecked={formState.isRegionConfirmed}
                        onChange={(value) => {
                          updateFormState((prevState) => ({
                            ...prevState,
                            isRegionConfirmed: value,
                          }))
                        }}
                        isDisabled={!formState.privacyRegion}
                        data-testid="region-confirmed-checkbox"
                        label={<UploadDisclaimer />}
                      />{' '}
                    </FormItem>
                  )}
                />
              </div>

              <form
                onSubmit={handleSubmit}
                className="text-sm"
                data-testid="mapping-form"
              >
                <div className="w-full border-coolGray-200 border border-solid mb-6" />
                <div className="text-xl font-medium text-base-700 mb-2">
                  Map your data
                </div>
                <div className="text-sm text-base-700 leading-tight mb-4">
                  Specify how you would like us to process your file.
                </div>
                <div className="flex gap-4">
                  <Field<string>
                    name="productNameColumn"
                    validate={() =>
                      isProductCatalogSelected
                        ? undefined
                        : validateNotUndefined(formState.productNameColumn)
                    }
                    render={({ meta }) => (
                      <FormItem
                        label="Product name*"
                        htmlFor="customerNameColumn"
                        className="max-w-55"
                        error={meta.error}
                      >
                        <SingleSelect
                          id="productNameColumn"
                          data-testid="product-name-column"
                          placeholder="Select product name"
                          onChange={(val: any) => {
                            updateFormState((prevState) => ({
                              ...prevState,
                              productNameColumn: val?.value,
                            }))
                          }}
                          value={formState.productNameColumn}
                          isSearchable={true}
                          required
                          options={filteredHeaders}
                          isDisabled={isProductCatalogSelected}
                        />
                      </FormItem>
                    )}
                  />
                  <Field<string>
                    name="productIdColumn"
                    validate={() =>
                      isProductCatalogSelected
                        ? undefined
                        : validateNotUndefined(formState.productIdColumn)
                    }
                    render={({ meta }) => (
                      <FormItem
                        label="Product ID*"
                        htmlFor="customerIdColumn"
                        className="max-w-55"
                        error={meta.error}
                      >
                        <SingleSelect
                          id="productIdColumn"
                          data-testid="product-id-column"
                          onChange={(val: any) => {
                            if (val !== null) {
                              updateFormState((prevState) => ({
                                ...prevState,
                                productIdColumn: val.value,
                              }))
                            }
                          }}
                          placeholder="Select product ID"
                          value={formState.productIdColumn}
                          isSearchable={true}
                          options={filteredHeaders}
                          isDisabled={isProductCatalogSelected}
                          required
                        />
                      </FormItem>
                    )}
                  />
                  <Field<string>
                    name="descriptionColumn"
                    validate={() =>
                      isProductCatalogSelected
                        ? undefined
                        : validateNotUndefined(formState.descriptionColumn)
                    }
                    render={({ meta }) => (
                      <FormItem
                        label="Description*"
                        htmlFor="descriptionColumn"
                        className="max-w-55"
                        isOptional={false}
                        error={meta.error}
                      >
                        <SingleSelect
                          id="descriptionColumn"
                          data-testid="description-column"
                          placeholder="Select description"
                          onChange={(val: any) => {
                            if (val !== null) {
                              updateFormState((prevState) => ({
                                ...prevState,
                                descriptionColumn: val.value,
                              }))
                            }
                          }}
                          value={formState.descriptionColumn}
                          isSearchable={true}
                          options={filteredHeaders}
                          isDisabled={isProductCatalogSelected}
                          required
                        />
                      </FormItem>
                    )}
                  />
                </div>

                {formState.dataSeparator === 'columns' && (
                  <>
                    <div className="flex gap-4 flex-wrap items-start">
                      <FieldArray name="categoryLevel">
                        {({ fields }) => {
                          return fields.map((name, index) => (
                            <CategoryLevelFormItem
                              key={`${name}-${index}`}
                              name={name}
                              index={index}
                              fields={fields}
                              isProductCatalogSelected={
                                isProductCatalogSelected
                              }
                              headers={headers}
                              formState={formState}
                              updateFormState={updateFormState}
                              validateNotEmptyString={validateNotEmptyString}
                            />
                          ))
                        }}
                      </FieldArray>
                      <FieldArray name="categoryLevel">
                        {({ fields }) => (
                          <div className="mt-8">
                            <Button
                              variant="link"
                              ghost
                              className="h-full "
                              data-testid="add-category-button"
                              onClick={() => {
                                updateFormState((prevState) => ({
                                  ...prevState,
                                  categoryLevel: [
                                    ...prevState.categoryLevel,
                                    '',
                                  ],
                                  categoryLevelDescription: [
                                    ...prevState.categoryLevelDescription,
                                    '',
                                  ],
                                }))
                              }}
                              disabled={
                                fields.length === Math.min(headers.length, 5) ||
                                isProductCatalogSelected
                              }
                              prefixIcon={
                                <AddIcon size={4} isDefaultColor={false} />
                              }
                            >
                              Add category
                            </Button>
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  </>
                )}
                {formState.dataSeparator === 'delimiter' && (
                  <>
                    <Field<string>
                      name="categoryColumn"
                      validate={() =>
                        isProductCatalogSelected
                          ? undefined
                          : validateNotUndefined(formState.categoryColumn)
                      }
                      render={({ meta }) => (
                        <FormItem
                          label="Category column*"
                          htmlFor="categoryColumn"
                          className="max-w-55"
                          error={meta.error}
                        >
                          <SingleSelect
                            id="categoryColumn"
                            data-testid="category-column"
                            onChange={(val) => {
                              if (val !== null) {
                                updateFormState((prevState) => ({
                                  ...prevState,
                                  categoryColumn: val.value,
                                }))
                              }
                            }}
                            placeholder="Select category column"
                            value={formState.categoryColumn}
                            isSearchable={true}
                            options={filteredHeaders}
                            isDisabled={isProductCatalogSelected}
                            required
                          />
                        </FormItem>
                      )}
                    />
                    <Field<string>
                      name="categoryColumnDescription"
                      render={({ meta }) => (
                        <FormItem
                          label="Category column - Description"
                          htmlFor="categoryColumnDescription"
                          className="max-w-55"
                          error={meta.error}
                        >
                          <SingleSelect
                            id="categoryColumnDescription"
                            data-testid="category-column-description"
                            onChange={(val) => {
                              if (val !== null) {
                                updateFormState((prevState) => ({
                                  ...prevState,
                                  categoryColumnDescription: val.value,
                                }))
                              }
                            }}
                            placeholder="Select category column description"
                            value={formState.categoryColumnDescription}
                            isSearchable={true}
                            options={filteredHeaders}
                            isDisabled={isProductCatalogSelected}
                          />
                        </FormItem>
                      )}
                    />
                  </>
                )}
              </form>

              {!isProductCatalogSelected && (
                <>
                  {errors && errors.duplicateHeaders.length > 0 && (
                    <div
                      className="mt-4 p-4 border border-red-400 bg-red-50 text-red-700 rounded"
                      data-testid="duplicate-headers-error"
                    >
                      <h4 className="font-medium mb-1">
                        Duplicate Headers Detected
                      </h4>
                      <p className="text-sm mb-2">
                        The following headers are duplicated (case-insensitive):
                      </p>
                      <ul className="list-disc pl-5 text-sm">
                        {errors.duplicateHeaders.map((dupe, i) => (
                          <li key={i}>
                            "{dupe.original}" (positions {dupe.firstIndex + 1}{' '}
                            and {dupe.duplicateIndex + 1})
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {errors && errors.emptyHeaders && (
                    <div
                      className="mt-4 p-4 border border-red-400 bg-red-50 text-red-700 rounded"
                      data-testid="empty-headers-error"
                    >
                      <h4 className="font-medium mb-1">No Headers Found</h4>
                      <p className="text-sm">
                        No column headers could be detected in your file. Please
                        check your file format.
                      </p>
                    </div>
                  )}

                  {!errors?.emptyHeaders &&
                    !errors?.duplicateHeaders.length && (
                      <Table
                        columns={columns}
                        data={data}
                        data-testid="preview-table"
                        className="mt-10 bg-coolGray-50 border-t border-coolGray-200"
                      />
                    )}
                </>
              )}
            </Loader>
          </Modal>
        )
      }}
    />
  )
}

const CsvProductPreviewWithResetState = (props: Props) => (
  <CsvProductPreview key={`${props.isOpen}`} {...props} />
)

export default CsvProductPreviewWithResetState
