import { errorToast } from 'common/components/toastNotification'

export type CsvProductState = {
  headers: string[]
  progressInPercentage: number | undefined
  data: string[][] | undefined
  fileName: string
  numberOfRows: number
  isPreviewOpen: boolean
  isConfirmationPreviewOpen: boolean
}

export const csvProductInitialState: CsvProductState = {
  headers: [],
  progressInPercentage: undefined,
  isPreviewOpen: false,
  data: undefined,
  fileName: '',
  isConfirmationPreviewOpen: false,
  numberOfRows: 0,
}

export type CsvProductActions =
  | { type: 'case1' }
  | {
      type: 'upload'
      headers: string[]
      data: any
      fileName: string
      numberOfRows: number
    }
  | { type: 'cancelPreview' }
  | { type: 'errorUpload' }
  | { type: 'uploadCompleted' }
  | { type: 'uploadProgress'; percentage: number }
  | { type: 'clickImport' }
  | { type: 'cancelConfirmationPreview' }
  | { type: 'closeConfirmationPreview' }

export const csvProductReducer: React.Reducer<
  CsvProductState,
  CsvProductActions
> = (previousState, action) => {
  switch (action.type) {
    case 'case1': {
      return { ...previousState }
    }
    case 'upload': {
      if ((action.data ?? []).length > 0) {
        return {
          ...previousState,
          headers: action.headers,
          data: action.data,
          fileName: action.fileName,
          numberOfRows: action.numberOfRows,
          isPreviewOpen: true,
        }
      } else {
        errorToast('No data found in the CSV file')
        return { ...previousState }
      }
    }
    case 'uploadProgress': {
      return {
        ...previousState,
        progressInPercentage: action.percentage,
      }
    }
    case 'clickImport': {
      return {
        ...previousState,
        progressInPercentage: 0,
        isPreviewOpen: false,
      }
    }
    case 'uploadCompleted':
    case 'errorUpload':
    case 'closeConfirmationPreview':
      return { ...csvProductInitialState }
    case 'cancelPreview': {
      return {
        ...previousState,
        isPreviewOpen: false,
        isConfirmationPreviewOpen: true,
      }
    }
    case 'cancelConfirmationPreview': {
      return { ...previousState, isConfirmationPreviewOpen: false }
    }
    default: {
      return previousState
    }
  }
}
