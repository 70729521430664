import { useMemo, useState } from 'react'

import Button from 'common/components/button'
import EmptyState from 'common/components/emptyState'
import ErrorPage from 'common/components/error/ErrorPage'
import Loader from 'common/components/loaders/Loader'
import PageContainer from 'common/components/PageContainer'
import PageTitle from 'common/components/PageTitle'
import { Add as AddIcon } from 'common/icons'

import useGetMergeTagsQuery from '../api/queries/useGetMergeTagsQuery'

import AddMergeTagDrawer from './components/manageMergeTagDrawer'
import MergeTagsList from './components/mergeTagsList'
import Searchbox from './components/Searchbox'

const MergeTags = () => {
  const [searchValue, setSearchValue] = useState('')

  const { data: mergeTags, isLoading, isError } = useGetMergeTagsQuery()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const mergeTagsFiltered = useMemo(
    () =>
      mergeTags?.filter((tag) =>
        tag.name.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [mergeTags, searchValue]
  )

  const mergeTagsSelectValues = useMemo(
    () =>
      mergeTags?.map((tag) => ({
        label: tag.name,
        value: tag.name,
      })),
    [mergeTags]
  )

  const getState = () => {
    if (isLoading) {
      return 'loading'
    } else if (isError) {
      return 'error'
    } else if (mergeTags?.length ?? 0 > 1) {
      return 'data'
    } else {
      return 'empty'
    }
  }

  const state = getState()

  return (
    <PageContainer className="max-w-318 sm:mx-auto pt-10 px-6 justify-start">
      <div className="flex justify-between items-center">
        <PageTitle title="Merge tags" />
        <div className="flex gap-6">
          <Button
            data-cy="add-merge-tag-button"
            variant="primary"
            onClick={() => setIsDrawerOpen(true)}
            prefixIcon={
              <AddIcon
                isDefaultColor={false}
                size={4}
                className="text-base-700"
              />
            }
          >
            MERGE TAG
          </Button>
          <Searchbox
            onSearch={setSearchValue}
            options={mergeTagsSelectValues}
          />
        </div>
      </div>
      <div className="text-base-700 mt-2 mb-10">
        Merge tags are placeholders that insert personalised data like names or
        emails into messages automatically.
      </div>
      <AddMergeTagDrawer isOpen={isDrawerOpen} onOpenChange={setIsDrawerOpen} />
      {
        {
          loading: <Loader />,
          error: <ErrorPage />,
          data: <MergeTagsList mergeTags={mergeTagsFiltered} />,
          empty: (
            <EmptyState
              title="No merge tags currently"
              description="Generate language with dynamic elements."
              className="h-full"
              action={
                <Button variant="primary" onClick={() => setIsDrawerOpen(true)}>
                  CREATE YOUR FIRST MERGE TAG
                </Button>
              }
            />
          ),
        }[state]
      }
    </PageContainer>
  )
}

export default MergeTags
