import { MergeTag } from '@phrasee/phrasee-typings'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { showErrorToast } from 'common/api/helpers'
import { successToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'

import { updateMergeTag } from '../api'
import { mergeTagsKeys } from '../queryKeys'

const useUpdateMergeTagMutation = ({ tagId }: { tagId: string }) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (
      values: Omit<
        MergeTag,
        '_id' | 'last_updated' | 'category' | 'integration'
      >
    ) => updateMergeTag({ ...values, tagId, accountId }),
    onSuccess: async (_, { name }) => {
      successToast(`Merge tag ${name} has been successfully updated`)
      queryClient.invalidateQueries({
        queryKey: mergeTagsKeys.list(accountId),
      })
    },
    onError: (error) => {
      showErrorToast(error)
    },
  })
}

export default useUpdateMergeTagMutation
