import { Field } from 'react-final-form'
import { FieldArrayRenderProps } from 'react-final-form-arrays'

import Button from 'common/components/button'
import FormItem from 'common/components/formItem'
import SingleSelect from 'common/components/singleSelect'
import { Bin as BinIcon } from 'common/icons'

import { FormValues } from '../CsvProductPreview'

type Props = {
  name: string
  index: number
  fields: FieldArrayRenderProps<string, HTMLElement>['fields']
  isProductCatalogSelected: boolean
  headers: string[]
  formState: {
    categoryLevel: string[]
    categoryLevelDescription: string[]
    columnsToExclude: string[]
  }
  updateFormState: (updateFn: (prevState: FormValues) => FormValues) => void
  validateNotEmptyString: (value: string | undefined) => string | undefined
}

const CategoryLevelFormItem = ({
  name,
  index,
  fields,
  isProductCatalogSelected,
  headers,
  formState,
  updateFormState,
  validateNotEmptyString,
}: Props) => {
  return (
    <div key={name} className="inline-flex items-center gap-4">
      <div className="flex flex-col gap-1">
        <Field<string>
          name={`${name}`}
          validate={() => {
            if (isProductCatalogSelected) {
              return undefined
            }
            return validateNotEmptyString(formState.categoryLevel[index])
          }}
          render={({ meta }) => (
            <FormItem
              label={`Category level ${index + 1}${index === 0 ? '*' : ''}`}
              htmlFor={`categoryLevel${index}`}
              orientation="horizontal"
              className="relative"
              error={meta.error}
            >
              {index === fields.length! - 1 && (
                <div className="absolute right-0 -top-2">
                  <Button
                    variant="icon"
                    disabled={index === 0 || isProductCatalogSelected}
                    data-testid={`remove-category-${index}`}
                    onClick={() => {
                      updateFormState((prevState) => ({
                        ...prevState,
                        categoryLevel: prevState.categoryLevel.slice(0, -1),
                        categoryLevelDescription:
                          prevState.categoryLevelDescription.slice(0, -1),
                      }))
                    }}
                    prefixIcon={<BinIcon size={3} isDefaultColor={false} />}
                  />
                </div>
              )}
              <div className="flex gap-4 relative">
                <div>
                  <SingleSelect
                    id={`categoryLevel${index}`}
                    className="flex-1 w-55"
                    data-testid={`category-level-${index}`}
                    onChange={(val) => {
                      if (val !== null) {
                        updateFormState((prevState) => ({
                          ...prevState,
                          categoryLevel: prevState.categoryLevel.map(
                            (item, i) => (i === index ? val.value : item)
                          ),
                        }))
                      }
                    }}
                    isDisabled={isProductCatalogSelected}
                    value={formState.categoryLevel[index]}
                    placeholder={`Select category level ${index + 1}`}
                    isSearchable={true}
                    required={index === 0}
                    options={headers
                      .filter((value) =>
                        fields.value[index] === value
                          ? true
                          : !fields.value.includes(value) &&
                            !formState.columnsToExclude.includes(value)
                      )
                      .map((value) => ({
                        label: value,
                        value,
                      }))}
                  />
                </div>
              </div>
            </FormItem>
          )}
        />
        <Field<string>
          name={`categoryLevelDesc[${index}]`}
          render={() => (
            <FormItem
              label={`Category level ${index + 1} - Description`}
              htmlFor={`categoryLevel${index}-description`}
              orientation="horizontal"
            >
              <div className="flex gap-4">
                <div>
                  <SingleSelect
                    id={`categoryLevel${index}-description`}
                    className="flex-1 w-55"
                    data-testid={`category-level-${index}-description`}
                    onChange={(val) => {
                      if (val !== null) {
                        updateFormState((prevState) => ({
                          ...prevState,
                          categoryLevelDescription:
                            prevState.categoryLevelDescription.map((item, i) =>
                              i === index ? val.value : item
                            ),
                        }))
                      }
                    }}
                    value={formState.categoryLevelDescription[index]}
                    isDisabled={isProductCatalogSelected}
                    isSearchable={true}
                    placeholder="Select description"
                    options={headers
                      .filter((value) =>
                        fields.value[index] === value
                          ? true
                          : !fields.value.includes(value) &&
                            !formState.columnsToExclude.includes(value)
                      )
                      .map((value) => ({
                        label: value,
                        value,
                      }))}
                  />
                </div>
              </div>
            </FormItem>
          )}
        />
      </div>
    </div>
  )
}

export default CategoryLevelFormItem
