import { useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import useListAccountsQuery from 'common/api/queries/useListAccountsQuery'
import ErrorPage from 'common/components/error/ErrorPage'
import Loader from 'common/components/loaders/Loader'
import PageContainer from 'common/components/PageContainer'
import PageTitle from 'common/components/PageTitle'
import { useAppSelector } from 'common/hooks/redux'
import useListAccountsIntegrationQuery from 'features/admin/accountSettings/api/queries/useListAccountsIntegrationQuery'

import AccountsPage from './components/AccountsPage'
import EmptyState from './components/EmptyState'
import SelectIntegrationOverlay from './components/SelectIntegrationOverlay'

const Integrations = () => {
  const flags = useFlags()
  const isSimplifiedProjectIntegrationOn = flags?.simplifiedProjectIntegrations
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const {
    data: accounts,
    isLoading: isLoadingAccounts,
    isError: isErrorAccounts,
    refetch: refetchAccounts,
  } = useListAccountsQuery(accountId, {
    enabled: !isSimplifiedProjectIntegrationOn,
  })

  const {
    data: accountsIntegration,
    isLoading: isLoadingIntegration,
    isError: isErrorIntegration,
    refetch: refetchAccountsIntegration,
  } = useListAccountsIntegrationQuery(accountId, {
    enabled: isSimplifiedProjectIntegrationOn,
  })

  const isLoading = isSimplifiedProjectIntegrationOn
    ? isLoadingIntegration
    : isLoadingAccounts

  const isError = isErrorAccounts || isErrorIntegration

  const [
    isSelectIntegrationOverlayVisible,
    setIsSelectIntegrationOverlayVisible,
  ] = useState(false)

  const state = (() => {
    if (isLoading) {
      return 'loading'
    } else if (isError) {
      return 'error'
    } else if (accounts!?.length > 0 || accountsIntegration!?.length > 0) {
      return 'data'
    } else {
      return 'empty'
    }
  })()

  return (
    <PageContainer className="max-w-318 sm:mx-auto pt-10 px-6 justify-start">
      <div>
        <PageTitle title="Integrations" />
      </div>
      <div className="text-coolGray-400 mt-2">
        Make the most of Jacquard integrations and do even more.
      </div>
      {
        {
          loading: (
            <div className="w-full h-82">
              <Loader />
            </div>
          ),
          error: <ErrorPage />,
          data: (
            <AccountsPage
              accounts={
                isSimplifiedProjectIntegrationOn
                  ? accountsIntegration!
                  : accounts!
              }
              onAddClick={() => setIsSelectIntegrationOverlayVisible(true)}
            />
          ),
          empty: (
            <EmptyState
              onAddClick={() => setIsSelectIntegrationOverlayVisible(true)}
            />
          ),
        }[state]
      }
      <SelectIntegrationOverlay
        isOpen={isSelectIntegrationOverlayVisible}
        onClose={() => {
          if (isSimplifiedProjectIntegrationOn) {
            refetchAccountsIntegration()
          } else {
            refetchAccounts()
          }
          setIsSelectIntegrationOverlayVisible(false)
        }}
      />
    </PageContainer>
  )
}

export default Integrations
