import { useMemo } from 'react'

import Breakpoints from 'common/breakpoints'
import AvatarList from 'common/components/AvatarList'
import Button from 'common/components/button'
import { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import TableWidget from 'common/components/widget/tableWidget'
import { formatDate } from 'common/helpers/formatDate'
import { useAppSelector } from 'common/hooks/redux'
import { useWindowDimensions } from 'common/hooks/useWindowDimensions'
import { Add as AddIcon } from 'common/icons'
import useGetBrandVoiceDetailsQuery from 'features/admin/brandVoice/api/queries/useGetBrandVoiceDetailsQuery'
import { AdvancedLanguageRule } from 'features/admin/brandVoice/store/brandVoiceSlice'

import AdvancedLanguageRulesActions from './AdvancedLanguageRulesActions'

const AdvancedLanguageRules = () => {
  const { screenWidth } = useWindowDimensions()
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const selectedBrandVoiceId = useAppSelector(
    (state) => state.styleGuide.selectedBrandVoiceId
  )

  const { data: brandVoice, isLoading: isLoadingBrandVoiceDetails } =
    useGetBrandVoiceDetailsQuery(accountId, selectedBrandVoiceId || '')

  const advancedLanguageRules = brandVoice?.languageRules || []

  const columns: Column<AdvancedLanguageRule>[] = useMemo(
    () => [
      {
        Header: 'Rule',
        accessor: 'rule',
      },
      {
        Header: 'Last modified',
        accessor: 'lastModified',
        Cell: ({ value }) => <BaseCell>{formatDate(new Date(value))}</BaseCell>,
        width: 60,
        align: 'left',
      },
      {
        Header: 'Created',
        accessor: 'created',
        Cell: ({ value }) => <BaseCell>{formatDate(new Date(value))}</BaseCell>,
        width: 60,
      },
      {
        Header: 'User',
        accessor: 'user',
        width: 20,
        disableSortBy: true,
        align: 'right',
        Cell: ({ value }) => {
          const initials = value
            ?.split(' ')
            .map((name) => name[0])
            .join('')

          return (
            <BaseCell className="flex justify-center">
              <AvatarList userNames={[initials]} />
            </BaseCell>
          )
        },
      },
      {
        Header: '',
        accessor: 'id',
        width: 20,
        disableSortBy: true,
        align: 'right',
        Cell: ({ row }) => {
          return (
            <BaseCell className="invisible group-hover:visible">
              <AdvancedLanguageRulesActions rule={row.original} />
            </BaseCell>
          )
        },
      },
    ],
    []
  )

  return (
    <TableWidget.Widget
      data-testid="advanced-language-rules-widget"
      className="mt-6"
      data={advancedLanguageRules}
      columns={columns}
      isLoading={isLoadingBrandVoiceDetails}
      firstUseText="No advanced language rules added yet"
      initialState={{
        sortBy: [
          {
            id: 'lastModified',
            desc: true,
          },
        ],
      }}
    >
      <TableWidget.Header
        title="Advanced language rules"
        subtitle="Set up to 10 rules with clear examples to guide language generation that remains consistent, precise, and aligned with your brand voice."
      >
        <Button
          className="mr-4"
          variant="primary"
          data-testid="add-advanced-language-rule-button"
          prefixIcon={<AddIcon size={6} isDefaultColor={false} />}
          onClick={() => {
            alert('Not implemented yet')
          }}
        >
          {screenWidth < Breakpoints.sm ? undefined : 'Add rule'}
        </Button>
      </TableWidget.Header>
    </TableWidget.Widget>
  )
}

export default AdvancedLanguageRules
