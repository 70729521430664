/* Disabled due to this file being just API calls exports */
/* eslint-disable max-lines */
import {
  Campaign as GraphQLResponseCampaigns,
  DashboardMode,
  DistributionChannel,
  MetricsValues,
} from '@phrasee/phrasee-typings/Graphql/interfaces'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { gql } from 'graphql-request'
import moment from 'moment'

import { CancelTokenSource, connectInternalApi, fetchGraphQL } from 'common/api'
import {
  fetchUpliftReport,
  ReportLeveledResponse,
  ReportResponse,
} from 'common/api/reporting'
import { ResponseStatus } from 'common/interfaces/campaign'
import { ResponseProject } from 'features/projects/interface'

const twelveMonthsAgo = moment().subtract(12, 'months').toISOString()

interface CampaignsResponse {
  campaigns: ResponseCampaign[]
  count: number
}

interface CampaignMetric {
  count: number
}

export interface CampaignMetricsResponse {
  live: CampaignMetric
  completed: CampaignMetric
  scheduled: CampaignMetric
  needApproval: CampaignMetric
}

export type ProjectSummaryResponse = {
  email_projects: EmailProject[]
  push_projects?: PushProject[]
  sms_projects?: SmsProject[]
}

export type EmailProject = {
  id: string
  name: string
  number_of_campaigns?: number
  open_uplift?: number
  click_uplift?: number
  incremental_opens?: number
  incremental_clicks?: number
}

type SmsProject = {
  id: string
  name: string
  number_of_campaigns?: number
  click_uplift?: number
  incremental_clicks?: number
}

type PushProject = {
  id: string
  name: string
  number_of_campaigns?: number
  direct_open_uplift?: number
  influenced_open_uplift?: number
  incremental_direct_open?: number
  incremental_influenced_opens?: number
}

export interface ContentKpiResponse {
  contentsNumber: number
  categories: DashboardCategory[]
}

interface DashboardCategory {
  label: string
  value: number
}

export interface ResponseCampaign
  extends Pick<
    GraphQLResponseCampaigns,
    '_id' | 'name' | 'status' | 'campaign_configuration' | 'user_id'
  > {
  id: string
  name: string
  project: { projectName: string }
  status: ResponseStatus
  user_id: string
  campaign_configuration: { distribution_channel: DistributionChannel }
}

export const fetchRecentlyCreated = function (
  page: number,
  pageSize: number,
  accountId: string
): Promise<CampaignsResponse> {
  const query = gql`
    query GetRecentlyCreated(
      $page: Int
      $pageSize: Int
      $sorting: CampaignSorting
      $filter: CampaignFilter
    ) {
      data: campaigns(
        page: $page
        pageSize: $pageSize
        sorting: $sorting
        filter: $filter
      ) {
        campaigns {
          id: _id
          name
          project {
            projectName: name
          }
          status
          user_id
          campaign_configuration {
            distribution_channel
          }
        }
      }
    }
  `

  const variables = {
    page,
    pageSize,
    sorting: {
      field: 'created',
      direction: 'DESC',
    },
    filter: {
      accountId,
    },
  }

  return fetchGraphQL<CampaignsResponse>({ query, variables })
}

export const fetchTodos = function ({
  page,
  pageSize,
  userId,
  accountId,
}): Promise<CampaignsResponse> {
  const query = gql`
    query GetTodo(
      $page: Int
      $pageSize: Int
      $sorting: CampaignSorting
      $filter: CampaignFilter
    ) {
      data: campaigns(
        page: $page
        pageSize: $pageSize
        sorting: $sorting
        filter: $filter
      ) {
        campaigns {
          id: _id
          name
          status
          user_id
          campaign_configuration {
            distribution_channel
          }
        }
      }
    }
  `

  const variables = {
    page,
    pageSize,
    sorting: {
      field: 'created',
      direction: 'DESC',
    },
    filter: {
      userId,
      accountId,
    },
  }

  return fetchGraphQL<CampaignsResponse>({ query, variables })
}

export const getCampaignMetrics = async (
  accountId: string
): Promise<CampaignMetricsResponse> => {
  const result = await connectInternalApi.get<CampaignMetricsResponse>(
    `v1/core/monorail/accounts/${accountId}/dashboard/campaigns`
  )
  return result.data
}

export const getContentKpi = async (
  accountId: string,
  isShowUnifiedFlow: boolean
): Promise<ContentKpiResponse> => {
  const result = await connectInternalApi.get<ContentKpiResponse>(
    `v1/core/content-api/accounts/${accountId}/contents/reports/dashboard`,
    {
      params: isShowUnifiedFlow
        ? {
            useElementBased: true,
          }
        : {},
    }
  )
  return result.data
}

export const getBroadcastProjects = async ({
  accountId,
  endDate,
  startDate,
}: {
  accountId: string
  endDate: string
  startDate: string
}) => {
  return connectInternalApi.get<{ data: ProjectSummaryResponse }>(
    `v1/core/reporting/reporting/accounts/${accountId}/report/summary`,
    {
      params: {
        reporting_level: 'project',
        distribution_type: 'broadcast',
        start_date: startDate,
        end_date: endDate,
      },
    }
  )
}

type QueryResponseType =
  | AxiosResponse<{ data: ProjectSummaryResponse }>
  | AxiosResponse<ReportLeveledResponse | ReportResponse>
  | null

export const useGetBroadcastMetricsQuery = (
  accountId: string,
  dashboardMode: DashboardMode | undefined,
  projectIds: string[]
) => {
  const endDate = moment().toISOString()
  const startDate = twelveMonthsAgo

  const query = useQuery<QueryResponseType>(
    ['broadcast_email_projects_metrics', accountId, projectIds],
    () => {
      if (dashboardMode === 'Incremental') {
        return getBroadcastProjects({ accountId, endDate, startDate })
      }
      return fetchUpliftReport({
        projectIds,
        accountId,
        endDate,
        startDate,
        upliftType: ['email_opens', 'email_clicks'],
        testingMethod: ['split_test_partial'],
        reportingLevel: 'project',
        periodType: 'aggregate',
      })
    },
    { refetchOnWindowFocus: false }
  )
  return query
}

export const fetchOptimizationMetrics = async ({
  accountId,
  distributionType,
  dashboardMode,
}: {
  accountId: string
  distributionType: 'broadcast' | 'AlwaysOn'
  dashboardMode: 'Incremental' | 'Average_uplift'
}) => {
  return connectInternalApi.get<{
    average_uplift_opens?: {
      value: number
      format: 'percentage'
    }
    average_uplift_clicks?: {
      value: number
      format: 'percentage'
    }
    total_incremental_opens?: {
      value: number
      format: 'number'
    }
    total_incremental_clicks?: {
      value: number
      format: 'number'
    }
    total?: number
  }>(`v1/core/content-api/accounts/${accountId}/contents/dashboard/metrics`, {
    params: {
      distributionType,
      dashboardMode,
    },
  })
}

export const fetchRecentContents = async (accountId: string) => {
  const result = await connectInternalApi.get<ContentLibraryEntryResponse[]>(
    `v1/core/content-api/accounts/${accountId}/contents/`,
    {
      params: { limit: 8, sort: 'created_date:desc' },
    }
  )
  return result.data
}

export async function fetchActiveProjectsByDistrubutionType(
  accountId: string,
  source: CancelTokenSource,
  distributionType: string
): Promise<ResponseProject[]> {
  const query = gql`
    query activeProjects($filter: ProjectFilter) {
      data: projects(filter: $filter) {
        _id
        name
        created
        last_updated
        deleted
        archived
        project_configuration {
          campaign_configurations {
            distribution_channel
            distribution_type
          }
        }
      }
    }
  `

  const variables = {
    filter: {
      accountId,
      includeDeleted: false,
      includeArchived: false,
      distributionType: distributionType,
    },
  }

  return fetchGraphQL<ResponseProject[]>(
    { query, variables },
    {
      cancelToken: source.token,
    }
  )
}

export const useActiveProjects = (
  accountId: string,
  source: CancelTokenSource,
  distributionType: string
) => {
  const { data, isError: hasGQLError } = useQuery(
    [`active_${distributionType}_projects`, accountId],
    async () =>
      await fetchActiveProjectsByDistrubutionType(
        accountId,
        source,
        distributionType
      ),
    {
      refetchOnWindowFocus: false,
      select: (data) =>
        data
          ?.filter(
            ({ project_configuration }) =>
              project_configuration?.campaign_configurations
                ?.distribution_channel === 'email'
          )
          ?.map(({ _id }) => _id),
    }
  )
  return { data, hasGQLError }
}

const getTriggerProjects = ({
  accountId,
  projectIds,
  endDate,
  startDate,
}: {
  accountId: string
  projectIds: string[]
  endDate: string
  startDate: string
}) => {
  return connectInternalApi.get<{
    data: ProjectSummaryResponse
  }>(`v1/core/reporting/reporting/accounts/${accountId}/report/summary`, {
    params: {
      project_ids: projectIds,
      reporting_level: 'project',
      distribution_type: 'AlwaysOn',
      start_date: startDate,
      end_date: endDate,
    },
  })
}

export const useGetTriggerMetricsQuery = (
  accountId: string,
  dashboardMode: DashboardMode | undefined,
  projectIds: string[]
) => {
  const endDate = moment().toISOString()
  const startDate = twelveMonthsAgo
  const query = useQuery<QueryResponseType>(
    ['trigger_email_projects_metrics', accountId, projectIds],
    () => {
      if (dashboardMode === 'Incremental') {
        return getTriggerProjects({ accountId, projectIds, endDate, startDate })
      }
      return fetchUpliftReport({
        projectIds,
        accountId,
        endDate,
        startDate,
        upliftType: ['email_opens', 'email_clicks'],
        testingMethod: ['split_test_partial'],
        reportingLevel: 'project',
        periodType: 'aggregate',
      })
    },
    { refetchOnWindowFocus: false }
  )

  return query
}

type ContentLibraryElementResponse = {
  id: string
  name: string
  project: string
  template: string
  campaign_id: string
  experimentType: string
  owner: string
  createdDate: string
  sendDate: string | null
  status: string
  icon: string | undefined
}

export type ContentLibraryEntryResponse = {
  id: string
  name: string
  status: string
  createdDate: string
  is_legacy: boolean
  elements: ContentLibraryElementResponse[]
}

export const getContentLibrary = async (accountId: string) => {
  const result = await connectInternalApi.get<{
    Draft: number
    'New Language': number
    Live: number
    Completed: number
    'Pending Approval': number
    'Missing Results': number
  }>(`v1/core/content-api/accounts/${accountId}/contents/dashboard`)

  return result.data
}

type MetricWithPercentageOnly = Pick<MetricsValues, 'percentage'>
type MilestoneMetricsResponse = {
  totalPercentage: number
  metrics: {
    broadcastCampaigns: MetricWithPercentageOnly
    liveTriggerCampaigns: MetricWithPercentageOnly
    totalExperimentsLaunched: MetricWithPercentageOnly
    totalSends: MetricWithPercentageOnly
    channelsActivated: MetricWithPercentageOnly
    uniqueVariantsSent: MetricsValues
  }
}

export const fetchMilestoneMetrics = async (
  accountId: string
): Promise<MilestoneMetricsResponse> => {
  const query = gql`
    query milestoneMetricsQuery($accountId: String) {
      data: milestoneMetrics(accountId: $accountId) {
        totalPercentage
        metrics {
          broadcastCampaigns {
            percentage
          }
          liveTriggerCampaigns {
            percentage
          }
          totalExperimentsLaunched {
            percentage
          }
          totalSends {
            percentage
          }
          channelsActivated {
            percentage
          }
          uniqueVariantsSent {
            value
            goal
            percentage
          }
        }
      }
    }
  `

  const variables = {
    accountId,
  }

  return fetchGraphQL<MilestoneMetricsResponse>({
    query,
    variables,
  })
}
