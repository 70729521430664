import Drawer from 'common/components/drawer'
import { useAppSelector } from 'common/hooks/redux'
import useCreateMergeTagMutation from 'features/admin/accountSettings/api/mutations/useCreateMergeTagMutation'

import ManageMergeTagCancelButton from './ManageMergeTagCancelButton'
import ManageMergeTagForm from './ManageMergeTagForm'
import ManageMergeTagSubmitButton from './ManageMergeTagSubmitButton'

const AddMergeTagDrawer = ({ isOpen, onOpenChange }) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const createMergeTagMutation = useCreateMergeTagMutation()

  const handleSave = (values) => {
    createMergeTagMutation.mutate(
      { ...values, accountId },
      {
        onSuccess: () => {
          onOpenChange(false)
        },
      }
    )
  }

  return (
    <Drawer
      isOpen={isOpen}
      title="Create a new merge tag"
      onOpenChange={onOpenChange}
      content={
        <ManageMergeTagForm
          onSubmit={handleSave}
          footer={
            <>
              <ManageMergeTagCancelButton
                onClick={() => onOpenChange(false)}
                data-testid="add-merge-tag-drawer-cancel-button"
              />
              <ManageMergeTagSubmitButton
                isLoading={createMergeTagMutation.isLoading}
                data-testid="add-merge-tag-drawer-add-button"
              >
                Save
              </ManageMergeTagSubmitButton>
            </>
          }
        ></ManageMergeTagForm>
      }
    />
  )
}

export default AddMergeTagDrawer
