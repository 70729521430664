import cx from 'classnames'

import * as Icons from 'common/icons'
import { Radio as RadioIcon } from 'common/icons'
import { IconType } from 'common/icons/Icon'
import { DistributionChannel as ResponseDistributionChannel } from 'common/interfaces/projects'

import DistributionChannel from '../DistributionChannel'

export function getIconForDistributionChannel(
  channel: ResponseDistributionChannel
): IconType | undefined {
  switch (channel) {
    case 'sms':
      return Icons.SmsLove
    case 'email':
    case 'email_in_body':
      return Icons.Email
    case 'push_notification':
      return Icons.Push
    case 'social_ad':
      return Icons.Facebook
    case 'web_and_app':
      return Icons.LandingPage
    case 'content_card':
      return Icons.ContentCard
    case 'in_app_message':
      return Icons.InApp
    case 'web_push':
      return Icons.Website
    default:
      return undefined
  }
}

type Props = {
  options: ResponseDistributionChannel[]
  onChange(type: ResponseDistributionChannel): void
  value?: ResponseDistributionChannel
  isDisabled?: boolean
}

const ChannelPicker = ({
  options,
  onChange,
  isDisabled,
  value: selectedValue,
}: Props) => {
  return (
    <>
      <div className="mb-2 text-sm text-coolGray-800 font-semibold">
        Channel
      </div>
      <div className="flex flex-wrap gap-6">
        {options.map((value) => {
          const Icon = getIconForDistributionChannel(value)
          const isSelected = value === selectedValue

          return (
            <label
              key={value}
              data-cy={`channel-option-label-${value}`}
              data-testid={`channel-option-label-${value}`}
              style={{ gridTemplateRows: '32px auto' }}
              className={cx(
                `relative w-27 grid justify-items-center gap-2 border-2 border-coolGray-300 
                pt-5 px-4 pb-4 bg-white text-coolGray-800 font-medium
                cursor-pointer hover:border-maroon-500`,
                {
                  'border-maroon-400 text-maroon-400 bg-maroon-40': isSelected,
                  'cursor-not-allowed': isDisabled,
                }
              )}
            >
              <input
                data-cy={`channel-option-input-${value}`}
                data-testid={`channel-option-input-${value}`}
                className="sr-only"
                type="radio"
                checked={isSelected}
                name="channel"
                onChange={() => onChange(value)}
                disabled={isDisabled}
              />
              <RadioIcon
                className="absolute top-2 right-2"
                size={5}
                state={isSelected ? 'selected' : 'default'}
              />
              {Icon && (
                <Icon size={8} state={isSelected ? 'selected' : 'default'} />
              )}
              <DistributionChannel
                data-cy={`channel-option-title-${value}`}
                data-testid={`channel-option-title-${value}`}
                value={value}
                className="self-center text-center"
              />
            </label>
          )
        })}
      </div>
    </>
  )
}

export default ChannelPicker
