import { useState } from 'react'

import MainContent from 'features/unifiedFlow/components/MainContent'

import useElementState from './hooks/useElementState'
import CenterSection from './centerSection'
import LeftSection from './leftSection'

const Review = () => {
  const [selectedPersonalizationId, setSelectedPersonalizationId] =
    useState<string | undefined>()
  useElementState()

  return (
    <MainContent className="flex-wrap" id="main-content-page">
      <LeftSection
        onSelectPersonalization={setSelectedPersonalizationId}
        selectedPersonalizationId={selectedPersonalizationId}
      />
      <CenterSection
        selectedPersonalizationId={selectedPersonalizationId}
        onSelectPersonalization={setSelectedPersonalizationId}
      />
    </MainContent>
  )
}

export default Review
