import { IntegrationAccount } from '@phrasee/phrasee-typings'
import keys from 'lodash/keys'
import uniq from 'lodash/uniq'

import { IntegrationRouting } from 'features/unifiedFlow/contentPage/integrations/centerSection/api/api'

export const getAvailableIntegrations = (
  data: IntegrationRouting[]
): string[] => {
  return uniq(
    data.reduce<string[]>(
      (acc, { providers }) => [...acc, ...keys(providers)],
      []
    )
  )
}

export const getSelectedIntegrationAccount = (
  accounts: IntegrationAccount[] | undefined,
  availableIntegrations: string[] | undefined,
  elementIntegrationAccountId: string | undefined
) => {
  return (
    accounts?.find((account) => account.id === elementIntegrationAccountId) ||
    accounts?.find(({ provider }) => availableIntegrations?.includes(provider))
  )
}
