/* eslint-disable react/no-unused-state */
/* eslint-disable max-lines */
import React from 'react'
import { connect } from 'react-redux'
import { Select } from 'antd'
import cx from 'classnames'
import { cloneDeep, isEqual } from 'lodash'
import moment from 'moment'
import GraphsModule from 'workflow/CampaignSummary/GraphsModule'
import BannerContainer from 'workflow/common/bannerContainer'
import NewVariantsBanner from 'workflow/common/newVariantsBanner'
import RawDataTabBanner from 'workflow/common/rawDataTabBanner'

import tokenFetcher from 'common/auth/tokenFetcher'
import Banner from 'common/components/banner'
import Loader from 'common/components/loaders/Loader'
import TabWidget from 'common/components/tabWidget/TabWidget'
import Tooltip from 'common/components/Tooltip'
import { WidgetWaitingForChartsData } from 'common/components/WidgetMessage'
import { copyAndReplaceNewLineEmoji } from 'common/helpers/copyAndReplaceNewLineEmoji'
import { AlertCircle, TestDummy } from 'common/icons'
import { isSFMC } from 'features/campaigns/components/details/helpers'
import FacebookStatus from 'features/campaigns/integration-options/Facebook/FacebookStatus'
import { closeDrawer, openDrawer } from 'features/campaigns/store/campaignSlice'

import { showBanner } from '../common/common.actions'
import MachineOpensSummary from '../common/machineOpensSummary/MachineOpensSummary'
import {
  AdvancedVisualisationsDataParams,
  AppliedFilters,
  DynamicOptimizationResultType,
  FacebookResultsPayload,
  SubjectLine,
  UserInput,
} from '../interface'
import helpers, { getFilteredVariants } from '../utils/helpers'
import * as actions from '../Workflow.actions'

import { BACKGROUND_CAMPAIGN_FETCH_INTERVAL } from './CampaignStatus/CampaignStatus'
import CancelModal from './CancelModal/CancelModal'
import { shouldShowThePhraseeXIntegrationPanel } from './IntegrationOptions/SfmcIntegration/helpers'
import SfmcIntegrationStatus from './IntegrationOptions/SfmcIntegration/SfmcIntegrationStatus'
import {
  BarGraph,
  OpenRatesOverTime,
  StackedAreaGraph,
  UpliftGraph,
} from './PhraseeXContent/AdvancedVisualisations/Graphs'
import graphsColorPalette from './PhraseeXContent/AdvancedVisualisations/graphsColorPalette'
import AcousticStatus from './AcousticStatus'
import CampaignResultsTable from './ResultsTable'
import VariantCell from './VariantCell'
import WinnerVariantInfo from './WinnerVariantInfo'

import styles from './CampaignSummary.module.css'

const { Option } = Select

const DEFAULT_VARIANT_STATUS_FILTER = ['live', 'approved']

export const initialState = {
  graphIndex: 0,
  tabIndex: '0',
  shouldShowScheduleButton: undefined,
  shouldShowCancelButton: undefined,
  shouldDisableButton: undefined,
  shouldShowDrawer: false,
  hasOnlyVariants: undefined,
  shouldOpenCancelModal: false,
  shouldShowInputResultsButton: false,
  advancedVisualisationDataRequested: false,
  showAdvancedVisualisationsGraphs: true,
  drawerTitle: 'Integration settings',
  facebookType: 'clicks',
  defaultVariantStatusFilter: DEFAULT_VARIANT_STATUS_FILTER,
  // this key is used to fix #HG-1423. We encountered 'highcharts' bug when providing
  // data on table filter change. Workaround is to mount new bar graph when filter has changed.
  barGraphKey: JSON.stringify(DEFAULT_VARIANT_STATUS_FILTER),
  token: undefined,
  showMachineOpensTicked: true,
}

const fbTypeOptions = [
  {
    id: 'clicks',
    name: 'Clicks',
  },
  {
    id: 'leads',
    name: 'Leads',
  },
  {
    id: 'engagement',
    name: 'Engagements',
  },
  {
    id: 'conversions',
    name: 'Conversions',
  },
]

const PHRASEE_X_TAB_NAMES = {
  MATURE_DATA: 'MATURE DATA',
  RAW_DATA: 'RAW DATA',
  TEST_DATA: 'TEST DATA',
}

const phraseeXContentTabs = (tabsToHide: string[]) => {
  const tabs = [
    {
      tabName: PHRASEE_X_TAB_NAMES.MATURE_DATA,
      tabindex: '1',
      tabTooltip:
        'Data is 2+ days old. The open rate and click rate have stabilized and are therefore representative of final performance.',
    },
    {
      tabName: PHRASEE_X_TAB_NAMES.RAW_DATA,
      tabindex: '2',
      tabTooltip:
        'All data is shown. This may include emails that have been sent recently. In this case, the open and click rate metrics for some variants may underestimate their final value.',
    },
    {
      tabName: PHRASEE_X_TAB_NAMES.TEST_DATA,
      tabindex: '3',
      tabTooltip:
        'Test data is shown. This data only includes data that was received before the experiment was started or when test mode was activated.',
    },
  ]
  return tabs.filter((tab) => !tabsToHide.includes(tab.tabName))
}

declare const window: any

const addColorToVariant = (variants) => {
  const variantColorMapping = new Map()
  let omittedIndex: any = undefined
  let color: any = undefined
  const variantsWithColors = variants?.values?.map(
    (variant: SubjectLine, index: number) => {
      color = graphsColorPalette.primary[index]
      if (variant.ownsl) {
        omittedIndex = index
        color = graphsColorPalette.control
      }
      if (index === graphsColorPalette.primary.length) {
        color = graphsColorPalette.primary[omittedIndex]
        omittedIndex = undefined
      }
      if (index > graphsColorPalette.primary.length) {
        const combinedPalette = [
          ...graphsColorPalette.primary,
          ...graphsColorPalette.secondary,
          ...graphsColorPalette.tertiary,
        ]
        color = combinedPalette[index]
      }
      variantColorMapping.set(variant._id, {
        color,
        index,
      })
      return {
        ...variant,
        color,
      }
    }
  )

  return {
    columns: variants?.columns,
    values: variantsWithColors,
    variantColorMapping,
  } as any
}

class CampaignSummary extends React.Component<any, any> {
  noCampaignStatusIntegrationTypes: any

  constructor(props) {
    super(props)
    this.state = initialState
    this.noCampaignStatusIntegrationTypes = ['none', 'movable_ink', 'cheetah']
  }

  async componentDidMount() {
    const {
      loadSummaryDetails,
      fetchCampaignData,
      updatedCampaignId,
      shouldFetchOnMount,
      typeOfIntegration,
      projectId,
      campaignData,
      subjectLines,
      setFilteredVariants,
      setDynamicOptimizationResultType,
    } = this.props

    const isBanditCampaign = helpers.isBanditCampaign(
      campaignData?.campaign_configuration
    )
    if (subjectLines?.length) {
      const { defaultVariantStatusFilter } = this.state

      if (isBanditCampaign) {
        setFilteredVariants(
          subjectLines.filter((subjectLine: SubjectLine) =>
            defaultVariantStatusFilter.includes(
              subjectLine?.bandit_status?.status
            )
          )
        )
      } else {
        setFilteredVariants(subjectLines)
      }
      this.setState({
        selectedRows: subjectLines.filter((subjectLine: SubjectLine) =>
          defaultVariantStatusFilter.includes(
            subjectLine?.bandit_status?.status
          )
        ),
      })
    }

    if (isBanditCampaign) {
      setDynamicOptimizationResultType('mature')
    } else {
      setDynamicOptimizationResultType(undefined)
    }

    const campaignProgress = campaignData?.campaign_progress

    if (shouldFetchOnMount) {
      loadSummaryDetails(updatedCampaignId, projectId)
      fetchCampaignData(updatedCampaignId)
    }
    const shouldDisplayTestModeBanner = helpers.shouldDisplayTestModeBanner(
      campaignData?.campaign_data,
      campaignData?.campaign_configuration
    )
    // Don't fetch results if we don't have correct campaign data
    // and don't fetch mature results if campaign is in test mode (for the initial load)
    if (
      updatedCampaignId === campaignData?._id &&
      !shouldDisplayTestModeBanner
    ) {
      this.makeLoadResultsCallWithRawOrMatureDataParams('mature')
    } else if (shouldDisplayTestModeBanner) {
      this.changeToTestTab()
      this.makeLoadResultsCallWithRawOrMatureDataParams('test')
    }
    this.runBackgroundStatusAndIntervalIfNecessary()
    if (campaignProgress) {
      this.updateCampaignProgressLocalState(campaignProgress)
      if (this.isAcoustic(typeOfIntegration)) {
        if (
          campaignProgress.test_scheduled ||
          campaignProgress.test_schedule_started ||
          campaignProgress.final_send_cancelled ||
          campaignProgress.test_cancelled
        ) {
          this.toggleDrawer({ visible: false })
        }
      }
    }
    if (!campaignProgress && typeOfIntegration) {
      this.setState({
        shouldShowScheduleButton: true,
        shouldShowBackToProject: true,
        hasOnlyVariants: true,
      })
    }
    if (typeOfIntegration === 'movable_ink') {
      this.setState({
        shouldShowScheduleButton: true,
      })
    }
    await this.setToken()
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      typeOfIntegration,
      updatedCampaignId,
      showBanner,
      fetchCampaignData,
      campaignData,
      summary,
      scheduleResponse,
      sendsCancelled,
      cancelResponse,
      clearingMovableInkTestData,
      updatingMovableInkIntegrationData,
      getAdvancedVisualisationsData,
      shouldCloseMappDrawer,
      closeDrawer,
      setFilteredVariants,
    } = this.props

    const {
      campaignData: prevCampaignData,
      summary: prevSummary,
      scheduleResponse: prevScheduleResponse,
      sendsCancelled: prevSendsCancelled,
      shouldCloseMappDrawer: prevShouldCloseMappDrawer,
      typeOfIntegration: prevTypeOfIntegration,
    } = prevProps

    const { advancedVisualisationDataRequested } = this.state
    const campaignProgress = campaignData?.campaign_progress
    const prevCampaignProgress = prevCampaignData?.campaign_progress
    const advancedVisualisationsConfig =
      campaignData?.campaign_configuration?.advanced_visualisations?.sort(
        (a: any, b: any) => a?.ordinal - b?.ordinal
      )

    if (prevTypeOfIntegration !== typeOfIntegration) {
      this.runBackgroundStatusAndIntervalIfNecessary()
    }
    // Movable Ink
    if (typeOfIntegration === 'movable_ink') {
      if (
        (clearingMovableInkTestData === false &&
          prevProps.clearingMovableInkTestData === true) ||
        (updatingMovableInkIntegrationData === false &&
          prevProps.updatingMovableInkIntegrationData === true)
      ) {
        this.toggleDrawer({ visible: false })
        this.loadResults()
      }
    }
    // Acoustic
    if (this.isAcoustic(typeOfIntegration)) {
      if (
        campaignProgress.test_schedule_started !==
          prevCampaignProgress.test_schedule_started &&
        campaignProgress.test_schedule_started
      ) {
        showBanner({
          content: `Experiment submitted for scheduling. We'll notify you
            once the mailings are all created.`,
          type: 'success',
        })
      }
      if (
        !isEqual(campaignProgress, prevCampaignProgress) &&
        (campaignProgress.test_scheduled ||
          campaignProgress.test_schedule_started ||
          campaignProgress.final_send_cancelled ||
          campaignProgress.test_cancelled)
      ) {
        this.toggleDrawer({ visible: false })
        this.handleCancel()
        fetchCampaignData(updatedCampaignId).then(() =>
          this.updateCampaignProgressLocalState(campaignProgress)
        )
      }
    }
    // Sailthru
    if (typeOfIntegration === 'sailthru') {
      if (summary && !isEqual(summary, prevSummary)) {
        closeDrawer()
        if (summary.isScheduled) {
          showBanner({
            content: 'Experiment scheduled successfully',
            type: 'success',
          })
        }
      }
    }
    // SFMC scheduleResponse changed
    if (typeOfIntegration === 'sfmcv2') {
      if (
        !isEqual(scheduleResponse, prevScheduleResponse) &&
        scheduleResponse
      ) {
        this.toggleDrawer({ visible: false })
        fetchCampaignData(updatedCampaignId)
      }
    }
    // Mapp
    if (
      shouldCloseMappDrawer &&
      prevShouldCloseMappDrawer !== shouldCloseMappDrawer
    ) {
      this.toggleDrawer({ visible: false })
    }
    // Any campaign will update state of buttons when campaignProgress changes
    if (!isEqual(campaignProgress, prevCampaignProgress) && campaignProgress) {
      this.updateCampaignProgressLocalState(campaignProgress)
    }
    if (
      typeOfIntegration === 'sailthru' ||
      typeOfIntegration === 'sfmcv2' ||
      this.isAcoustic(typeOfIntegration)
    ) {
      if (sendsCancelled !== prevSendsCancelled && sendsCancelled) {
        const config = {
          content:
            cancelResponse.message || 'Experiment cancelled successfully',
          type: 'success',
        }
        showBanner(config)
        this.handleCancel()
        if (typeOfIntegration === 'sfmcv2') {
          fetchCampaignData(updatedCampaignId)
        }
      }
    }

    if (this.shouldRenderGraphs() && updatedCampaignId === campaignData?._id) {
      if (
        !advancedVisualisationDataRequested &&
        Object.keys(advancedVisualisationsConfig || {}).length
      ) {
        const { dynamicOptimizationResultType } = this.props
        this.setState(
          {
            advancedVisualisationDataRequested: true,
            advancedVisualisationsConfig,
            requestedGraphTemplate: this.state.requestedGraphTemplate
              ? this.state.requestedGraphTemplate
              : advancedVisualisationsConfig[0]?.visualisation_template,
            requestedGraphType: this.state.requestedGraphType
              ? this.state.requestedGraphType
              : advancedVisualisationsConfig[0]?.type,
            requestedGraphOrdinal: this.state.requestedGraphOrdinal
              ? this.state.requestedGraphOrdinal
              : advancedVisualisationsConfig[0]?.ordinal,
          },
          () => {
            getAdvancedVisualisationsData({
              requestedGraphTemplate: this.state.requestedGraphTemplate
                ? this.state.requestedGraphTemplate
                : advancedVisualisationsConfig[0]?.visualisation_template || '',
              campaignId: updatedCampaignId,
              resultType: dynamicOptimizationResultType,
            })
          }
        )
      }

      // If we change the tab, ensure that correct rows are showed in the graph
      if (
        prevProps.dynamicOptimizationResultType !==
        this.props.dynamicOptimizationResultType
      ) {
        const { defaultVariantStatusFilter } = this.state
        const subjectLineWithDefaultFilter = this.props.subjectLines.filter(
          (subjectLine: SubjectLine) =>
            defaultVariantStatusFilter.includes(
              subjectLine?.bandit_status?.status
            )
        )

        setFilteredVariants(subjectLineWithDefaultFilter)
        this.setState({
          selectedRows: subjectLineWithDefaultFilter,
          selectedRowKeys: undefined,
          appliedFilters: undefined,
          barGraphKey: JSON.stringify(this.state.defaultVariantStatusFilter),
        })

        if (prevProps.dynamicOptimizationResultType) {
          this.makeLoadResultsCallWithRawOrMatureDataParams()
        }
      }
    } else if (
      prevProps.dynamicOptimizationResultType &&
      prevProps.dynamicOptimizationResultType !==
        this.props.dynamicOptimizationResultType
    ) {
      this.makeLoadResultsCallWithRawOrMatureDataParams()
    }
    const shouldDisplayTestModeBanner = helpers.shouldDisplayTestModeBanner(
      campaignData?.campaign_data,
      campaignData?.campaign_configuration
    )
    if (
      prevCampaignData?.campaign_data?.bandit_data
        ?.dynamic_optimization_paused !==
        campaignData?.campaign_data?.bandit_data?.dynamic_optimization_paused &&
      shouldDisplayTestModeBanner
    ) {
      this.changeToTestTab()
    }

    return true
  }

  componentWillUnmount() {
    helpers.cancellAllIntrvals(window.pollIntervalSelf)
    window.pollIntervalSelf = undefined
    this.resetGraphsState()
    this.props.setDynamicOptimizationResultType(undefined)
  }

  changeToTestTab = () => {
    this.onTabChangeActions('2')
    this.setTabIndex('2')
  }

  resetGraphsState = () => {
    const { getAdvancedVisualisationsData } = this.props
    getAdvancedVisualisationsData({ shouldResetState: true }).then(() => {
      this.setState({
        advancedVisualisationDataRequested: false,
        advancedVisualisations: [],
      })
    })
  }

  shouldRenderGraphs = () => {
    const { campaignData } = this.props

    return (
      helpers.isBanditCampaign(campaignData?.campaign_configuration) &&
      campaignData?.campaign_progress?.test_results_received &&
      campaignData?.campaign_configuration?.advanced_visualisations &&
      this.state.tabIndex !== '2'
    )
  }

  addVariantCell = (variants: any) => {
    const { showBanner } = this.props
    const handleCopy = (text: string) => {
      copyAndReplaceNewLineEmoji(text)

      showBanner({
        type: 'success',
        content: 'Variant copied successfully',
      })
    }
    const values = variants?.values?.map((variant) => {
      return {
        ...variant,
        variant_text: variant.variant,
        variant: (
          <VariantCell
            text={variant.variant ?? ''}
            isControlVariant={variant.ownsl}
            onClickCopy={handleCopy}
          />
        ),
      }
    })
    return {
      ...variants,
      values,
    }
  }

  isAcoustic = (integration) => {
    return (
      integration === 'ibm' ||
      integration === 'ibm watson' ||
      integration === 'acoustic'
    )
  }

  runBackgroundStatusAndIntervalIfNecessary = () => {
    const {
      fetchCampaignBackgroundStatus,
      typeOfIntegration,
      campaignData,
      updatedCampaignId,
    } = this.props
    if (updatedCampaignId) {
      fetchCampaignBackgroundStatus(updatedCampaignId).then(() => {
        helpers.cancellAllIntrvals(window.pollIntervalSelf)
        window.pollIntervalSelf = undefined
        if (
          typeOfIntegration === 'braze' ||
          typeOfIntegration === 'cheetah' ||
          typeOfIntegration === 'mapp' ||
          typeOfIntegration === 'none' ||
          !typeOfIntegration ||
          helpers.isBanditCampaign(campaignData?.campaign_configuration)
        ) {
          return
        }
        if (!window.pollIntervalSelf) {
          window.pollIntervalSelf = setInterval(() => {
            fetchCampaignBackgroundStatus(updatedCampaignId)
          }, BACKGROUND_CAMPAIGN_FETCH_INTERVAL)
        }
      })
    }
  }

  updateCampaignProgressLocalState = (config) => {
    const {
      test_scheduled,
      test_results_received,
      test_cancelled,
      final_send_cancelled,
      final_send_results_received,
      final_send_schedule_started,
    } = config
    const test_schedule_started =
      'test_schedule_started' in config && config.test_schedule_started
    const scheduleButtonConfigValue =
      (!test_results_received && test_cancelled) || !test_scheduled
    this.setState({
      shouldShowScheduleButton:
        scheduleButtonConfigValue && !final_send_results_received,
      shouldShowCancelButton:
        (!final_send_cancelled || !test_cancelled) &&
        !test_results_received &&
        test_scheduled &&
        !final_send_schedule_started,
      shouldDisableButton: test_schedule_started && !test_scheduled,
      hasOnlyVariants: !test_results_received,
      shouldShowInputResultsButton: true,
    })
  }

  // TODO: This function is no longer closing the drawer and should be removed.
  toggleDrawer = (config) => {
    this.setState({
      shouldShowDrawer: config.visible,
      isInputResults: config.isInputResults,
      drawerTitle: config.drawerTitle,
    })

    document.body.classList.toggle('drawer-open', config.visible)
  }

  onCancelSends = (shouldDoCheckBefore?: boolean, cancelType?: any) => {
    const {
      campaignId,
      projectId,
      typeOfIntegration,
      cancelSends,
      stack,
      checkBeforeCancel,
    } = this.props
    if (shouldDoCheckBefore) {
      return checkBeforeCancel(campaignId).then((response) => {
        this.setState({
          shouldOpenCancelModal: response !== null && response !== undefined,
        })
      })
    }
    return cancelSends(
      campaignId,
      projectId,
      typeOfIntegration,
      stack,
      cancelType
    )
  }

  handleCancel = () => {
    this.setState({
      shouldOpenCancelModal: false,
    })
  }

  // Take the result array form the result Endpoint
  // and re order it based on the campaignData.sl array.
  // It's important to take note that this method is only
  // called when we don't have results.
  reorderResult = (resultEndpointData: any) => {
    const { campaignData } = this.props
    const orderedValues: any[] = []
    let valueArray = resultEndpointData.values
    campaignData.sl.forEach((key) => {
      let found = false
      valueArray = valueArray?.filter((item: any) => {
        if (!found && item._id === key._id) {
          orderedValues.push(item)
          found = true
          return false
        }
        return true
      })
    })
    // We then return the data from the endpoint but with the re-ordered values.
    return addColorToVariant({
      ...resultEndpointData,
      values: orderedValues,
    })
  }

  renderAdvancedVisualisationGraph =
    (areMachineOpensExcluded: boolean) =>
    (
      graphType: string,
      { results, average, ...others },
      ordinal: number,
      variantColorMapping: any,
      template: string
      // eslint-disable-next-line max-params
    ) => {
      const resultsUpdated = cloneDeep(results)
      let averageUpdated = cloneDeep(average)
      if (areMachineOpensExcluded) {
        switch (template) {
          case 'lil_bandit_open_rates_over_time':
            averageUpdated = others.average_rate_opens_machine_excluded
            resultsUpdated.forEach((items) => {
              items.data.forEach((item) => {
                item[1] = item[2]
              })
            })
            break
        }
      }
      const { selectedRows, shouldHideTable, barGraphKey } = this.state
      // hide the table when uplift graph is rendered as first by default.
      if (!shouldHideTable && graphType === 'uplift_graph' && ordinal === 1) {
        this.setState({
          shouldHideTable: true,
        })
      }
      if (resultsUpdated?.length < 1) {
        return <WidgetWaitingForChartsData className="m-6" />
      }
      switch (graphType) {
        case 'line_graph':
        case 'line_graph_with_click_listener':
          return (
            <OpenRatesOverTime
              selectedRows={selectedRows}
              results={resultsUpdated}
              average={averageUpdated}
              variantColorMapping={variantColorMapping}
            />
          )
        case 'bar_graph':
          return (
            <BarGraph
              key={barGraphKey}
              shouldEnableRestartButton={(val: boolean) => {
                this.shouldEnableRestartButton(val)
              }}
              setCurrentIndex={(index: number) => {
                this.setGraphIndex(index)
              }}
              currentIndex={this.state.graphIndex || 0}
              results={resultsUpdated}
              selectedRows={selectedRows}
              variantColorMapping={variantColorMapping}
              template={template}
              areMachineOpensExcluded={areMachineOpensExcluded}
            />
          )
        case 'stacked_area':
          return (
            <StackedAreaGraph
              results={resultsUpdated}
              average={averageUpdated}
              selectedRows={selectedRows}
              variantColorMapping={variantColorMapping}
              title="Sent per variant"
            />
          )
        case 'uplift_graph':
          return <UpliftGraph results={resultsUpdated} />
        default:
          return undefined
      }
    }

  sortedGraphListToRender = () => {
    const { advancedVisualisationsConfig } = this.state
    return advancedVisualisationsConfig?.sort(
      (a: any, b: any) => a?.ordinal - b?.ordinal
    )
  }

  handleSelectUiGraphChange = (requestedGraphTemplate: string) => {
    const {
      getAdvancedVisualisationsData,
      campaignId,
      dynamicOptimizationResultType: resultType,
    } = this.props
    const { advancedVisualisationsConfig } = this.state
    const currentGraph = advancedVisualisationsConfig.find(
      (graph: any) => graph.visualisation_template === requestedGraphTemplate
    )

    this.setState(
      {
        shouldHideTable: requestedGraphTemplate === 'lil_bandit_uplift',
        requestedGraphTemplate,
        requestedGraphType: currentGraph.type,
        requestedGraphOrdinal: currentGraph.ordinal,
        graphIndex: 0,
      },
      () => {
        if (!this.isGraphAlreadyLoaded()) {
          getAdvancedVisualisationsData({
            requestedGraphTemplate,
            campaignId,
            resultType,
            showMachineOpens: this.state.showMachineOpensTicked,
          })
        }
      }
    )
  }

  isGraphAlreadyLoaded = () => {
    const { advancedVisualisationsData } = this.props
    const { requestedGraphTemplate } = this.state
    return advancedVisualisationsData[requestedGraphTemplate] !== undefined
  }

  rowSelectionAction = (
    selectedRowKeys: any,
    selectedRows: any,
    filters?: AppliedFilters
  ) => {
    const barGraphKey = Array.isArray(filters)
      ? JSON.stringify(filters)
      : JSON.stringify(filters?.variant_status)

    this.setState({
      selectedRowKeys,
      selectedRows,
      appliedFilters: filters,
      // update key only if we change status filter.
      ...(this.state.barGraphKey !== barGraphKey && {
        barGraphKey,
      }),
    })
    this.props.setFilteredVariants(
      getFilteredVariants(
        this.props.subjectLines,
        filters,
        this.state.defaultVariantStatusFilter
      )
    )
  }

  // TODO: must be refactored
  onFbTypeChange = (val: string) => {
    const { loadFacebookResults, campaignId, campaignData } = this.props
    this.setState({ facebookType: val })
    loadFacebookResults(
      {
        campaign_id: campaignId,
        type: val,
      },
      campaignData.sl
    )
  }

  loadResults = (resultType?: DynamicOptimizationResultType) => {
    const {
      loadResultsDetails,
      // TODO: loadFacebookResults should be refactored
      loadFacebookResults,
      typeOfIntegration,
      campaignData,
    } = this.props
    const primaryGoal =
      campaignData?.campaign_data?.integration_data?.primaryGoal

    switch (typeOfIntegration) {
      case 'facebook':
        // TODO: loadFacebookResults should be refactored
        loadFacebookResults(
          {
            campaign_id: campaignData._id,
            type: initialState.facebookType,
          },
          campaignData.sl
        )
        break
      case 'adobetarget':
      case 'optimizely':
        loadResultsDetails(campaignData?._id, primaryGoal, resultType)
        break
      default:
        loadResultsDetails(campaignData?._id, undefined, resultType)
        break
    }
  }

  shouldEnableRestartButton = (value: boolean) => {
    this.setState({ shouldEnableRestartButton: value })
  }

  setGraphIndex = (graphIndex: number) => {
    this.setState({ graphIndex })
  }

  setTabIndex = (tabIndex: string) => {
    this.setState({ tabIndex })
  }

  makeCampaignResultsTableHeader = ({
    splitResults,
    finalResults,
    variants,
    isBanditCampaign,
  }) => {
    if (splitResults) {
      return isBanditCampaign || finalResults ? undefined : 'Split results'
    }
    if (variants) {
      return isBanditCampaign ? undefined : 'Variants'
    }
  }

  makeLoadResultsCallWithRawOrMatureDataParams(
    overrideResultType?: DynamicOptimizationResultType
  ) {
    const { campaignData, dynamicOptimizationResultType: resultType } =
      this.props
    if (helpers.isBanditCampaign(campaignData?.campaign_configuration)) {
      this.loadResults(overrideResultType || resultType)
    } else {
      this.loadResults()
    }
  }

  onTabChangeActions = (index: string) => {
    const resultTypes: DynamicOptimizationResultType[] = [
      'mature',
      'raw',
      'test',
    ]
    const resultType = resultTypes[index]
    this.resetGraphsState()
    const { setDynamicOptimizationResultType } = this.props
    setDynamicOptimizationResultType(resultType)
    this.setTabIndex(index)
  }

  setToken = async () => {
    const token = await tokenFetcher.getAccessToken()
    this.setState({ token: token.toString() })
  }

  render() {
    const {
      campaignId,
      campaignData,
      typeOfIntegration,
      results: {
        split_campaign_results: splitResults,
        final_campaign_results: finalResults,
        machine_summary: machineSummary,
        variants,
        reports,
      },
      resultsDetailsIsLoading,
      folderName,
      cancelOptions,
      variantsToBeApprovedExperimentsFlow,
      setUserInputForNavigation,
      dynamicOptimizationResultType,
      updateResultsVariantStatus,
      outerContainerClassName,
      innerContainerClassName,
      onRedirect,
    } = this.props
    const variantsToBeApprovedCount: number =
      variantsToBeApprovedExperimentsFlow?.length
    const {
      shouldOpenCancelModal,
      selectedRowKeys,
      shouldHideTable,
      facebookType,
      requestedGraphTemplate,
      requestedGraphType,
      requestedGraphOrdinal,
      shouldEnableRestartButton,
      token,
      showMachineOpensTicked,
    } = this.state
    const isBanditCampaign = helpers.isBanditCampaign(
      campaignData?.campaign_configuration
    )
    const isBroadcastCampaign = helpers.isBroadcastCampaign(campaignData)
    const isEpsilonCampaign = helpers.isEpsilonCampaign(campaignData)
    const isBanditManualConversionsCampaign =
      helpers.isBanditManualConversionsCampaign(
        campaignData?.campaign_configuration
      )
    const { variantColorMapping } = addColorToVariant(
      finalResults || splitResults || variants
    )
    const sortedGraphList = this.sortedGraphListToRender()
    const isSfmcEngageCampaign =
      campaignData?.campaign_configuration &&
      isSFMC(typeOfIntegration) &&
      !shouldShowThePhraseeXIntegrationPanel(campaignData)
    const shouldDisplayTestBanner = helpers.shouldDisplayTestModeBanner(
      campaignData?.campaign_data,
      campaignData?.campaign_configuration
    )
    const shouldShowTestMode = helpers.shouldShowTestMode(
      campaignData?.campaign_configuration
    )
    const tabsToHide = shouldShowTestMode ? [] : [PHRASEE_X_TAB_NAMES.TEST_DATA]

    return (
      <>
        <CancelModal
          data-testid="CancelModal"
          folderName={folderName}
          shouldOpenCancelModal={shouldOpenCancelModal}
          handleCancel={() => this.handleCancel()}
          handleCancelSends={(cancelType: any) =>
            this.onCancelSends(undefined, cancelType)
          }
          cancelOptions={cancelOptions}
          finalSendCancelled={
            !!campaignData?.campaign_progress?.final_send_cancelled
          }
        />
        {campaignData ? (
          <>
            <div className={cx('w-full h-full', outerContainerClassName)}>
              <div
                className={cx(
                  'overflow-x-hidden flex-1-0 bg-gold-40 pb-24',
                  innerContainerClassName
                )}
              >
                {this.isAcoustic(typeOfIntegration) && (
                  <div className="mb-6">
                    <AcousticStatus
                      campaignProgress={campaignData?.campaign_progress}
                    />
                  </div>
                )}
                {isSfmcEngageCampaign && (
                  <SfmcIntegrationStatus
                    campaignProgress={campaignData?.campaign_progress}
                  />
                )}
                {token && typeOfIntegration === 'facebook' && (
                  <>
                    <FacebookStatus
                      inlineText={true}
                      showButton={false}
                      cleanStateOnUnmount={true}
                    />
                    <Select
                      className="w-40 mb-6"
                      optionFilterProp="children"
                      defaultValue={initialState.facebookType}
                      value={facebookType}
                      onChange={(val: string) => this.onFbTypeChange(val)}
                    >
                      {fbTypeOptions.map((option: any) => (
                        <Option value={option.id} key={option.id}>
                          <span title={option.name}>{option.name}</span>
                        </Option>
                      ))}
                    </Select>
                  </>
                )}
                <div className="overflow-x-hidden flex-1-0 bg-gold-40">
                  <BannerContainer>
                    {variantsToBeApprovedCount > 0 &&
                      (helpers.isBanditNlgInitialised(campaignData) ||
                        helpers.isLinguoCampaign(campaignData) ||
                        helpers.isLlmCampaign(campaignData)) && (
                        <NewVariantsBanner
                          variantsToBeApprovedCount={variantsToBeApprovedCount}
                          onClick={() =>
                            !!onRedirect
                              ? onRedirect()
                              : setUserInputForNavigation({
                                  isUserInput: true,
                                  chosenStep: helpers.isLinguoCampaign(
                                    campaignData
                                  )
                                    ? 'content_generation'
                                    : 'language_approval',
                                })
                          }
                        />
                      )}

                    {shouldDisplayTestBanner && (
                      <Banner
                        icon={<TestDummy size={4} />}
                        type="info"
                        heading="This experiment is now in test mode."
                        text={helpers.getTestModeBannerText(
                          campaignData,
                          shouldDisplayTestBanner
                        )}
                        cta="Edit settings"
                        onClick={() =>
                          this.props.openDynamicOptimizationDrawer()
                        }
                      />
                    )}
                  </BannerContainer>
                  <>
                    {isBanditCampaign ? (
                      <>
                        <TabWidget
                          activeTabKey={this.state.tabIndex}
                          data-testid="PhraseeXResultsWidget"
                          destroyInactiveTabPane
                          onTabClick={this.onTabChangeActions}
                          tabs={phraseeXContentTabs(tabsToHide).map(
                            ({ tabName, tabTooltip }) => ({
                              name: (
                                <Tooltip
                                  overlay={
                                    <span className="max-w-xs inline-block">
                                      {tabTooltip}
                                    </span>
                                  }
                                  placement="top"
                                >
                                  <span>{tabName}</span>
                                </Tooltip>
                              ),
                              content: (
                                <>
                                  <hr className="w-full my-8 border-gold-400" />
                                  <BannerContainer>
                                    {campaignData?.input_results_at &&
                                      helpers.isBanditNlgInitialised(
                                        campaignData
                                      ) && (
                                        <Banner
                                          type="info"
                                          icon={
                                            <AlertCircle
                                              className="text-base-700"
                                              isDefaultColor={false}
                                            />
                                          }
                                          text={`Last Updated: ${moment(
                                            campaignData?.input_results_at
                                          )
                                            .local()
                                            .format(
                                              'dddd, MMMM Do YYYY, HH:MM'
                                            )}`}
                                        />
                                      )}
                                    {tabName ===
                                      PHRASEE_X_TAB_NAMES.RAW_DATA && (
                                      <RawDataTabBanner />
                                    )}
                                  </BannerContainer>
                                  {!shouldHideTable &&
                                    (variants || splitResults) && (
                                      <WinnerVariantInfo
                                        results={finalResults}
                                        reports={reports}
                                        showMachineOpensTicked={
                                          showMachineOpensTicked
                                        }
                                      />
                                    )}

                                  {this.shouldRenderGraphs() && (
                                    <>
                                      <GraphsModule
                                        className="mt-6"
                                        sortedGraphList={sortedGraphList}
                                        requestedGraphTemplate={
                                          requestedGraphTemplate
                                        }
                                        handleSelectUiGraphChange={
                                          this.handleSelectUiGraphChange
                                        }
                                        requestedGraphType={requestedGraphType}
                                        shouldEnableRestartButton={
                                          shouldEnableRestartButton
                                        }
                                        setCurrentIndex={this.setGraphIndex}
                                        renderAdvancedVisualisationGraph={this.renderAdvancedVisualisationGraph(
                                          !showMachineOpensTicked
                                        )}
                                        requestedGraphOrdinal={
                                          requestedGraphOrdinal
                                        }
                                        variantColorMapping={
                                          variantColorMapping
                                        }
                                      />
                                    </>
                                  )}
                                  <div
                                    className={cx(styles.campaignResultsTable)}
                                  >
                                    {!shouldHideTable &&
                                      (variants || splitResults) && (
                                        <>
                                          <CampaignResultsTable
                                            campaignId={campaignId}
                                            resultType={
                                              dynamicOptimizationResultType
                                            }
                                            testTrackedMetrics={
                                              campaignData
                                                ?.campaign_configuration
                                                ?.test_tracked_metrics
                                            }
                                            hasResultsReceived={
                                              campaignData?.campaign_progress
                                                ?.test_results_received
                                            }
                                            bordered={false}
                                            header={this.makeCampaignResultsTableHeader(
                                              {
                                                splitResults,
                                                finalResults,
                                                variants,
                                                isBanditCampaign,
                                              }
                                            )}
                                            data-testid="CampaignResults"
                                            results={this.addVariantCell(
                                              // run reorderResult only on variants when there is no results
                                              variants && !splitResults
                                                ? this.reorderResult(variants)
                                                : addColorToVariant(
                                                    splitResults
                                                  )
                                            )}
                                            rowSelectionEnabled={helpers.isBanditCampaign(
                                              campaignData?.campaign_configuration
                                            )}
                                            rowSelectionAction={
                                              this.rowSelectionAction as any
                                            }
                                            selectedRowKeys={selectedRowKeys}
                                            variantColorMapping={addColorToVariant(
                                              variants || splitResults
                                            )}
                                            isBanditCampaign={helpers.isBanditCampaign(
                                              campaignData
                                            )}
                                            isLinguoCampaign={helpers.isLinguoCampaign(
                                              campaignData
                                            )}
                                            isBanditManualConversionsCampaign={
                                              isBanditManualConversionsCampaign
                                            }
                                            appliedFilters={
                                              this.state.appliedFilters
                                            }
                                            updateResultsVariantStatus={
                                              updateResultsVariantStatus
                                            }
                                            isBroadcastCampaign={
                                              isBroadcastCampaign
                                            }
                                            isStatusDropdownDisabled={
                                              isEpsilonCampaign
                                            }
                                            showMachineOpens={
                                              showMachineOpensTicked
                                            }
                                          />
                                        </>
                                      )}
                                  </div>
                                </>
                              ),
                            })
                          )}
                          firstUse={false}
                        />
                      </>
                    ) : (
                      <div className="bg-gold-40 shadow-md">
                        <div className={cx(styles.campaignResultsTable)}>
                          <WinnerVariantInfo
                            results={finalResults}
                            reports={reports}
                            showMachineOpensTicked={showMachineOpensTicked}
                          />
                          {this.shouldRenderGraphs() && (
                            <GraphsModule
                              sortedGraphList={sortedGraphList}
                              requestedGraphTemplate={requestedGraphTemplate}
                              handleSelectUiGraphChange={
                                this.handleSelectUiGraphChange
                              }
                              requestedGraphType={requestedGraphType}
                              shouldEnableRestartButton={
                                shouldEnableRestartButton
                              }
                              setCurrentIndex={this.setGraphIndex}
                              renderAdvancedVisualisationGraph={this.renderAdvancedVisualisationGraph(
                                !showMachineOpensTicked
                              )}
                              requestedGraphOrdinal={requestedGraphOrdinal}
                              variantColorMapping={variantColorMapping}
                            />
                          )}

                          {!shouldHideTable ? (
                            resultsDetailsIsLoading ? (
                              <Loader className="py-18" />
                            ) : variants || splitResults ? (
                              <CampaignResultsTable
                                campaignId={campaignId}
                                bordered={false}
                                header={this.makeCampaignResultsTableHeader({
                                  splitResults,
                                  finalResults,
                                  variants,
                                  isBanditCampaign,
                                })}
                                data-testid="CampaignResults"
                                results={this.addVariantCell(
                                  // run reorderResult only on variants when there is no results
                                  variants && !splitResults
                                    ? this.reorderResult(variants)
                                    : addColorToVariant(splitResults)
                                )}
                                rowSelectionEnabled={helpers.isBanditCampaign(
                                  campaignData?.campaign_configuration
                                )}
                                rowSelectionAction={
                                  this.rowSelectionAction as any
                                }
                                selectedRowKeys={selectedRowKeys}
                                variantColorMapping={addColorToVariant(
                                  variants || splitResults
                                )}
                                isBanditCampaign={helpers.isBanditCampaign(
                                  campaignData
                                )}
                                isLinguoCampaign={helpers.isLinguoCampaign(
                                  campaignData
                                )}
                                appliedFilters={this.state.appliedFilters}
                                updateResultsVariantStatus={
                                  updateResultsVariantStatus
                                }
                                isBroadcastCampaign={isBroadcastCampaign}
                                isStatusDropdownDisabled={isEpsilonCampaign}
                                showMachineOpens={showMachineOpensTicked}
                              />
                            ) : undefined
                          ) : undefined}
                        </div>
                      </div>
                    )}
                  </>
                </div>
                {!shouldHideTable ? (
                  <MachineOpensSummary
                    machineSummary={machineSummary}
                    isMachineOpensVisible={showMachineOpensTicked}
                    onChangeMachineOpensVisible={(isTicked) => {
                      this.setState({ showMachineOpensTicked: isTicked })
                    }}
                  />
                ) : undefined}
              </div>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </>
    )
  }
}

export function mapDispatchToProps(dispatch) {
  const {
    checkBeforeCancel,
    fetchCampaignData,
    loadSummaryDetails,
    loadResultsDetails,
    loadFacebookResults,
    cancelSends,
    fetchCampaignBackgroundStatus,
    getAdvancedVisualisationsData,
    updateResultsTable,
    setUserInputForNavigation,
    setFilteredVariants,
    setDynamicOptimizationResultType,
    updateResultsVariantStatus,
  } = actions
  return {
    setUserInputForNavigation: (config: UserInput) =>
      dispatch(setUserInputForNavigation(config)),
    checkBeforeCancel: (campaignId) => dispatch(checkBeforeCancel(campaignId)),
    showBanner: (config) => dispatch(showBanner(config)),
    fetchCampaignBackgroundStatus: (campaignId) =>
      dispatch(fetchCampaignBackgroundStatus(campaignId)),
    fetchCampaignData: (id) => dispatch(fetchCampaignData(id)),
    loadSummaryDetails: (campaignId, projectId) =>
      dispatch(loadSummaryDetails(campaignId, projectId)),
    loadResultsDetails: (
      id: string,
      primaryGoal?: string,
      resultType?: DynamicOptimizationResultType
    ) => dispatch(loadResultsDetails(id, primaryGoal, resultType)),
    updateResultsVariantStatus: (variantId: string, variantStatus: string) =>
      dispatch(updateResultsVariantStatus(variantId, variantStatus)),
    // TODO: loadFacebookResults should be refactored
    loadFacebookResults: (
      data: FacebookResultsPayload,
      subjectLines: SubjectLine[]
    ) => dispatch(loadFacebookResults(data, subjectLines)),
    // eslint-disable-next-line max-params
    cancelSends: (
      campaignId: any,
      projectId: any,
      token: any,
      typeOfIntegration: any,
      stack: any,
      cancelType?: any | undefined
      // eslint-disable-next-line max-params
    ) =>
      dispatch(
        cancelSends(campaignId, projectId, typeOfIntegration, stack, cancelType)
      ),
    getAdvancedVisualisationsData: ({
      requestedGraphTemplate,
      campaignId,
      shouldResetState,
      resultType,
    }: AdvancedVisualisationsDataParams) =>
      dispatch(
        getAdvancedVisualisationsData({
          requestedGraphTemplate,
          campaignId,
          shouldResetState,
          resultType,
        })
      ),
    updateResultsTable: (newResult) => dispatch(updateResultsTable(newResult)),
    setFilteredVariants: (filteredVariants) =>
      dispatch(setFilteredVariants(filteredVariants)),
    closeDrawer: () => dispatch(closeDrawer()),
    openDynamicOptimizationDrawer: () => dispatch(openDrawer('phraseex')),
    setDynamicOptimizationResultType: (
      resultType: DynamicOptimizationResultType | undefined
    ) => dispatch(setDynamicOptimizationResultType(resultType)),
  }
}

export function mapStateToProps(state: any) {
  return cloneDeep({
    advancedVisualisationsError:
      state.campaignStates.advancedVisualisationsError,
    campaignName: state.campaignStates.campaignName,
    campaignData: state.campaignStates.campaignData,
    shouldCloseMappDrawer: state.campaignStates.shouldCloseMappDrawer,
    ampScript: state.campaignStates?.ampScript,
    summary: state.campaignStates.summary,
    isLoading: state.campaignStates.isLoading,
    resultsDetailsIsLoading: state.campaignStates.resultsDetailsIsLoading,
    typeOfIntegration: state.campaignStates.typeOfIntegration,
    campaignId: state.campaignStates.currentCampaignId,
    projectId: state.campaignStates.projectId,
    subjectLines: state.campaignStates.subjectLines,
    error: state.campaignStates.error,
    isWaitingState: state.campaignStates.isWaitingState,
    authStates: state.authStates,
    sendsCancelled: state.campaignStates?.cancelResponse?.sendsCancelled,
    cancelResponse: state.campaignStates.cancelResponse,
    results: state.campaignStates.results,
    stack: state.campaignStates.campaignData?.stack,
    scheduleResponse: state.campaignStates.scheduleResponse,
    shouldStopPolling: state.campaignStates.shouldStopPolling,
    folderName: state.campaignStates.folderName,
    cancelOptions: {
      splitsSent: state.campaignStates.splitsSent,
      mailingExists: state.campaignStates.mailingExists,
    },
    clearingMovableInkTestData: state.campaignStates.clearingMovableInkTestData,
    updatingMovableInkIntegrationData:
      state.campaignStates.updatingMovableInkIntegrationData,
    advancedVisualisationsData: state.campaignStates.advancedVisualisationsData,
    advancedVisualisationsLoading:
      state.campaignStates.advancedVisualisationsLoading,
    maxVariantOpenRate: state.campaignStates.maxVariantOpenRate,
    variantsToBeApprovedExperimentsFlow:
      state.campaignStates.variantsToBeApprovedExperimentsFlow,
    dynamicOptimizationResultType:
      state.campaignStates.dynamicOptimizationResultType,
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignSummary)
