import { useMemo } from 'react'
import { useState } from 'react'

import { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import TableWidget from 'common/components/widget/tableWidget'
import { formatDateToAgo } from 'common/helpers/date'

import { MergeTagListItem } from '../../../api'
import EditMergeTagDrawer from '../manageMergeTagDrawer/EditMergeTagDrawer'

import MergeTagsActions from './MergeTagsActions'

type Props = {
  mergeTags?: MergeTagListItem[]
}

const MergeTagsList = ({ mergeTags }: Props) => {
  const [selectedTag, setSelectedTag] = useState<{
    id: string | undefined
    isVisible: boolean
  }>({
    id: mergeTags?.[0].id,
    isVisible: false,
  })

  const columns: Column<MergeTagListItem>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: 160,
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Integration',
        accessor: 'integration',
        width: 100,
      },
      {
        Header: 'Category',
        accessor: 'category',
        width: 100,
      },
      {
        Header: 'Date Modified',
        minWidth: 100,
        width: 100,
        accessor: 'lastModified',
        Cell: ({ value }) => (
          <BaseCell className="text-gold-700 text-base">
            {formatDateToAgo(new Date(value))}
          </BaseCell>
        ),
      },
      {
        Header: 'Actions',
        id: 'actions',
        disableSortBy: true,
        accessor: 'id',
        align: 'right',
        disableFilters: true,
        width: 80,
        Cell: ({ row }) => (
          <BaseCell className="invisible group-hover:visible">
            <MergeTagsActions
              tagId={row.original.id}
              tagName={row.original.name}
              onEdit={() => {
                setSelectedTag({
                  id: row.original.id,
                  isVisible: true,
                })
              }}
            />
          </BaseCell>
        ),
      },
    ],
    []
  )

  return (
    <>
      <TableWidget.Widget
        columns={columns}
        data={mergeTags}
        firstUseText="Nothing to see here… There is no content to show."
      />

      <EditMergeTagDrawer
        isOpen={selectedTag.isVisible}
        onOpenChange={() => {
          setSelectedTag(({ id }) => {
            return {
              id,
              isVisible: false,
            }
          })
        }}
        initialValues={mergeTags?.find(({ id }) => selectedTag.id === id)!}
      />
    </>
  )
}

export default MergeTagsList
