import { batch } from 'react-redux'
import { Element } from '@phrasee/phrasee-typings/typings/futurama/element'
import { SplitCalculationType } from '@phrasee/phrasee-typings/typings/futurama/split_calculation_config'
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import uniqueId from 'lodash/uniqueId'
import { RootState } from 'redux/store'
import { SplitSizeData } from 'workflow/interface'
import { fetchCampaignData } from 'workflow/Workflow.actions'

import { Topic } from 'common/components/topics/interfaces'
import { changeAccountId } from 'features/auth/store/authSlice'

import { LeftPaneVisibility } from '../contentPage/components/layout/LeftPanel'
import { RightPaneVisibility } from '../contentPage/components/layout/RightPanel'
import { BRIEF_ELEMENT_ID } from '../contentPage/components/universalBriefElement/constants'
export const MAX_TEMPLATES_TO_ADD = 10

interface UnifiedFlowContentState {
  activeContentId?: string
  selectedElementId?: number | string
  concurrentUsers?: { name: string; id: string }[]
  areElementsDisabled: boolean
  rightPanelVisibility: {
    system: RightPaneVisibility
    user: RightPaneVisibility
  }
  leftPanelVisibility: {
    system: LeftPaneVisibility
    user: LeftPaneVisibility
  }
  shouldShowUnsavedModal?: boolean
  openUnsavedModal?: boolean
  pendingSelection?: {
    elementId?: number | string
  }
  previousStep: string | undefined
  isUsingUniversalBrief: boolean | undefined
  selectedTopics: Topic[]
}

interface UnifiedFlowState extends UnifiedFlowContentState {
  showTemplateView: boolean
  templatesToAdd: TemplateToAdd[]
  contentLibraryFilters: {
    selectedProjects: string[]
    selectedTypes: string[]
    selectedTemplates: string[]
    selectedStatuses: string[]
    selectedOwners: string[]
  }
}

export interface TemplateToAdd {
  id: string
  count: number
  internalId: string
  status: FormStatus
  isOptimizable?: boolean
  isPersonalizable?: boolean
  splitConfiguration?: {
    type: SplitCalculationType
    listSize?: number
    splitNumber?: number
    splitCalculationResult?: SplitSizeData
  }
}

export type FormStatus = 'pristine' | 'incomplete' | 'complete'

const contentInitialState: UnifiedFlowContentState = {
  selectedElementId: undefined,
  concurrentUsers: [],
  activeContentId: undefined,
  areElementsDisabled: false,
  rightPanelVisibility: { system: 'auto', user: 'auto' },
  leftPanelVisibility: { system: 'auto', user: 'auto' },
  shouldShowUnsavedModal: undefined,
  openUnsavedModal: undefined,
  pendingSelection: undefined,
  previousStep: undefined,
  isUsingUniversalBrief: undefined,
  selectedTopics: [],
}

const initialState: UnifiedFlowState = {
  showTemplateView: false,
  templatesToAdd: [],
  contentLibraryFilters: {
    selectedProjects: [],
    selectedTypes: [],
    selectedTemplates: [],
    selectedStatuses: [],
    selectedOwners: [],
  },
  ...contentInitialState,
}

export const selectElementAndFetchCampaignData = createAsyncThunk<
  void,
  Element,
  { state: RootState }
>(
  'unifiedFlow/selectElementAndFetchCampaignData',
  async (element: Element, thunkAPI) => {
    return new Promise<void>((resolve, reject) => {
      const abortController = new AbortController()

      thunkAPI.signal.addEventListener('abort', () => {
        abortController.abort()
        reject(new Error('Aborted'))
      })

      batch(() => {
        thunkAPI.dispatch(selectElement({ elementId: element.element_id }))
        if (!!element?.campaign_id) {
          thunkAPI
            .dispatch(
              fetchCampaignData(element.campaign_id, {}, abortController.signal)
            )
            .then(() => {
              resolve()
            })
        } else {
          resolve()
        }
      })
    })
  }
)

const unifiedFlowSlice = createSlice({
  name: 'unifiedFlow',
  initialState,
  reducers: {
    updateConcurrentUsers: (
      state,
      action: PayloadAction<{ name: string; id: string }[]>
    ) => {
      state.concurrentUsers = action.payload || []
    },
    resetUnsavedModal: (state) => {
      state.shouldShowUnsavedModal = undefined
      state.openUnsavedModal = undefined
    },
    unifiedContentInitialize: (
      state,
      action: PayloadAction<Partial<UnifiedFlowState>>
    ) => {
      state.activeContentId = action.payload.activeContentId
      state.selectedElementId = action.payload.selectedElementId
      state.isUsingUniversalBrief =
        action.payload.isUsingUniversalBrief || state.isUsingUniversalBrief
    },
    selectElement: (
      state,
      action: PayloadAction<{
        elementId?: number | string
      }>
    ) => {
      const { elementId } = action.payload
      if (elementId === state.selectedElementId) {
        return
      }

      state.selectedTopics = []

      if (state.shouldShowUnsavedModal) {
        state.openUnsavedModal = true
        state.pendingSelection = { elementId }
      } else {
        state.selectedElementId = elementId
      }
    },
    userInputFormHasChanged: (
      state,
      action: PayloadAction<{ hasChanged: boolean; isGlobalSettings?: boolean }>
    ) => {
      const { hasChanged, isGlobalSettings } = action.payload
      if (hasChanged && isGlobalSettings) {
        state.areElementsDisabled = true
      } else if (hasChanged && !isGlobalSettings) {
        state.areElementsDisabled = false
        state.shouldShowUnsavedModal = true
      } else {
        state.shouldShowUnsavedModal = false
        state.areElementsDisabled = false
      }
    },
    toggleOpenUnsavedModal: (state, action: PayloadAction<boolean>) => {
      state.openUnsavedModal = action.payload
    },
    toggleIsUsingUniversalBrief: (
      state,
      action: PayloadAction<{
        isUsingUniversalBrief: boolean | undefined
        elementId: number | string
      }>
    ) => {
      state.isUsingUniversalBrief = action.payload.isUsingUniversalBrief
      if (action.payload.isUsingUniversalBrief) {
        state.selectedElementId = BRIEF_ELEMENT_ID
      } else {
        state.selectedElementId = action.payload.elementId
      }
    },
    inputFormLeave: (state) => {
      state.selectedElementId = state.pendingSelection?.elementId
      state.pendingSelection = undefined
      state.openUnsavedModal = false
      state.shouldShowUnsavedModal = false
    },
    toggleElementsDisabled: (state, action: PayloadAction<boolean>) => {
      state.areElementsDisabled = action.payload
    },
    resetContentPageState: (state) => {
      Object.keys(contentInitialState).forEach((key) => {
        state[key] = contentInitialState[key]
      })
    },
    showTemplates: (state) => {
      state.showTemplateView = true
    },
    hideTemplates: (state) => {
      state.showTemplateView = false
      state.templatesToAdd = []
    },

    showRightPanel: (
      state,
      action: PayloadAction<
        { visibility?: RightPaneVisibility; isUserAction?: boolean } | undefined
      >
    ) => {
      state.rightPanelVisibility.system =
        action.payload?.visibility || 'visible'
      state.rightPanelVisibility.user = action.payload?.isUserAction
        ? 'visible'
        : state.rightPanelVisibility.user
    },
    hideRightPanel: (
      state,
      action: PayloadAction<
        { visibility?: RightPaneVisibility; isUserAction?: boolean } | undefined
      >
    ) => {
      state.rightPanelVisibility.system = action.payload?.visibility || 'hidden'
      state.rightPanelVisibility.user = action.payload?.isUserAction
        ? 'hidden'
        : state.rightPanelVisibility.user
    },
    showLeftPanel: (
      state,
      action: PayloadAction<
        { visibility?: LeftPaneVisibility; isUserAction?: boolean } | undefined
      >
    ) => {
      state.leftPanelVisibility.system = action.payload?.visibility || 'visible'
      state.leftPanelVisibility.user = action.payload?.isUserAction
        ? 'visible'
        : state.leftPanelVisibility.user
    },
    hideLeftPanel: (
      state,
      action: PayloadAction<
        { visibility?: LeftPaneVisibility; isUserAction?: boolean } | undefined
      >
    ) => {
      state.leftPanelVisibility.system = action.payload?.visibility || 'hidden'
      state.leftPanelVisibility.user = action.payload?.isUserAction
        ? 'hidden'
        : state.leftPanelVisibility.user
    },
    cancelTemplatesAdd: (state) => {
      state.templatesToAdd = []
      state.showTemplateView = false
    },
    resetTemplatesToAdd: (state) => {
      state.templatesToAdd = []
    },
    postAddTemplate: (
      state,
      action: PayloadAction<Partial<UnifiedFlowState>>
    ) => {
      state.templatesToAdd = []
      state.showTemplateView = false
      state.selectedElementId = action.payload.selectedElementId
    },
    addTemplate(
      state,
      action: PayloadAction<{
        templateId: string
        isOptimizable: boolean
        isPersonalizable: boolean
      }>
    ) {
      const templatesToAddCount = state.templatesToAdd.length
      if (templatesToAddCount >= MAX_TEMPLATES_TO_ADD) {
        return
      }
      const internalId = uniqueId()
      state.templatesToAdd.push({
        id: action.payload.templateId,
        isOptimizable: action.payload.isOptimizable,
        isPersonalizable: action.payload.isPersonalizable,
        count: 1,
        internalId,
        status: 'pristine',
      })
    },
    removeTemplate(
      state,
      action: PayloadAction<{ internalId?: string; templateId?: string }>
    ) {
      if (action.payload.internalId) {
        state.templatesToAdd = state.templatesToAdd.filter(
          (t) => t.internalId !== action.payload.internalId
        )
      } else if (action.payload.templateId) {
        const lastIndex = state.templatesToAdd
          .slice()
          .reverse()
          .findIndex((template) => template.id === action.payload.templateId)

        if (lastIndex !== -1) {
          const indexToRemove = state.templatesToAdd.length - 1 - lastIndex
          state.templatesToAdd.splice(indexToRemove, 1)
        }
      }
    },
    templateSetupComplete: (
      state,
      action: PayloadAction<{
        internalId: string
        status: TemplateToAdd['status']
      }>
    ) => {
      const template = state.templatesToAdd.find(
        (t) => t.internalId === action.payload.internalId
      )
      if (template) {
        template.status = action.payload.status
      }
    },
    updateTemplateSplitConfiguration: (
      state,
      action: PayloadAction<{
        internalId?: string
        splitConfiguration: TemplateToAdd['splitConfiguration']
      }>
    ) => {
      const template = state.templatesToAdd.find(
        (t) => t.internalId === action.payload.internalId
      )
      if (template) {
        template.splitConfiguration = action.payload.splitConfiguration
      }
    },
    setPreviousStep: (
      state,
      action: PayloadAction<{
        previousStep: string | undefined
      }>
    ) => {
      state.previousStep = action.payload.previousStep
    },
    applyContentLibraryFilters: (
      state,
      action: PayloadAction<Partial<UnifiedFlowState['contentLibraryFilters']>>
    ) => {
      state.contentLibraryFilters = {
        ...state.contentLibraryFilters,
        ...action.payload,
      }
    },
    selectTopics: (state, action: PayloadAction<Topic[]>) => {
      state.selectedTopics = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeAccountId.fulfilled, () => initialState)
  },
})

export const {
  resetUnsavedModal,
  updateConcurrentUsers,
  unifiedContentInitialize,
  selectElement,
  resetContentPageState,
  userInputFormHasChanged,
  toggleElementsDisabled,
  showTemplates,
  hideTemplates,
  showRightPanel,
  hideRightPanel,
  showLeftPanel,
  hideLeftPanel,
  addTemplate,
  postAddTemplate,
  removeTemplate,
  cancelTemplatesAdd,
  resetTemplatesToAdd,
  toggleOpenUnsavedModal,
  toggleIsUsingUniversalBrief,
  inputFormLeave,
  templateSetupComplete,
  updateTemplateSplitConfiguration,
  setPreviousStep,
  applyContentLibraryFilters,
  selectTopics,
} = unifiedFlowSlice.actions

/*
 * Selectors
 */

const selectTemplatesToAddByProperty = (
  state: RootState,
  property: keyof TemplateToAdd
): TemplateToAdd[] =>
  state.unifiedFlow.templatesToAdd.filter((template) => template[property])

export const selectTemplatesToAddWithOptimization = (
  state: RootState
): TemplateToAdd[] => selectTemplatesToAddByProperty(state, 'isOptimizable')

export const selectTemplatesToAddWithPersonalization = (
  state: RootState
): TemplateToAdd[] => selectTemplatesToAddByProperty(state, 'isPersonalizable')

export const selectRightPanelVisibility = createSelector(
  [(state: RootState) => state.unifiedFlow.rightPanelVisibility],
  (rightPanelVisibility) => {
    const { user: userVisibility, system: systemVisibility } =
      rightPanelVisibility
    if (
      userVisibility === 'hidden' &&
      (systemVisibility === 'auto' || systemVisibility === 'visible')
    ) {
      return 'hidden'
    }
    return systemVisibility
  }
)

export const selectLeftPanelVisibility = createSelector(
  [(state: RootState) => state.unifiedFlow.leftPanelVisibility],
  (leftPanelVisibility) => {
    const { user: userVisibility, system: systemVisibility } =
      leftPanelVisibility
    if (
      userVisibility === 'hidden' &&
      (systemVisibility === 'auto' || systemVisibility === 'visible')
    ) {
      return 'hidden'
    }
    return systemVisibility
  }
)

export default unifiedFlowSlice.reducer
