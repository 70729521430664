import { useEffect, useRef } from 'react'
import { Form } from 'react-final-form'
import cx from 'classnames'

import Overlay from 'common/components/Overlay'
import { ProjectMappedUiFields } from 'common/interfaces/projects'

import { CepTemplate } from '../../api'

import CreateCepIntegrationWidget from './CreateCepIntegrationWidget'

type Props = {
  cep?: CepTemplate
  onClose: () => void
  onSubmit?: (payload: any) => void
  isCreateWidgetVisible: boolean
}

const CreateCepIntegrationModal = ({
  cep,
  onClose,
  isCreateWidgetVisible,
  onSubmit,
}: Props) => {
  const nameInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    nameInputRef.current?.focus()
  })

  return (
    <div className="bg-gold-40 flex-grow">
      <Form<ProjectMappedUiFields>
        onSubmit={() => {}}
        render={() => (
          <>
            {isCreateWidgetVisible && (
              <Overlay
                className={cx({
                  hidden: !isCreateWidgetVisible,
                })}
              >
                <CreateCepIntegrationWidget
                  cepTemplate={cep}
                  onClose={onClose}
                  onSubmit={onSubmit}
                />
              </Overlay>
            )}
          </>
        )}
      />
    </div>
  )
}

export default CreateCepIntegrationModal
