import { useQueries } from '@tanstack/react-query'

import { getProductCatalogs } from 'common/api/dataSourceApi'
import useGetAccountPrivacyRegionsQuery from 'common/api/queries/useGetAccountPrivacyRegionsQuery'
import { useAppSelector } from 'common/hooks/redux'

import { keys } from '../queryKeys'

const useGetProductCatalogsQuery = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const regionsQuery = useGetAccountPrivacyRegionsQuery()
  const regions = regionsQuery.data ?? []

  const queries = regions.map((region) => ({
    queryKey: keys.productCatalogs({ accountId, region }),
    queryFn: () => getProductCatalogs({ accountId, region }),
    enabled: !!accountId,
    meta: {
      errorMessage: 'The catalogs could not be loaded',
    },
  }))

  const queryResults = useQueries({ queries })

  const combine = (results: typeof queryResults) => {
    return {
      data: results
        .map((result, index) =>
          result.data?.map((catalog) => ({
            id: catalog.id,
            name: catalog.name,
            createdAt: catalog.created_at,
            lastUpdated: catalog.last_updated,
            region: regions[index],
          }))
        )
        .flat()
        .filter((value) => !!value)
        .toSorted((a, b) => {
          if (!a || !b) {
            return 0
          }
          const aTime = new Date(a.lastUpdated ?? a.createdAt).valueOf()
          const bTime = new Date(b.lastUpdated ?? b.createdAt).valueOf()
          return bTime - aTime
        }),
      isLoading:
        results.some((result) => result.isLoading) || regionsQuery.isLoading,
      isError: results.some((result) => result.isError) || regionsQuery.isError,
    }
  }

  return combine(queryResults)
}

export default useGetProductCatalogsQuery
