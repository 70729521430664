import { useMemo } from 'react'
import { Field } from 'react-final-form'

import FormItem from 'common/components/formItem'
import IconTitleGroup from 'common/components/iconTitleGroup'
import InputCombobox from 'common/components/inputCombobox'
import useGetMergeTagsQuery from 'features/admin/accountSettings/api/queries/useGetMergeTagsQuery'

const MergeTags = () => {
  const { data: mergeTags, isLoading, isError } = useGetMergeTagsQuery()

  const mergeTagItems = useMemo(
    () =>
      mergeTags?.map((tag) => ({
        id: tag.id,
        label: tag.name,
        value: tag.id,
        tooltip: tag.code,
      })) || [],
    [mergeTags]
  )

  return (
    <>
      <hr className="w-full my-6 border-gold-400" />
      <div className="flex flex-col mb-6">
        <IconTitleGroup title="Merge tags" />
        <span className="text-base-700 text-base mt-1 mb-4">
          Use merge tags in content generation to dynamically insert
          personalised data, 4 max.
        </span>
        {mergeTagItems.length === 0 ? (
          <span className="text-base-700 text-base mt-4">
            No merge tags have been set up.
          </span>
        ) : (
          <FormItem guidingText="Type the merge tag you want to add in to the input field above.">
            <Field<string[]>
              name="merge_tags"
              render={({ input }) => (
                <InputCombobox
                  items={mergeTagItems}
                  selectedItems={input.value}
                  name={input.name}
                  onApply={input.onChange}
                  isLoading={isLoading}
                  maxOptions={4}
                  placeholder="Select merge tags"
                  isDisabled={isLoading || isError}
                  menuPlacement="top"
                />
              )}
            />
          </FormItem>
        )}
      </div>
    </>
  )
}

export default MergeTags
