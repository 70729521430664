import React, { useEffect, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { revenueSettingsPath } from 'app/navigation/paths'
import { getIsUserTypeAuthorized } from 'app/router/routesUtils'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { connectInternalApi } from 'common/api'
import {
  accountSettingsPermissions,
  getIsAuthorized,
} from 'common/auth/permissions'
import Button from 'common/components/button'
import * as GraphWidget from 'common/components/GraphWidget'
import InfoAction from 'common/components/InfoAction'
import WidgetLoader from 'common/components/loaders/WidgetLoader'
import {
  WidgetFirstUse,
  WidgetIncrementalError,
} from 'common/components/WidgetMessage'
import { useAppSelector } from 'common/hooks/redux'
import { Settings } from 'common/icons'
import { getNonTestProjects } from 'features/campaigns/store/campaignSlice'

import { selectEndDate, selectStartDate } from '../../../store/dashboardSlice'

import IncrementalRevenueWidgetItem from './IncrementalRevenueWidgetItem'

interface Props {
  accountId: string
  averageOrder: number | undefined
  openConversionPercentage: number | undefined
  clickConversionPercentage: number | undefined
  openValue: number | undefined
  clickValue: number | undefined
  setIsConfigModalOpened: (value: boolean) => void
  localLang: string
  currency: string
  projectIds: string[]
}

const IncrementalRevenueWidgetContent = ({
  accountId,
  openConversionPercentage,
  clickConversionPercentage,
  openValue,
  clickValue,
  averageOrder,
  setIsConfigModalOpened,
  localLang,
  currency,
  projectIds,
}: Props) => {
  const flags = useFlags()
  const history = useHistory()

  const currencyFormatter = new Intl.NumberFormat(localLang, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const projects = useAppSelector(getNonTestProjects)
  const { permissions, userType } = useAppSelector((state) => state.authStates)
  const startDate = useAppSelector(selectStartDate)
  const endDate = useAppSelector(selectEndDate)

  const [graphData, setGraphData] = useState<number[]>([])
  const [reportData, setReportData] = useState<number>(0)
  const [hasError, setHasError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    function fetchData() {
      const params = {
        start_date: startDate,
        channel: ['sms', 'email', 'push_notification'],
        calculation_type: 'projected',
        end_date: endDate,
        testing_method: ['split_test_partial', 'split_test_full', 'dynamic'],
        conversion_rate: openConversionPercentage,
        click_conversion_rate: clickConversionPercentage,
        open_value: openValue,
        click_value: clickValue,
        average_order_value: averageOrder,
        project_ids: projectIds,
      }
      return Promise.all([
        connectInternalApi.get<{
          data: { name: string; data: number[] }[]
        }>(
          `v1/core/reporting/reporting/accounts/${accountId}/incremental-revenue/report/graph-data`,
          {
            params,
          }
        ),
        connectInternalApi.get(
          `v1/core/reporting/reporting/accounts/${accountId}/incremental-revenue/report`,
          {
            params: {
              ...params,
              period_type: 'aggregate',
            },
          }
        ),
      ])
    }

    function shouldFetchData() {
      return projectIds.length > 0
        ? projectIds.some(
            (projectId) =>
              projects.find((p) => p.id === projectId)?.distributionChannel ===
              'email'
          )
        : projects.some((p) => p.distributionChannel === 'email')
    }

    setHasError(false)
    if (shouldFetchData()) {
      setIsLoading(true)
      fetchData()
        .then(([graphResponse, reportResponse]) => {
          const {
            data: { data: graphData },
          } = graphResponse

          const {
            data: { data: reportData },
          } = reportResponse

          if (graphData.length) {
            const data = graphData.find(({ name }) =>
              name.toLowerCase().includes('revenue')
            )?.data
            data && setGraphData(data)
          }
          if (reportData.length) {
            setReportData(reportData[0].values[0].value)
          }
        })
        .catch(() => {
          setHasError(true)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }, [
    accountId,
    startDate,
    endDate,
    openConversionPercentage,
    clickConversionPercentage,
    openValue,
    clickValue,
    averageOrder,
    projectIds,
    projects,
  ])

  const openSettings = () => {
    const path = generatePath(revenueSettingsPath, {
      accountId,
    })

    flags.showAdminAccountSettingsPage && flags.showRevenueReporting
      ? history.push(path)
      : setIsConfigModalOpened(true)
  }

  const isConfigured =
    (openConversionPercentage && averageOrder) ||
    (clickConversionPercentage && averageOrder) ||
    openValue ||
    clickValue
  const firstUse = !isLoading && !graphData.length && !hasError
  const hasResults = !isLoading && graphData.length && !hasError
  const hasSettingsPermission = flags.rolesAndPermissions
    ? getIsAuthorized(
        permissions,
        accountSettingsPermissions.accountSettingsView
      )
    : getIsUserTypeAuthorized(userType, 'superhero')

  const getStatus = () => {
    if (isLoading) {
      return 'loading'
    } else if (!isConfigured && hasSettingsPermission) {
      return 'noConfigHasPermission'
    } else if (!isConfigured && !hasSettingsPermission) {
      return 'noConfigNoPermission'
    } else if (firstUse) {
      return 'firstUse'
    } else if (hasError && isConfigured) {
      return 'error'
    } else {
      return 'data'
    }
  }

  const status = getStatus()

  return (
    <>
      <GraphWidget.Widget
        title="Incremental revenue"
        className="min-h-full"
        actions={
          <div className="inline-flex">
            {hasSettingsPermission && (
              <Button
                data-cy="incremental-revenue-settings"
                data-testid="incremental-revenue-settings"
                aria-label="Configure"
                variant="icon"
                size="small"
                className={`opacity-50 text-coolGray-400
                mr-4 hover:opacity-80`}
                onClick={openSettings}
              >
                <Settings />
              </Button>
            )}
            <InfoAction
              message="Supports email experiments with standard and dynamic optimization. Other channels coming soon. 
            Global configuration is used unless selecting a single channel."
            />
          </div>
        }
      >
        {
          {
            loading: (
              <WidgetLoader
                data-cy="revenue-widget-loader"
                data-testid="revenue-widget-loader"
                size="small"
              />
            ),
            firstUse: <WidgetFirstUse className="h-32" />,
            noConfigHasPermission: (
              <div className="flex flex-col justify-between flex-1 ">
                <p className="text-blueGray-500 text-sm">
                  Configure me to view incremental revenue performance for Email
                  experiments.
                </p>
                <Button
                  data-cy="setup-button"
                  data-testid="setup-button"
                  variant="primary"
                  className="self-end text-base font-medium"
                  onClick={openSettings}
                >
                  Set it up now
                </Button>
              </div>
            ),
            noConfigNoPermission: (
              <p className="text-blueGray-500 text-sm">
                Speak to your account admin to turn me on.
              </p>
            ),
            error: <WidgetIncrementalError />,
            data: (
              <div className="flex-grow">
                {!!hasResults ? (
                  <IncrementalRevenueWidgetItem
                    label="For period"
                    value={currencyFormatter.format(reportData)}
                    data={graphData}
                  />
                ) : undefined}
              </div>
            ),
          }[status]
        }
      </GraphWidget.Widget>
    </>
  )
}

//  React.memo is used to prevent the chart to be redrawn when the state
//  of the parent component changes
export default React.memo(IncrementalRevenueWidgetContent)
