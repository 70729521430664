import { useState } from 'react'
import { Route, useParams } from 'react-router-dom'
import { isAtLeastOneAccessFlagEnabled } from 'app/navigation/helpers'
import CustomSwitch from 'app/router/Switch'
import cx from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { DETAILS_TRANSITION_DURATION } from 'common/components/detailsPanel'
import Loader from 'common/components/loaders/Loader'
import { useAppSelector } from 'common/hooks/redux'
import useProjectConfigQuery from 'features/projects/queries/useProjectConfigQuery'

import ProjectDetailsPanel from './components/projectDetailsPanel'
import ProjectNav from './components/projectNav'
import { IntegrationContext } from './context/IntegrationContext'
import { commonRoutes, linguoRoutes, llmRoutes } from './routes'

const EditProject = () => {
  const flags = useFlags()
  const { accountId, projectId } =
    useParams<{ accountId: string; projectId: string }>()
  const newRegionId = useAppSelector(
    (state) => state.contentSettings.rawContent?.projectConfig.region_id
  )
  const projectConfigQuery = useProjectConfigQuery({ accountId, projectId })
  const savedConfig = projectConfigQuery.data
  const languageGenerationMethod =
    savedConfig?.mappedUIFields?.languageGenerationMethod
  const hasIntegrationConfiguration =
    !!savedConfig?.project_configuration?.campaign_configurations
      ?.integration_options?.configuration || false
  const isLinguo = languageGenerationMethod === 'linguo'
  const isLlm = languageGenerationMethod === 'llm'
  const regionId = savedConfig?.region_id || newRegionId
  const cepIntegrationId = savedConfig?.cep_integration_id as string | undefined
  const [isDetailsExpanded, setIsDetailsExpanded] = useState<boolean>(false)

  return (
    <IntegrationContext.Provider
      value={{
        hasIntegrationConfiguration,
        cepIntegrationId: cepIntegrationId,
      }}
    >
      <div
        className={cx(
          `flex-grow bg-gold-40 transition-all duration-${DETAILS_TRANSITION_DURATION}`,
          {
            'mr-13': !isDetailsExpanded && flags.projectDetailsPanel,
            'md:mr-75': isDetailsExpanded && flags.projectDetailsPanel,
          }
        )}
      >
        <Loader
          data-cy="project-page-loader"
          data-testid="project-page-loader"
          isLoading={projectConfigQuery.isLoading}
        >
          {savedConfig?.mappedUIFields && (
            <>
              {(isLinguo || isLlm) && (
                <ProjectNav
                  accountId={accountId}
                  projectId={projectId}
                  title={savedConfig?.mappedUIFields.name}
                  regionId={regionId}
                  languageGenerationMethod={languageGenerationMethod}
                />
              )}
              <CustomSwitch>
                {commonRoutes.map(({ to, component: Component }) => (
                  <Route key={to} path={to} exact>
                    <Component />
                  </Route>
                ))}
                {isLinguo &&
                  linguoRoutes
                    .filter(({ accessFlags }) =>
                      isAtLeastOneAccessFlagEnabled(flags, accessFlags?.flags)
                    )
                    .map(({ to, component: Component }) => (
                      <Route key={to} path={to} exact>
                        <Component />
                      </Route>
                    ))}
                {isLlm &&
                  llmRoutes
                    .filter(({ accessFlags }) =>
                      isAtLeastOneAccessFlagEnabled(flags, accessFlags?.flags)
                    )
                    .map(({ to, component: Component }) => (
                      <Route key={to} path={to} exact>
                        <Component />
                      </Route>
                    ))}
              </CustomSwitch>
            </>
          )}
        </Loader>
      </div>

      {flags.projectDetailsPanel && savedConfig && (
        <ProjectDetailsPanel
          isArchived={savedConfig.archived}
          isActive={!savedConfig.deleted}
          isExpanded={isDetailsExpanded}
          setIsExpanded={setIsDetailsExpanded}
        />
      )}
    </IntegrationContext.Provider>
  )
}

export default EditProject
