import { useState } from 'react'
import Select, {
  ActionMeta,
  components,
  GroupBase,
  InputProps,
  mergeStyles,
  OptionProps,
  Props as SelectProps,
  PropsValue,
  SingleValue,
  StylesConfig,
} from 'react-select'
import cx from 'classnames'

import { Close, Search } from 'common/icons'

import { styles } from './AutocompleteStyles'

export type AutocompleteType = 'default' | 'search'
export type Orientation = 'horizontal' | 'vertical'
export type SelectValue = {
  label: string
  value: string
}

type Props<T> = SelectProps<T, false> & {
  onSearch: (searchValue: string) => void
  orientation?: Orientation
  'data-cy'?: string
  'data-testid'?: string
  'aria-label'?: string
  type?: AutocompleteType
  customStyles?: StylesConfig<T, false>
  showSearchIcon?: boolean
}

const OptionWithSearchIcon = <T,>({
  isSelected,
  children,
  ...rest
}: OptionProps<T, false, GroupBase<T>>) => {
  return (
    <components.Option isSelected={isSelected} {...rest}>
      <div
        className={`px-3 py-2 flex justify-between items-center hover:bg-gray-100 
        text-coolGray-800 font-medium cursor-pointer`}
      >
        <div className="w-6 h-6 ml-3 mr-4">
          <Search />
        </div>
        <div role="option" aria-selected={isSelected}>
          {children}
        </div>
      </div>
    </components.Option>
  )
}
function isSingleSelect(
  obj: PropsValue<SelectValue> | undefined
): obj is SelectValue {
  return !!obj && 'value' in obj
}
const Input = <T,>(props: InputProps<T, false, GroupBase<T>>) => (
  <components.Input
    {...{
      'data-lpignore': true,
      'data-testid': 'single-select-input',
      // 'data-testid': `single-select-input-${props.selectProps['name']}`,
    }}
    {...props}
    isHidden={false}
  />
)

function Autocomplete<T extends SelectValue>({
  onSearch,
  onChange,
  className,
  orientation = 'vertical',
  'data-cy': dataCy,
  'data-testid': dataTestId,
  'aria-label': ariaLabel,
  options = [],
  customStyles,
  type = 'default',
  showSearchIcon = true,
  ...rest
}: Props<T>) {
  const [inputValue, setInputValue] = useState<string>('')
  const [value, setValue] = useState<T | null>(null)
  const [isFocused, setIsFocused] = useState<boolean>(false)

  return (
    <div
      className={cx(
        'relative',
        { 'flex items-center': orientation === 'horizontal' },
        className
      )}
      data-testid={dataTestId}
    >
      {showSearchIcon && (
        <div
          className={cx('absolute z-10', {
            'p-2': type === 'default',
            'py-4 px-6': type === 'search',
          })}
        >
          <Search
            size={4}
            state={isFocused ? 'selected' : 'default'}
            className={cx({ 'opacity-100': isFocused })}
          />
        </div>
      )}
      {((inputValue && inputValue.length > 0) ||
        value ||
        (isSingleSelect(rest.value) &&
          rest.value.value &&
          rest.value.value !== '')) && (
        <button
          aria-label="clear input"
          className={cx('absolute z-10 h-full', {
            'right-2': type === 'default',
            'right-6': type === 'search',
          })}
          onMouseDown={() => {
            setInputValue('')
            setValue(null)
            onSearch('')
            if (onChange) {
              onChange(null, {
                action: 'clear',
                removedValues: value ? [value] : [],
              })
            }
          }}
        >
          <Close
            state={isFocused ? 'selected' : 'default'}
            size={4}
            className={cx({ 'opacity-100': isFocused })}
          />
        </button>
      )}
      <Select<T>
        key={value?.value}
        data-cy={dataCy}
        data-testid={dataTestId}
        onChange={(newValue: SingleValue<T>, actionMeta: ActionMeta<T>) => {
          if (newValue !== null) {
            setValue(newValue)
            onSearch(newValue.label)
            setInputValue(newValue.label)
          }
          if (onChange) {
            onChange(newValue, actionMeta)
          }
        }}
        styles={mergeStyles(styles(type), customStyles)}
        isClearable={true}
        inputValue={inputValue}
        hideSelectedOptions={false}
        blurInputOnSelect={true}
        onBlur={() => setIsFocused(false)}
        value={value}
        aria-label={ariaLabel}
        options={options}
        showSearchIcon={showSearchIcon}
        onInputChange={(newValue, actionMeta) => {
          if (actionMeta.action === 'input-change') {
            if (newValue !== null && newValue !== '') {
              setInputValue(newValue)
              onSearch(newValue)
            } else {
              setInputValue('')
              setValue(null)
              onSearch('')
            }
          }
        }}
        filterOption={(option, inputValue) => {
          if (inputValue && option.label) {
            return option.label
              .replaceAll(' ', '')
              .toLowerCase()
              .includes(inputValue.replaceAll(' ', '').toLowerCase())
          }
          return true
        }}
        isSearchable
        {...rest}
        components={
          type === 'search'
            ? {
                Option: OptionWithSearchIcon,
                Input,
                ...rest.components,
              }
            : { Input, ...rest.components }
        }
      />
    </div>
  )
}

export default Autocomplete
