import Button from 'common/components/button'

type Props = {
  onClick: () => void
  'data-testid'?: string
}

const ManageMergeTagCancelButton = ({
  onClick,
  'data-testid': dataTestId,
}: Props) => {
  return (
    <Button
      onClick={onClick}
      data-testid={dataTestId}
      className="flex items-center bg-gold-300"
    >
      <span className="text-gold-700">Cancel</span>
    </Button>
  )
}

export default ManageMergeTagCancelButton
