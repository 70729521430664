import React from 'react'

import CsvUploader from './CsvUploader'

type Props = {
  fileName: string | undefined
  progressInPercentage: number | undefined
  hasHeaderColumn: boolean
  onStartUpload: (file: File) => void
  onUpload: ({
    data,
    headers,
    fileName,
    numberOfRows,
  }: {
    data: string[][]
    headers: string[]
    fileName: string
    numberOfRows: number
  }) => void
}

const SourceUploader = React.forwardRef<{ open: () => void }, Props>(
  (
    {
      fileName,
      progressInPercentage,
      onStartUpload,
      onUpload,
      hasHeaderColumn,
    },
    ref
  ) => (
    <CsvUploader
      ref={ref}
      fileName={fileName}
      progressInPercentage={progressInPercentage}
      onStartUpload={onStartUpload}
      onUpload={onUpload}
      hasHeaderColumn={hasHeaderColumn}
    />
  )
)

export default SourceUploader
