import { forwardRef, useImperativeHandle } from 'react'
import { Field } from 'react-final-form'
import { UpdateAccountQuery } from '@phrasee/phrasee-typings/typings/integrations/accounts'
import get from 'lodash/get'

import FormItem from 'common/components/formItem'
import Input from 'common/components/input'
import type { IntegrationProviderConfig } from 'features/unifiedFlow/contentPage/integrations/centerSection/api/api'

import useUpsertAssetMutation from '../api/mutations/useUpsertAssetMutation'
import { IntegrationAssetFieldRef } from '../interfaces'

import EmailsTemplatesSelect from './EmailsTemplatesSelect'

const getValidation = (method: IntegrationProviderConfig['method']) => {
  switch (method) {
    case 'create':
      return (value?: string) => {
        if (!value) {
          return 'Required'
        } else if (/\s/.test(value)) {
          return 'Spaces are not allowed in the asset name'
        } else {
          return undefined
        }
      }
    case 'update':
      return (value?: string) => {
        if (!value) {
          return 'Required'
        } else {
          return undefined
        }
      }
    default:
      return undefined
  }
}

type IntegrationAssetFieldProps = UpdateAccountQuery & {
  isDisabled: boolean
  label: string
  name: string
  variant: string
  integration: IntegrationProviderConfig & { field?: string }
  elementId: number
}

const IntegrationAssetField = forwardRef<
  IntegrationAssetFieldRef,
  IntegrationAssetFieldProps
>(
  (
    {
      label,
      name,
      stackOneAccountId,
      isDisabled,
      variant,
      integration: { method, field },
      elementId,
    },
    ref
  ) => {
    const { isLoading, error, reset, isError, mutateAsync } =
      useUpsertAssetMutation()

    const clearApiError = () => {
      return isError && reset()
    }

    useImperativeHandle(ref, () => ({
      upsertAsset: (values, metadata) =>
        mutateAsync({
          value: values[name],
          stackOneAccountId,
          method,
          variant,
          field,
          metadata,
          elementId,
        }),
    }))

    return (
      <Field<string>
        name={name}
        validate={!isDisabled ? getValidation(method) : undefined}
        render={({ input, meta }) => {
          const formError =
            meta.touched && (meta.error || meta.submitError)
              ? meta.error || meta.submitError
              : undefined

          const apiError = get(
            error,
            'response.data.details.validationErrors[0].message'
          )

          return (
            <FormItem
              label={label}
              htmlFor={input.name}
              error={formError || apiError}
              data-testid="integration-asset"
            >
              {
                {
                  create: (
                    <Input
                      type="text"
                      id={input.name}
                      data-testid="integration-asset-input"
                      variant="default"
                      className="text-base-700"
                      disabled={isDisabled || isLoading}
                      value={input.value}
                      name={input.name}
                      onChange={({ target }) => {
                        input.onChange(target.value)
                        clearApiError()
                      }}
                      onBlur={input.onBlur}
                    />
                  ),
                  update: (
                    <EmailsTemplatesSelect
                      id={input.name}
                      stackOneAccountId={stackOneAccountId}
                      isDisabled={isDisabled || isLoading}
                      name={input.name}
                      value={get(input, 'value.value')}
                      onChange={(option) => {
                        input.onChange(option)
                        clearApiError()
                      }}
                      onBlur={input.onBlur}
                    />
                  ),
                }[method]
              }
            </FormItem>
          )
        }}
      />
    )
  }
)

export default IntegrationAssetField
