import { useCallback, useRef } from 'react'
import { AuthStatus, init } from '@thoughtspot/visual-embed-sdk'

import { ThoughtSpotConfig } from '../utils/config'

export const useInitializeThoughtSpot = () => {
  const initializedRef = useRef(false)

  const initializeThoughtSpot = useCallback(() => {
    if (initializedRef.current) {
      return
    }

    const eventEmitter = init(ThoughtSpotConfig)

    if (eventEmitter) {
      eventEmitter
        .on(AuthStatus.SUCCESS, () => (initializedRef.current = true))
        .on(AuthStatus.SDK_SUCCESS, () => (initializedRef.current = true))
        .on(AuthStatus.FAILURE, () => (initializedRef.current = false))
    }
  }, [])

  initializeThoughtSpot()
}
