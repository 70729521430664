import { useFlags } from 'launchdarkly-react-client-sdk'

import { mapCepToIcon } from 'common/cepUtils'
import ActionIcon from 'common/components/ActionIcon'
import Avatar from 'common/components/Avatar'
import { getIconForDistributionChannel } from 'common/components/channelPicker/ChannelPicker'
import Tooltip from 'common/components/Tooltip'
import Widget from 'common/components/Widget'
import { formatDate } from 'common/helpers/formatDate'
import {
  Bin as BinIcon,
  Clock as ClockIcon,
  Edit as EditIcon,
} from 'common/icons'
import { IconType } from 'common/icons/Icon'
import { DistributionChannel } from 'common/interfaces/projects'
import { AvatarColor } from 'features/profile/AvatarColorPicker'

type Props = {
  name: string
  createdAt: string
  userName?: string
  label?: string
  logoUrl?: string
  avatarColor?: AvatarColor
  distributionChannel?: DistributionChannel
  cepName?: string
  onDelete: (e: React.MouseEvent<HTMLSpanElement>) => void
  onUpdate: (e: React.MouseEvent<HTMLSpanElement>) => void
}

const AccountCard = ({
  name,
  userName,
  label,
  logoUrl,
  createdAt,
  avatarColor,
  onUpdate,
  onDelete,
  distributionChannel,
  cepName,
}: Props) => {
  let Icon: IconType | undefined
  const flags = useFlags()
  if (distributionChannel) {
    Icon = getIconForDistributionChannel(distributionChannel)
  }

  const [firstName, lastName] = userName ? userName.split(' ') : []

  return (
    <div role="presentation" className="hover:cursor-pointer group">
      <Widget type="basic" className="h-full">
        <div className="flex justify-between mb-4">
          {cepName && mapCepToIcon(cepName)}
          {logoUrl && (
            <img className="h-10" src={logoUrl} alt={`${name}-logo`} />
          )}
          {firstName && lastName && (
            <Avatar
              firstName={firstName}
              lastName={lastName}
              className="text-xs w-10 h-10 min-w-7 mr-3"
              color={avatarColor}
            />
          )}
          {distributionChannel && (
            <div className="flex items-center">
              <div className="mr-2">
                {Icon && (
                  <Icon className="p-3 w-12 h-12 rounded-full bg-white border-gold-300 border-1" />
                )}
              </div>
            </div>
          )}
        </div>
        <div className="text-coolGray-800 text-base font-medium mb-4 capitalize-first">
          {`${name} ${label}`}
        </div>
        <div className="flex justify-between">
          <div className="flex flex-row justify-start text-base-700">
            <ClockIcon isDefaultColor={false} />
            <div className="ml-2.5 self-center">{formatDate(createdAt)}</div>
          </div>
          <div className="flex ml-auto">
            {flags?.s1DeleteIntegration && (
              <div className="flex gap-4 invisible group-hover:visible">
                <Tooltip overlay="Delete" mouseLeaveDelay={0}>
                  <ActionIcon
                    onClick={onDelete}
                    data-testid="deleteIntegrationIcon"
                  >
                    <BinIcon
                      isDefaultColor={false}
                      className="text-base-700 hover:text-maroon-600"
                    />
                  </ActionIcon>
                </Tooltip>
              </div>
            )}
            {flags?.s1UpdateIntegration && (
              <div className="flex gap-4 invisible group-hover:visible ml-2">
                <Tooltip overlay="Update" mouseLeaveDelay={0}>
                  <ActionIcon
                    onClick={onUpdate}
                    data-testid="updateIntegrationIcon"
                  >
                    <EditIcon
                      isDefaultColor={false}
                      className="text-base-700 hover:text-maroon-600"
                    />
                  </ActionIcon>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </Widget>
    </div>
  )
}

export default AccountCard
