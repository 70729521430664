import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorToast, successToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'

import { brandVoiceKeys } from '../../../brandVoice/api/queryKeys'
import { deleteAdvancedLanguageRules } from '../api'

const useDeleteAdvancedLanguageRulesMutation = () => {
  const queryClient = useQueryClient()

  const accountId = useAppSelector((state) => state.authStates.accountId)
  const brandVoiceId = useAppSelector(
    (state) => state.styleGuide.selectedBrandVoiceId
  )

  return useMutation({
    mutationFn: ({ ruleId }: { ruleId: string }) => {
      if (!brandVoiceId) {
        return Promise.reject('Brand Voice is missing')
      }

      return deleteAdvancedLanguageRules({
        brandVoiceId,
        accountId,
        ruleId,
      })
    },
    onSuccess: () => {
      successToast(`Advanced Language Rule successfully deleted`)
      queryClient.invalidateQueries({
        queryKey: brandVoiceKeys.details(accountId, brandVoiceId!),
      })
    },
    onError: () => {
      errorToast('Advanced Language Rule could not be deleted')
    },
  })
}

export default useDeleteAdvancedLanguageRulesMutation
