import { useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  canAccessAccount,
  getIsAuthorized,
  routesPermissions,
  usersPermissions,
} from 'common/auth/permissions'
import Button from 'common/components/button'
import Modal from 'common/components/Modal'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import useContentHomePathPerFlag from 'common/hooks/useContentPathPerFlag'
import {
  AiContent as AiContentIcon,
  Experiment as ExperimentIcon,
  Reaction as ReactionIcon,
  Report as ReportIcon,
  Users as UsersIcon,
} from 'common/icons'
import { showAddUserModal } from 'features/admin/users/store/usersSlice'
import { clickCreateCampaign } from 'features/campaigns/store/campaignSlice'
import { showTemplates } from 'features/unifiedFlow/store/unifiedFlowSlice'

import MilestoneTracker from './MilestoneTracker'
import QuickCard from './QuickCard'

const QuickCards = () => {
  const flags = useFlags()

  const dispatch = useAppDispatch()
  const history = useHistory()
  const { accountId, permissions, accountCategory } = useAppSelector(
    (state) => state.authStates
  )
  const contentPathPerFlag = useContentHomePathPerFlag()

  const [showNewUserMessage, setShowNewUserMessage] = useState(false)
  const [showSetupBrandVoiceMessage, setShowSetupBrandVoiceMessage] =
    useState(false)
  const hasNotAccess = !canAccessAccount(
    accountCategory,
    routesPermissions.experimentsView
  )
  const handleOnClick = (path: string) => {
    if (hasNotAccess) {
      return () => {
        window.open('https://jacquard.com/pricing/')
      }
    } else {
      return () => {
        history.push(generatePath(path, { accountId }))
        dispatch(clickCreateCampaign())
      }
    }
  }

  if (flags.milestoneTracking) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
        <MilestoneTracker />
        <div className="flex flex-col gap-4">
          {!flags.switchToContentLibrary && (
            <QuickCard
              icon={<ReactionIcon isDefaultColor={false} size={6} />}
              text="Create an experiment"
              hasNotAccess={hasNotAccess}
              onClick={handleOnClick('/campaigns')}
            />
          )}
          <QuickCard
            icon={<ReportIcon isDefaultColor={false} size={6} />}
            text="Create a report"
            hasNotAccess={hasNotAccess}
            onClick={handleOnClick('/reports/performance')}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-6 w-full">
        {!flags.switchToContentLibrary && (
          <QuickCard
            icon={<ReactionIcon isDefaultColor={false} size={6} />}
            text="Create an experiment"
            hasNotAccess={hasNotAccess}
            onClick={handleOnClick('/campaigns')}
          />
        )}
        <QuickCard
          icon={<ReportIcon isDefaultColor={false} size={6} />}
          text="Create a report"
          hasNotAccess={hasNotAccess}
          onClick={handleOnClick('/reports/performance')}
        />
        {!flags.switchToContentLibrary && (
          <QuickCard
            icon={<AiContentIcon isDefaultColor={false} size={6} />}
            text="Draft content"
            onClick={() => {
              history.push(generatePath(contentPathPerFlag))
              dispatch(showTemplates())
            }}
          />
        )}
        <QuickCard
          icon={<UsersIcon isDefaultColor={false} size={6} />}
          text="Invite users"
          onClick={() => {
            if (getIsAuthorized(permissions, usersPermissions.create)) {
              history.push(generatePath('/admin/users'))
              dispatch(showAddUserModal())
            } else {
              setShowNewUserMessage(true)
            }
          }}
        />
        {flags.switchToContentLibrary && (
          <QuickCard
            icon={<ExperimentIcon isDefaultColor={false} size={6} />}
            text="Set up brand voice"
            onClick={() => {
              history.push(
                generatePath(
                  `/admin/accounts/${accountId}/brand-voice?isCreateMode=true`
                )
              )
            }}
          />
        )}
      </div>
      <Modal
        data-cy="info-invite-users"
        data-testid="info-invite-users"
        visible={showNewUserMessage}
        closable={false}
        footer={null}
        hasMargin={true}
        centered
      >
        <div>
          <h1 className="text-2xl font-bold text-base-700 mb-7 inline">
            Invite users
          </h1>
        </div>
        <div className="mt-4 mb-14 text-lg text-coolGray-500">
          Contact your team admin to invite a new user.
        </div>
        <div className="flex justify-end">
          <Button
            variant="primary"
            className="text-base"
            onClick={() => {
              setShowNewUserMessage(false)
            }}
            data-cy="limit-reached-cancel-button"
            data-testid="limit-reached-cancel-button"
          >
            Ok
          </Button>
        </div>
      </Modal>
      <Modal
        data-cy="info-setup-brand-voice"
        data-testid="info-setup-brand-voice"
        visible={showSetupBrandVoiceMessage}
        closable={false}
        footer={null}
        hasMargin={true}
        centered
      >
        <div>
          <h1 className="text-2xl font-bold text-coolGray-800 mb-7 inline">
            Set up brand voice
          </h1>
        </div>
        <div className="mt-4 mb-14 text-lg text-coolGray-500">
          Contact your team admin to set up brand voice.
        </div>
        <div className="flex justify-end">
          <Button
            variant="primary"
            className="text-base"
            onClick={() => {
              setShowSetupBrandVoiceMessage(false)
            }}
          >
            Ok
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default QuickCards
