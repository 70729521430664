import { useMutation, useQueryClient } from '@tanstack/react-query'

import { showErrorToast } from 'common/api/helpers'
import { successToast } from 'common/components/toastNotification'

import { createIntegration } from '../api'
import { cepIntegrationsKeys } from '../queryKeys'

const useCreateIntegration = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: createIntegration,
    onSuccess: () => {
      successToast('Integration has been successfully created')
      queryClient.invalidateQueries({
        queryKey: cepIntegrationsKeys.createCepIntegration(),
      })
    },
    onError: (error) => {
      showErrorToast(error)
    },
  })
}

export default useCreateIntegration
