import { Fragment } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import isEqual from 'lodash/isEqual'

import { SelectValue } from 'common/components/BaseSelect'
import Button from 'common/components/button'
import Input from 'common/components/input'
import { Add as AddIcon, Bin as BinIcon } from 'common/icons'
import { MergeTagListItem } from 'features/admin/accountSettings/api'

const MAX_MERGE_TAG_EXAMPLE_VALUES = 3

type Props = {
  selectedCategory: SelectValue
  systemMergeTags?: MergeTagListItem[]
}

const ExampleValues = ({ systemMergeTags, selectedCategory }: Props) => {
  const form = useForm()
  const { initialValues, values } = useFormState()

  const initialExampleValues = systemMergeTags?.find(
    (tag) => tag.id === selectedCategory?.value
  )?.exampleValues

  const formStateValues = values['merge_tag_example_values'] || []
  const hasAnEmptyInput =
    formStateValues.length > 0 && formStateValues.some((value) => !value)

  const initialValue =
    initialValues['merge_tag_example_values'] ?? initialExampleValues ?? []

  return (
    <Fragment>
      <Field<string[]>
        name="merge_tag_example_values"
        initialValue={initialValue}
        render={({ input }) => {
          return (
            <>
              {input.value.map((inputValue, index) => {
                return (
                  <div key={index} className="w-full flex mb-4">
                    <Input
                      type="text"
                      id={`example_values[${index}]`}
                      variant="default"
                      className="text-coolGray-800"
                      value={inputValue}
                      name={`${input.name}${[index]}`}
                      onChange={(e) => {
                        const newValue = e.target.value
                        const updatedValues = [...input.value]
                        updatedValues[index] = newValue

                        form.change(
                          'merge_tag_example_values',
                          isEqual(updatedValues, initialValue)
                            ? initialValue
                            : updatedValues
                        )
                      }}
                    />
                    <Button
                      variant="icon"
                      onClick={() => {
                        input.onChange(
                          input.value.filter((_, i) => i !== index)
                        )
                      }}
                      prefixIcon={
                        <BinIcon
                          isDefaultColor={false}
                          size={4}
                          className="ml-4 text-base-700"
                        />
                      }
                    />
                  </div>
                )
              })}
            </>
          )
        }}
      />

      <div>
        <Button
          variant="primary"
          size="medium"
          disabled={
            formStateValues.length >= MAX_MERGE_TAG_EXAMPLE_VALUES ||
            hasAnEmptyInput
          }
          prefixIcon={<AddIcon isDefaultColor={false} size={4} />}
          onClick={() =>
            form.change(`merge_tag_example_values`, [...formStateValues, ''])
          }
        >
          Add
        </Button>
      </div>
    </Fragment>
  )
}

export default ExampleValues
