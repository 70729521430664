import React, { Children } from 'react'
import cx from 'classnames'

import Tooltip from './Tooltip'

type Props = {
  className?: string
  tooltip?: string
  'data-testid'?: string
  'data-icon'?: string
  'aria-label'?: string
  onClick: (e: React.MouseEvent<HTMLSpanElement>) => void
}

type IconWrapperProps = {
  className?: string
  'data-testid'?: string
  'data-icon'?: string
  'aria-label'?: string
  onClick: (e: React.MouseEvent<HTMLSpanElement>) => void
}

const IconWrapper: React.FC<IconWrapperProps> = ({
  className,
  'data-testid': dataTestId,
  'data-icon': dataIcon,
  'aria-label': ariaLabel,
  onClick,
  children,
}) => {
  return (
    <span
      className={cx('cursor-pointer', className)}
      data-testid={dataTestId}
      data-icon={dataIcon}
      aria-label={ariaLabel}
      onClick={onClick}
    >
      {children}
    </span>
  )
}

const ActionIcon: React.FC<Props> = ({
  className,
  tooltip,
  'data-testid': dataTestId,
  'data-icon': dataIcon,
  'aria-label': ariaLabel,
  onClick,
  children,
}) => {
  const icon = Children.only(children)

  return (
    <>
      {tooltip ? (
        <Tooltip overlay={tooltip} overlayClassName="max-w-xs break-words">
          <IconWrapper
            className={className}
            onClick={onClick}
            data-testid={dataTestId}
            data-icon={dataIcon}
            aria-label={ariaLabel}
          >
            {icon}
          </IconWrapper>
        </Tooltip>
      ) : (
        <IconWrapper
          className={className}
          onClick={onClick}
          data-testid={dataTestId}
          data-icon={dataIcon}
          aria-label={ariaLabel}
        >
          {icon}
        </IconWrapper>
      )}
    </>
  )
}

export default ActionIcon
