import { useState } from 'react'

import Button from 'common/components/button/Button'
import Modal from 'common/components/Modal'
import { Bin } from 'common/icons'
import { AdvancedLanguageRule } from 'features/admin/brandVoice/store/brandVoiceSlice'

import useDeleteAdvancedLanguageRulesMutation from '../api/mutations/useDeleteAdvancedLanguageRulesMutation'

type Props = {
  rule: AdvancedLanguageRule
}

const AdvancedLanguageRulesActions = ({ rule }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const { mutate, isLoading } = useDeleteAdvancedLanguageRulesMutation()

  return (
    <>
      <Button
        onClick={() => {
          setIsModalVisible(true)
        }}
        variant="icon"
        suffixIcon={
          <Bin isDefaultColor={false} size={4} className="text-base-700" />
        }
      ></Button>
      <Modal
        destroyOnClose
        visible={isModalVisible}
        centered
        onClose={() => setIsModalVisible(false)}
      >
        <div className="w-219">
          <h1 className="text-2xl font-bold text-coolGray-800 inline">
            Do you want to delete this rule?
          </h1>
          <div className="bg-gold-100 p-6 mt-6">
            <span className="text-base-700 font-medium uppercase">Rule</span>
          </div>

          <div className="bg-gold-40 p-6">
            <span className="text-base-700 font-normal">{rule.rule}</span>
          </div>
          <div className="flex justify-end mt-6">
            <Button onClick={() => setIsModalVisible(false)}>Go back</Button>

            <Button
              className="ml-4"
              variant="danger"
              onClick={() => {
                mutate(
                  { ruleId: rule.id },
                  {
                    onSuccess: () => {
                      setIsModalVisible(false)
                    },
                  }
                )
              }}
              loading={isLoading}
              disabled={isLoading}
            >
              Delete rule
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AdvancedLanguageRulesActions
