import { Content, Element } from '@phrasee/phrasee-typings'
import { Template } from '@phrasee/phrasee-typings/typings/futurama/template'
import isArray from 'lodash/isArray'

import { connectInternalApi } from 'common/api'
import { FieldConfiguration } from 'common/components/dynamicFields/interfaces'
import { parseNode, parseReplacer } from 'common/components/topics/helpers'
import { Node, Replacer, Topic } from 'common/components/topics/interfaces'

import type { TemplateToAdd } from '../store/contentSlice'

const TEMPORARY_FIXED_CONTENT_UPDATE_ID = 3

export const getTemplate = async (id: string): Promise<Template> => {
  const result = await connectInternalApi.get<Template>(
    `v1/core/content-gen/templates/${id}`
  )

  return result.data
}

export const getTemplates = async (accountId: string): Promise<Template[]> => {
  const result = await connectInternalApi.get<Template[]>(
    `v1/core/content-gen/accounts/${accountId}/templates`
  )
  return result.data
}

export const deleteVariant = async ({
  accountId,
  contentId,
  elementId,
  variantId,
}: {
  accountId: string
  contentId: string
  elementId: number
  variantId: number
}): Promise<void> => {
  await connectInternalApi.delete(
    `v1/core/content-gen/accounts/${accountId}/contents/${contentId}/elements/${elementId}/variants/${variantId}`
  )
}

export const updateVariant = async ({
  accountId,
  contentId,
  elementId,
  variantId,
  updatedVariantText,
}: {
  accountId: string
  contentId: string
  elementId: number
  variantId: number
  updatedVariantText: string
}): Promise<void> => {
  await connectInternalApi.patch(
    `v1/core/content-gen/accounts/${accountId}/contents/${contentId}/elements/${elementId}/variants/${variantId}`,
    { text: updatedVariantText }
  )
}

export const replaceVariant = async ({
  accountId,
  contentId,
  elementId,
  variantId,
  wsTopic,
}: {
  accountId: string
  contentId: string
  elementId: number
  variantId: number
  wsTopic: string
}): Promise<void> => {
  await connectInternalApi.put(
    `v1/core/content-gen/accounts/${accountId}/contents/${contentId}/elements/${elementId}/variants/${variantId}`,
    {
      contentUpdateId: TEMPORARY_FIXED_CONTENT_UPDATE_ID,
      wsTopic,
    }
  )
}

export const getContent = async ({
  accountId,
  contentId,
}: {
  accountId: string
  contentId: string
}): Promise<Content> => {
  const result = await connectInternalApi.get<Content>(
    `v1/core/content-gen/accounts/${accountId}/contents/${contentId}`
  )

  const topics: Topic[] = []

  result.data.user_input?.entities?.forEach((entity) => {
    if (entity.nodes) {
      entity.nodes.forEach((node) => {
        topics.push(parseNode(node))
      })
    }
    if (entity.replacer) {
      topics.push(parseReplacer(entity.replacer))
    }
  })

  return result.data
}

export const createContent = async ({
  accountId,
  template,
  brandVoiceId,
}: {
  accountId: string
  template: Template
  brandVoiceId?: string
}) => {
  const result = await connectInternalApi.post<Content>(
    `v1/core/content-gen/accounts/${accountId}/contents/`,
    {
      name: template.name,
      template_id: template._id,
      user_input: {
        user_input_form: template.user_input_form,
        ...(brandVoiceId && { brand_voice_id: brandVoiceId }),
      },
    }
  )

  return result.data
}

export const deleteContent = async ({
  accountId,
  contentId,
}: {
  accountId: string
  contentId: string
}) => {
  const result = await connectInternalApi.delete<{
    status: number
    message: string
  }>(`v1/core/content-gen/accounts/${accountId}/contents/${contentId}`)

  return {
    id: contentId,
    data: result.data,
  }
}

export const copyContent = async ({
  accountId,
  contentId,
}: {
  accountId: string
  contentId: string
}) => {
  const result = await connectInternalApi.post<Content>(
    `v1/core/content-gen/accounts/${accountId}/contents/${contentId}`
  )

  return result.data
}

export const updateContent = async ({
  contentId,
  accountId,
  userInputForm,
  updatedName,
  forceNer,
}: {
  contentId: string
  accountId: string
  userInputForm: FieldConfiguration[]
  updatedName?: string
  forceNer?: boolean
}) => {
  const result = await connectInternalApi.patch<Content>(
    `v1/core/content-gen/accounts/${accountId}/contents/${contentId}`,
    {
      user_input: { user_input_form: userInputForm },
      ...(updatedName
        ? { name: updatedName, display_name: updatedName }
        : undefined),
    },
    {
      params: {
        ...(forceNer && { force_ner: forceNer }),
      },
    }
  )

  return result.data
}

export const getContents = async (accountId: string): Promise<Content[]> => {
  const result = await connectInternalApi.get<Content[]>(
    `v1/core/content-gen/accounts/${accountId}/contents/`
  )
  return result.data
}

export const generateVariants = async ({
  contentId,
  elementId,
  accountId,
  wsTopic,
  userInputForm,
  topics,
  isCustomSettings,
  brandVoiceId,
}: {
  contentId: string
  elementId: number
  accountId: string
  wsTopic: string
  userInputForm?: FieldConfiguration[]
  topics?: Topic[]
  isCustomSettings?: boolean
  brandVoiceId?: string
}) => {
  const nodes: Node[] = []
  const replacers: Replacer[] = []

  topics?.forEach((topic) => {
    if (topic.type === 'topic') {
      nodes.push({
        nodeId: topic.original.nodeId,
        node: topic.original.node,
        nodeName: topic.name,
        displayName: topic.name,
        isConstant: topic.isConstant,
      })
    } else {
      replacers.push({
        replacerId: topic.original.replacerId,
        replacer: topic.original.replacer,
        displayName: topic.original.displayName,
        isConstant: topic.isConstant,
        replacerDefinition: {
          ...topic.original.replacerDefinition,
          form_definition: topic.configuration,
        },
        value: isArray(topic.name) ? topic.name.join(';') : topic.name,
      })
    }
  })

  await connectInternalApi.post(
    `v1/core/content-gen/accounts/${accountId}/contents/${contentId}/elements/${elementId}/variants`,
    {
      contentUpdateId: TEMPORARY_FIXED_CONTENT_UPDATE_ID,
      wsTopic,
      nodes,
      replacers,
      ...(userInputForm && {
        user_input: {
          user_input_form: userInputForm,
          ...(brandVoiceId && { brand_voice_id: brandVoiceId }),
        },
      }),
      update_content: !!userInputForm,
      ...(isCustomSettings && { custom_settings: true }),
    }
  )
}

export const generateMoreLikeThisVariants = async ({
  accountId,
  contentId,
  elementId,
  variantId,
  wsTopic,
}: {
  accountId: string
  contentId: string
  elementId: number
  variantId: number
  wsTopic: string
}): Promise<void> => {
  await connectInternalApi.put(
    `v1/core/content-gen/accounts/${accountId}/contents/${contentId}/elements/${elementId}/variants/${variantId}`,
    {
      contentUpdateId: TEMPORARY_FIXED_CONTENT_UPDATE_ID,
      wsTopic,
    },
    {
      params: {
        more_like_this: true,
      },
    }
  )
}

export const selectVariant = async ({
  accountId,
  contentId,
  elementId,
  variantId,
}: {
  accountId: string
  contentId: string
  elementId: number
  variantId: number
}): Promise<Content> => {
  const result = await connectInternalApi.post<Content>(
    `v1/core/content-gen/accounts/${accountId}/contents/${contentId}/elements/${elementId}/variants/${variantId}/approval`
  )

  return result.data
}

export const updateElement = async ({
  accountId,
  contentId,
  elementId,
  updatedElement,
}: {
  accountId: string
  contentId: string
  elementId: number
  updatedElement: Partial<Element>
}) => {
  const result = await connectInternalApi.patch<Content>(
    `v1/core/content-gen/accounts/${accountId}/contents/${contentId}/elements/${elementId}`,
    { ...updatedElement }
  )

  return result.data
}

export const updateElements = async ({
  accountId,
  contentId,
  templates,
}: {
  accountId: string
  contentId: string
  templates: TemplateToAdd[]
}) => {
  let order = 1
  const templatesPayload: { template_id: string; order: number }[] = []

  templates.forEach((template) => {
    for (let i = 0; i < template.count; i++) {
      templatesPayload.push({ template_id: template.id, order })
      order++
    }
  })

  const result = await connectInternalApi.post<Content>(
    `v1/core/content-gen/accounts/${accountId}/contents/${contentId}/elements`,
    { templates: templatesPayload }
  )

  return result.data
}

export const selecteElement = async ({
  accountId,
  contentId,
  elementId,
}: {
  accountId: string
  contentId: string
  elementId: number
}) => {
  const result = await connectInternalApi.post<Content>(
    `v1/core/content-gen/accounts/${accountId}/contents/${contentId}/elements/${elementId}/selected`
  )

  return result.data
}

export const deleteElement = async ({
  accountId,
  contentId,
  elementId,
}: {
  accountId: string
  contentId: string
  elementId: number
}) => {
  const result = await connectInternalApi.delete(
    `v1/core/content-gen/accounts/${accountId}/contents/${contentId}/elements/${elementId}`
  )

  return result.data
}
