export const integrations = [
  { label: 'Acoustic', value: 'acoustic' },
  { label: 'Adobe Campaign Classic', value: 'adobe_campaign_classic' },
  { label: 'Adobe Campaign Standard', value: 'adobe_campaign_standard' },
  { label: 'Adobe Journey Optimizer', value: 'ajo' },
  { label: 'Bloomreach', value: 'bloomreach' },
  { label: 'Braze', value: 'braze' },
  { label: 'Cheetah', value: 'cheetah' },
  { label: 'Cordial', value: 'cordial' },
  { label: 'Emarsys', value: 'emarsys' },
  { label: 'Epsilon', value: 'epsilon' },
  { label: 'Iterable', value: 'iterable' },
  { label: 'Jacquard', value: 'jacquard' },
  { label: 'Klaviyo', value: 'klaviyo' },
  { label: 'Leanplum', value: 'leanplum' },
  { label: 'MessageGears', value: 'message_gears' },
  { label: 'MoEngage', value: 'moengage' },
  { label: 'Oracle Responsys', value: 'responsys' },
  { label: 'Sailthru', value: 'sailthru' },
  { label: 'Salesforce Marketing Cloud', value: 'sfmcv2' },
  { label: 'Movable Ink', value: 'movable_ink' },
]
