export const ROOT_PATH = 'data-sources'
export const PRODUCT_CATALOGS_PATH = 'product-catalogs'

export const keys = {
  productCatalogs: ({
    accountId,
    region,
  }: {
    accountId: string | undefined
    region: string | undefined
  }) => [ROOT_PATH, 'product-catalogs', accountId, region],
}
