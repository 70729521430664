import { FC, useEffect } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'

import FormItem from 'common/components/formItem'
import SingleSelect from 'common/components/singleSelect'
import Tags from 'common/components/tags'
import { getFieldError } from 'features/projects/helpers'
import {
  FinalMetric,
  OptimizationMetricSettings,
  SelectionMetric,
  TrackingMetric,
} from 'features/projects/interface'

import FormCard, { FormCardTitle } from '../formCard'

import AddFinalMetricsModalButton from './components/addFinalMetricsModalButton'
import AddTrackingMetricsModalButton from './components/addTrackingMetricsModalButton'

type Props = {
  optimizationMetricSettings: OptimizationMetricSettings
  isSimplifiedProjectIntegrationOn: boolean
}

const MetricsCard: FC<Props> = ({
  optimizationMetricSettings,
  isSimplifiedProjectIntegrationOn,
}) => {
  const formState = useFormState()
  const { change: formChange } = useForm()
  const { selectionMetric } = formState.values
  const trackingMetrics = formState.values?.trackingMetrics || []
  const finalMetrics = formState.values?.finalMetrics || []
  const {
    selectionMetricSettings,
    trackingMetricsSettings,
    finalMetricsSettings,
  } = optimizationMetricSettings || {}

  const trackingMetricsOptions =
    trackingMetricsSettings?.options.map((option) => {
      const isSelected = trackingMetrics.includes(option.value)

      return {
        ...option,
        isDisabled: option.default && isSelected,
      }
    }) ?? []
  const selectedTrackingMetricsOptions = trackingMetricsOptions.filter(
    ({ value }) => trackingMetrics.includes(value)
  )

  const finalMetricsOptions =
    finalMetricsSettings?.options.map((option) => {
      const isSelected = finalMetrics.includes(option.value)

      return {
        ...option,
        isDisabled: option.default && isSelected,
      }
    }) ?? []
  const selectedFinalMetricsOptions = finalMetricsOptions.filter(({ value }) =>
    finalMetrics.includes(value)
  )

  useEffect(() => {
    const hasOneOption =
      selectionMetricSettings.show &&
      selectionMetricSettings.options.length === 1

    if (hasOneOption && !selectionMetric) {
      formChange('selectionMetric', selectionMetricSettings.options[0].value)
    }

    if (selectionMetric) {
      const isSelectedOptionAvailable = selectionMetricSettings.options.some(
        ({ value }) => value === selectionMetric
      )

      if (!isSelectedOptionAvailable) {
        formChange('selectionMetric', undefined)
      }
    }
  }, [formChange, selectionMetricSettings, selectionMetric])

  const removeTrackingMetric = (value: TrackingMetric) => {
    formChange(
      'trackingMetrics',
      trackingMetrics.filter((item) => item !== value)
    )
  }

  const removeFinalMetric = (value: FinalMetric) => {
    formChange(
      'finalMetrics',
      finalMetrics.filter((item) => item !== value)
    )
  }

  return (
    <>
      <FormCard data-cy="metrics-card" data-testid="metrics-card">
        <div className="flex justify-between">
          <FormCardTitle title="Optimization metrics" />

          <div>
            {trackingMetricsSettings?.show &&
              !isSimplifiedProjectIntegrationOn && (
                <AddTrackingMetricsModalButton
                  options={trackingMetricsOptions}
                  selectedValues={trackingMetrics}
                />
              )}
            {finalMetricsSettings?.show &&
              !isSimplifiedProjectIntegrationOn && (
                <AddFinalMetricsModalButton
                  options={finalMetricsOptions}
                  selectedValues={finalMetrics}
                />
              )}
          </div>
        </div>

        {selectionMetricSettings.show && (
          <Field<SelectionMetric> name="selectionMetric">
            {({ input, meta }) => (
              <FormItem className="mb-6" error={getFieldError(meta)}>
                <SingleSelect
                  data-cy="selection-metric-select"
                  data-testid="selection-metric-select"
                  className="max-w-xs"
                  label="Selection metric"
                  options={selectionMetricSettings.options}
                  value={input.value}
                  onChange={(val) => val && input.onChange(val.value)}
                />
              </FormItem>
            )}
          </Field>
        )}
        {!isSimplifiedProjectIntegrationOn && (
          <>
            {trackingMetricsSettings?.show && (
              <Field<TrackingMetric[]> name="trackingMetrics">
                {({ meta }) => (
                  <FormItem
                    label="Tracking metrics"
                    error={getFieldError(meta)}
                  >
                    {selectedTrackingMetricsOptions.length ? (
                      <Tags<TrackingMetric>
                        data-cy="tracking-metric"
                        isFreeText
                        data-testid="tracking-metric"
                        tags={selectedTrackingMetricsOptions}
                        onRemoveClick={removeTrackingMetric}
                      />
                    ) : (
                      'No tracking metrics selected'
                    )}
                  </FormItem>
                )}
              </Field>
            )}

            {finalMetricsSettings?.show && (
              <Field<FinalMetric[]> name="finalMetrics">
                {({ meta }) => (
                  <FormItem label="Final metrics" error={getFieldError(meta)}>
                    {selectedFinalMetricsOptions.length ? (
                      <Tags<FinalMetric>
                        isFreeText
                        data-cy="final-metric"
                        data-testid="final-metric"
                        tags={selectedFinalMetricsOptions}
                        onRemoveClick={removeFinalMetric}
                      />
                    ) : (
                      'No final metrics selected'
                    )}
                  </FormItem>
                )}
              </Field>
            )}
          </>
        )}
      </FormCard>
    </>
  )
}

export default MetricsCard
