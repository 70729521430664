import type { FormSpyProps } from 'react-final-form'
import { Form, FormSpy } from 'react-final-form'
import { MergeTag } from '@phrasee/phrasee-typings'

import type { MergeTagListItem } from 'features/admin/accountSettings/api/api'

import { integrations } from '../../constants'

import FormContent from './FormContent'
import { parseMergeTagValues } from './helpers'

type Props = {
  initialValues?: MergeTagListItem

  isEditMode?: boolean
  footer?: React.ReactNode
  onSubmit: (values: Omit<MergeTag, '_id' | 'last_updated' | 'created'>) => void
  onChange?: FormSpyProps['onChange']
}

const ManageMergeTagForm = ({
  initialValues,
  isEditMode,
  footer,
  onSubmit,
  onChange,
}: Props) => {
  return (
    <div className="w-219 flex flex-col h-full">
      <Form
        initialValues={
          initialValues
            ? {
                merge_tag_name: initialValues.name,
                merge_tag_description: initialValues.description,
                merge_tag_code: initialValues.code,
                merge_tag_integration: integrations.find(
                  ({ value }) => value === initialValues.integration
                ),
                merge_tag_category: initialValues.category,
                merge_tag_custom_category_name: initialValues.category,
                merge_tag_example_values: initialValues.exampleValues,
              }
            : undefined
        }
        onSubmit={(values) => {
          onSubmit(parseMergeTagValues(values))
        }}
        render={({ handleSubmit }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className="flex flex-col flex-1 h-full"
            >
              <FormSpy
                onChange={(values) => {
                  onChange?.(values)
                }}
              />
              <FormContent isEditMode={isEditMode} />
              <div className="flex flex-none justify-end mt-auto w-full py-6 border-t border-gold-400 gap-3">
                {footer}
              </div>
            </form>
          )
        }}
      />
    </div>
  )
}

export default ManageMergeTagForm
