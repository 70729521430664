import { IntegrationAccount } from '@phrasee/phrasee-typings'

import GridContainer from 'common/components/grid/GridContainer'
import { DistributionChannel } from 'common/interfaces/projects'

import AccountCard from './AccountCard'
import { CepIntegration } from './AccountsPage'

type Props = {
  accounts: CepIntegration[]
  onDelete: (integrationAccount: IntegrationAccount) => void
  onUpdate: (integrationAccount: IntegrationAccount) => void
}

const AccountsGrid = ({ accounts, onDelete, onUpdate }: Props) => {
  return (
    <GridContainer data-testid="accountsGridList">
      {accounts.map(
        ({ _id, name, cep_name, created_at, distribution_channel }, index) => (
          <AccountCard
            key={_id}
            name={name}
            cepName={cep_name}
            label=""
            createdAt={created_at}
            onDelete={() => {}}
            distributionChannel={distribution_channel as DistributionChannel}
            onUpdate={() => {}}
          />
        )
      )}
    </GridContainer>
  )
}

export default AccountsGrid
