import { LiveboardEmbed } from '@thoughtspot/visual-embed-sdk/lib/src/react'

export const Liveboard = () => {
  return (
    <LiveboardEmbed
      className="h-full"
      liveboardId="d084c256-e284-4fc4-b80c-111cb606449a"
    />
  )
}
