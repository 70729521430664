import Loader from 'common/components/loaders/Loader'
import RadialProgress from 'common/components/RadialProgress'
import useGetMilestoneMetricsQuery from 'features/homePage/api/queries/useGetMilestoneMetricsQuery'

import MilestoneContainer from './MilestoneContainer'
import MilestoneItem from './MilestoneItem'

const MilestoneTracker = () => {
  const { isLoading, data } = useGetMilestoneMetricsQuery()

  if (isLoading) {
    return (
      <MilestoneContainer>
        <Loader data-testid="milestone-tracker-loader" />
      </MilestoneContainer>
    )
  }

  if (!data) {
    return (
      <MilestoneContainer className="flex items-center justify-center">
        <div className="text-center">
          <h3 className="text-lg font-medium text-base-700">
            No milestone data available
          </h3>
          <p className="text-sm text-base-600 mt-1">
            Check back later for your quarterly progress
          </p>
        </div>
      </MilestoneContainer>
    )
  }

  const {
    totalPercentage,
    metrics: {
      broadcastCampaigns,
      liveTriggerCampaigns,
      totalExperimentsLaunched,
      uniqueVariantsSent,
      totalSends,
      channelsActivated,
    },
  } = data

  return (
    <MilestoneContainer className="flex gap-4 justify-between items-center">
      <div className="flex items-center gap-3">
        <RadialProgress value={totalPercentage} />
        <span className="text-3xl text-base-700">
          Quarterly
          <br />
          Progress
        </span>
      </div>
      <div className="flex flex-col">
        <MilestoneItem value={broadcastCampaigns.percentage}>
          Broadcast campaigns
        </MilestoneItem>
        <MilestoneItem value={liveTriggerCampaigns.percentage}>
          Trigger campaigns
        </MilestoneItem>
        <MilestoneItem value={totalExperimentsLaunched.percentage}>
          Total experiments launched
        </MilestoneItem>
      </div>
      <div className="flex flex-col">
        <MilestoneItem value={uniqueVariantsSent.percentage}>
          Variants sent{' '}
          <span className="text-gold-600">
            {uniqueVariantsSent.value} / {uniqueVariantsSent.goal}
          </span>
        </MilestoneItem>
        <MilestoneItem value={totalSends.percentage}>Total sends</MilestoneItem>
        <MilestoneItem value={channelsActivated.percentage}>
          Channels activated
        </MilestoneItem>
      </div>
    </MilestoneContainer>
  )
}

export default MilestoneTracker
