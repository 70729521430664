import { useHistory } from 'react-router'
import { Variant } from '@phrasee/phrasee-typings'
import { useFlags } from 'launchdarkly-react-client-sdk'

import ButtonWithDropdown, {
  ButtonOptionsProps,
} from 'common/components/ButtonWithDropdown'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Download } from 'common/icons'
import { useContent } from 'features/futurama/contentPage/hooks'
import { integrationWorkflowCardClicked } from 'features/futurama/store/contentSlice'

import { contentDownloadCsv, contentDownloadXls } from '../../helpers'

type Props = {
  selectedRows: Variant[]
  isEnabled?: boolean
}

const ContentNextActions = ({ selectedRows, isEnabled = false }: Props) => {
  const { content } = useContent()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const flags = useFlags()

  const selectedElementId = useAppSelector(
    (state) => state.content.selectedElementId
  )

  const downloadOptions: ButtonOptionsProps[] = [
    {
      key: 'csv',
      label: 'Export CSV',
      onClick: () => {
        contentDownloadCsv(content)
      },
    },
    {
      key: 'xls',
      label: 'Export XLS',
      onClick: () => {
        contentDownloadXls(content)
      },
    },
  ]

  const gotoIntegration = () => {
    history.push(`/content/${content?._id}/integration`)
    dispatch(
      integrationWorkflowCardClicked({
        elementId: selectedElementId,
      })
    )
  }

  const submitOptions: ButtonOptionsProps[] = [
    ...(flags.showContentIntegrations
      ? [
          {
            key: 'submit',
            label: 'Set up integration',
            onClick: gotoIntegration,
          },
        ]
      : []),
  ]

  return (
    <div className="flex justify-center items-center">
      {isEnabled && (
        <>
          <span className="text-base font-medium text-coolGray-800 mr-4">
            Next up...
          </span>
          <ButtonWithDropdown
            suffixIcon={
              <Download className="text-coolGray-600 ml-2" size={4} />
            }
            options={downloadOptions}
            ghost
          >
            Download content
          </ButtonWithDropdown>
        </>
      )}
      {submitOptions.length > 0 && (
        <ButtonWithDropdown
          className="ml-4"
          ghost
          options={submitOptions}
          disabled={!isEnabled}
        >
          Set up integration
        </ButtonWithDropdown>
      )}
    </div>
  )
}

export default ContentNextActions
