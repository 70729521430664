import { useFormState } from 'react-final-form'

import Button from 'common/components/button'

type Props = {
  isLoading: boolean
  children: React.ReactNode
  'data-testid'?: string
}

const ManageMergeTagSubmitButton = ({
  isLoading,
  children,
  'data-testid': dataTestId,
}: Props) => {
  const { hasValidationErrors, pristine, values } = useFormState()

  const hasAtLeastOneExample = values.merge_tag_example_values?.some(
    (value) => value
  )

  return (
    <Button
      type="submit"
      variant="primary"
      className="mr-6"
      data-testid={dataTestId}
      loading={isLoading}
      disabled={
        hasValidationErrors || pristine || isLoading || !hasAtLeastOneExample
      }
    >
      {children}
    </Button>
  )
}

export default ManageMergeTagSubmitButton
