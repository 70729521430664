import { Field, useForm } from 'react-final-form'

import { SelectValue } from 'common/components/BaseSelect'
import FormItem from 'common/components/formItem'
import SingleSelect from 'common/components/singleSelect'

import { integrations } from '../../constants'

type Props = {
  isLoading: boolean
  isDisabled?: boolean
}

const validate = (value: SelectValue) => {
  if (!value) {
    return 'Required'
  }
}

const Integrations = ({ isLoading, isDisabled }: Props) => {
  const form = useForm()

  const resetFormValues = () => {
    form.change('merge_tag_category', undefined)
    form.change('merge_tag_description', undefined)
    form.change('merge_tag_code', undefined)
    form.change('merge_tag_example_values', undefined)
  }

  return (
    <Field<SelectValue> name="merge_tag_integration" validate={validate}>
      {({ input, meta }) => (
        <FormItem
          label="Integration"
          htmlFor={input.name}
          className="flex-none"
          guidingText="Which integration will use this merge tag?"
          error={meta.touched ? meta.error : undefined}
        >
          <SingleSelect
            isDisabled={isDisabled}
            data-testid="add-merge-tag-drawer-integration-input"
            id={input.name}
            name={input.name}
            options={integrations}
            value={input.value.value}
            onChange={(value) => {
              input.onChange(value)
              resetFormValues()
            }}
            onBlur={input.onBlur}
            onFocus={input.onFocus}
            isLoading={isLoading}
          />
        </FormItem>
      )}
    </Field>
  )
}

export default Integrations
