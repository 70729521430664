import { ComponentElement, Element } from '@phrasee/phrasee-typings'
import find from 'lodash/find'
import isNil from 'lodash/isNil'

import useListAccountsQuery from 'common/api/queries/useListAccountsQuery'
import { useAppSelector } from 'common/hooks/redux'
import {
  isComponentElement,
  isTemplateElement,
} from 'features/unifiedFlow/api/interfaces'
import { useSelectedElement } from 'features/unifiedFlow/contentPage/hooks'
import type { IntegrationProviderConfig } from 'features/unifiedFlow/contentPage/integrations/centerSection/api/api'
import {
  getAvailableIntegrations,
  getSelectedIntegrationAccount,
} from 'features/unifiedFlow/contentPage/integrations/helpers'

import useGetIntegrationsRoutingConfigQuery from '../centerSection/api/queries/useGetIntegrationsRoutingConfigQuery'

interface Asset {
  id: string
  label: string
  value: string
  isDisabled: boolean
  elementId: number
  integration?: IntegrationProviderConfig
  variant?: string
}

const convertElementToAsset = (element: ComponentElement): Asset => {
  return {
    id: `${element.element_id}`,
    label: element.display_name || element.name,
    value: element.name,
    isDisabled: element.integration_status === 'finished',
    variant: element?.value,
    elementId: element.element_id,
  }
}

const getAssets = (element: Element | undefined): Asset[] => {
  if (isComponentElement(element!)) {
    return [convertElementToAsset(element)]
  } else if (isTemplateElement(element!)) {
    return element.elements.map((nestedElement) =>
      convertElementToAsset(nestedElement as ComponentElement)
    )
  } else {
    return []
  }
}

const useAssets = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const { data: selectedElement } = useSelectedElement()
  const { data: accounts } = useListAccountsQuery(accountId)

  return useGetIntegrationsRoutingConfigQuery<Asset[]>({
    enabled: !!selectedElement,
    select: (data) => {
      const assets = getAssets(selectedElement)

      const filteredIntegrations = data.filter(({ name }) => {
        return assets.map(({ value }) => value).includes(name)
      })

      const selectedIntegrationAccount = getSelectedIntegrationAccount(
        accounts,
        getAvailableIntegrations(filteredIntegrations),
        selectedElement?.integration_account_id
      )

      return assets.flatMap((asset) => {
        const { value, isDisabled } = asset
        const integrationAsset = find(data, { name: value })

        const integration =
          integrationAsset?.providers?.[
            selectedIntegrationAccount?.provider ?? ''
          ]

        const variantSections = selectedElement?.variant_sections

        if (variantSections) {
          const variantValues = asset.variant?.split('〽') ?? []
          return variantSections.map((variantSection, index) => {
            return {
              id: `${asset.id}-${index}`,
              label: `${asset.label} - ${variantSection.name}`,
              value: `${asset.value} - ${variantSection.name}`,
              variant: variantValues[index] || '',
              isDisabled: isNil(integration) || isDisabled,
              integration,
              elementId: asset.elementId,
            }
          })
        } else {
          return {
            ...asset,
            variant: asset.variant || '',
            isDisabled: isNil(integration) || isDisabled,
            integration,
          }
        }
      })
    },
  })
}

export default useAssets
