import { useState } from 'react'
import cx from 'classnames'

import { mapCepToIcon } from 'common/cepUtils'
import Widget from 'common/components/Widget'
import { CepTemplate } from 'features/admin/accountSettings/api/api'

import CreateCepIntegrationModal from '../CreateCepIntegrationModal'

type Props = {
  name: string
  logoUrl?: string
  cep?: CepTemplate
  className?: string
  onSubmit?: (payload: any) => void
}

const IntegrationCardCepIntegration = ({
  name,
  logoUrl,
  cep,
  className,
  onSubmit,
}: Props) => {
  const [isCreateWidgetVisible, setIsCreateWidgetVisible] =
    useState<boolean>(false)
  const logoSize = 22
  const onClose = () => {
    setIsCreateWidgetVisible(false)
  }

  return (
    <Widget
      type="inner"
      className="border border-solid hover:border-maroon-400 h-61"
    >
      <div
        className={cx('flex flex-col items-center', className)}
        onClick={(e) => {
          e.stopPropagation()
          setIsCreateWidgetVisible(true)
        }}
        role="button"
      >
        <div className="mt-6 h-32">
          {mapCepToIcon(cep?.cep_name!, logoSize)}
        </div>
        <div className="text-coolGray-800 text-base font-medium">{name}</div>
      </div>
      {isCreateWidgetVisible && (
        <CreateCepIntegrationModal
          cep={cep}
          isCreateWidgetVisible={isCreateWidgetVisible}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      )}
    </Widget>
  )
}

export default IntegrationCardCepIntegration
