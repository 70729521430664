import { MergeTag } from '@phrasee/phrasee-typings'
import compact from 'lodash/compact'

const parseMergeTagValues = (
  values
): Omit<MergeTag, '_id' | 'last_updated' | 'created'> => {
  const name = values.merge_tag_name
  const description = values.merge_tag_description
  const category = values.merge_tag_category.label
  const code = values.merge_tag_code
  const exampleValues = compact<string>(values.merge_tag_example_values)
  const integration = values.merge_tag_integration.value
  const customCategory = values.merge_tag_custom_category_name

  return {
    name,
    description,
    category: category?.toLowerCase() === 'custom' ? customCategory : category,
    code,
    example_values: exampleValues,
    integration,
  }
}

export { parseMergeTagValues }
