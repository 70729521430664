import { useFlags } from 'launchdarkly-react-client-sdk'
import cond from 'lodash/cond'
import entries from 'lodash/entries'
import flatMap from 'lodash/flatMap'
import includes from 'lodash/includes'
import stubTrue from 'lodash/stubTrue'

import {
  colCount,
  incrementalOpen,
  incrementalOpenMachineExcluded,
  openUplift,
  openUpliftMachineExcluded,
} from './constants'

export type ReportsData = Record<string, string | number | null> | undefined

export interface ResultColumn {
  title: string
  key: string
  dataIndex: string
  value?: string | number | null
}

interface ResultValue {
  [key: string]: string | number
}

export type ResultsData = {
  columns: ResultColumn[]
  values: ResultValue[]
} | null

export type ShowMachineOpensTicked = boolean | undefined

const getFilteredReportsCollection = (
  reports: ReportsData,
  showMachineOpens: boolean,
  showMachineOpensTicked: ShowMachineOpensTicked
) => {
  return Object.entries(reports || {}).filter(([key]) => {
    const flattenedReports = flatMap(entries(reports), (item) => item)

    return cond([
      [
        () => showMachineOpens,
        () => {
          if (includes(flattenedReports, openUpliftMachineExcluded)) {
            if (key === openUpliftMachineExcluded && showMachineOpensTicked) {
              return false
            }
            if (key === openUplift && !showMachineOpensTicked) {
              return false
            }
          }
          if (includes(flattenedReports, incrementalOpenMachineExcluded)) {
            if (
              key === incrementalOpenMachineExcluded &&
              showMachineOpensTicked
            ) {
              return false
            }
            if (key === incrementalOpen && !showMachineOpensTicked) {
              return false
            }
          }
          return true
        },
      ],
      [
        () => !showMachineOpens,
        () =>
          !(
            key === openUpliftMachineExcluded ||
            key === incrementalOpenMachineExcluded
          ),
      ],
      [stubTrue, () => true],
    ])()
  })
}

const getReports = ({
  reports,
  hasReportsData,
  showMachineOpens,
  showMachineOpensTicked,
}: {
  reports: ReportsData
  hasReportsData: boolean
  showMachineOpens: boolean
  showMachineOpensTicked: ShowMachineOpensTicked
}) => {
  // Temporary hide click uplift for in-body projects until the BE is fixed. HH-1774
  if (
    reports &&
    hasReportsData &&
    'In-Body Click Uplift' in reports &&
    'Click Uplift' in reports
  ) {
    delete reports['Click Uplift']
  }

  const filteredReports = hasReportsData
    ? getFilteredReportsCollection(
        reports,
        showMachineOpens,
        showMachineOpensTicked
      )
    : []

  return {
    reportsCellCount: filteredReports.length,
    reportsCollection: filteredReports,
  }
}

export const getResults = (results: ResultsData) => {
  const resultsCollection = (
    results?.columns.filter(({ key, dataIndex }) => {
      const value = results && results.values[0][dataIndex]
      return key !== 'variant' && value
    }) ?? []
  ).map((item) => {
    return {
      ...item,
      value: results?.values[0][item.dataIndex],
    }
  })

  return {
    resultsCollection,
    resultsCellCount: resultsCollection.length,
  }
}

export const getWinnerColSpan = (
  reportsCellCount: number,
  totalCellCount: number
) => {
  const hasOneDataRow = totalCellCount <= colCount
  const hasReportsDataInWinnerRow = !hasOneDataRow && reportsCellCount <= 2

  return {
    winnerColSpan: hasReportsDataInWinnerRow
      ? colCount - reportsCellCount
      : colCount,
    hasOneDataRow,
    hasReportsDataInWinnerRow,
  }
}

const useWinnerVariantInfo = ({ reports, results, showMachineOpensTicked }) => {
  const { showMachineOpens } = useFlags()

  const hasReportsData = !!(reports && Object.keys(reports).length)

  const hasResultsData = !!results

  const winnerVariant = results && results.values[0]['variant']

  const { reportsCellCount, reportsCollection } = getReports({
    reports,
    hasReportsData,
    showMachineOpens,
    showMachineOpensTicked,
  })

  const { resultsCellCount, resultsCollection } = getResults(results)

  const totalCellCount = resultsCellCount + reportsCellCount

  const { winnerColSpan, hasOneDataRow, hasReportsDataInWinnerRow } =
    getWinnerColSpan(reportsCellCount, totalCellCount)

  const emptyReportsCellCount =
    hasOneDataRow || hasReportsDataInWinnerRow ? 0 : colCount - reportsCellCount

  const emptyResultsCellCount = hasOneDataRow
    ? colCount - totalCellCount
    : colCount - resultsCellCount

  return {
    hasResultsData,
    hasReportsData,
    winnerVariant,
    reportsCellCount,
    reportsCollection,
    resultsCollection,
    winnerColSpan,
    emptyReportsCellCount,
    emptyResultsCellCount,
  }
}

export default useWinnerVariantInfo
