import { ConversationEmbed } from '@thoughtspot/visual-embed-sdk/lib/src/react'

export const Spotter = () => {
  return (
    <ConversationEmbed
      className="h-full"
      worksheetId="668bb2ea-8c64-4812-b853-053b9d770d0b"
    />
  )
}
