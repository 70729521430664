import { useQuery } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'

import useGetAccountPrivacyRegionsQuery from 'common/api/queries/useGetAccountPrivacyRegionsQuery'
import { TreeNode } from 'common/components/catalog/Catalog'
import { useAppSelector } from 'common/hooks/redux'

import { getProductCatalogCategories } from '../api'
import { keys } from '../queryKeys'

export type ProductCatalog = {
  id: string
  name: string
  created: string
  categories: any
  type: string
  status: 'processing' | 'success' | 'failed' | undefined
}
function hasChildrenWithEmptyValues(node: Object) {
  return Object.values(node).every((value) => {
    if (typeof value === 'object') {
      return isEmpty(value)
    }
    return false
  })
}

export function mapObjectToTreeNode(object: Object): TreeNode {
  return Object.entries(object)
    .sort((a, b) => a[0].localeCompare(b[0], 'en', { numeric: true }))
    .reduce((previousValue, [key, value]) => {
      return {
        ...previousValue,
        [key]:
          hasChildrenWithEmptyValues(value) || Object.keys(value).length === 0
            ? Object.keys(value).sort((a, b) =>
                a.localeCompare(b, 'en', { numeric: true })
              )
            : mapObjectToTreeNode(value),
      }
    }, {})
}

const useGetProductCatalogCategoriesQuery = ({
  catalogId,
}: {
  catalogId: string
}) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const regionsQuery = useGetAccountPrivacyRegionsQuery()
  const regions = regionsQuery.data ?? []
  const region = regions[0]

  const getProductCatalogCategoriesQuery = useQuery(
    keys.productCatalogCategories({ accountId, region, catalogId }),
    () => getProductCatalogCategories({ accountId, region, catalogId }),
    {
      select: (data) => {
        if (!data) {
          return undefined
        }
        return {
          categories: mapObjectToTreeNode(data.product_categories),
        }
      },
      enabled: !!accountId,
      initialData: {
        product_categories: [],
      },
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      meta: {
        errorMessage: 'The catalog could not be loaded',
      },
    }
  )

  return getProductCatalogCategoriesQuery
}

export default useGetProductCatalogCategoriesQuery
