import { useQuery } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'

import {
  getProductCatalogSettings,
  ProductCatalogSettingsResponse,
} from '../api'
import { keys } from '../queryKeys'

interface UseGetProductCatalogSettingsProps {
  productCatalogId: string | undefined
  region: string
}

// Extended type with fields expected by the CsvProductPreview component
export interface TransformedProductCatalogSettings
  extends ProductCatalogSettingsResponse {
  productNameColumnName?: string
  productIdColumnName?: string
  productDescriptionColumnName?: string
  separatedBy?: string
  categoryDelimiter?: string
  categoryColumnName?: string
  categoryDescriptionColumnName?: string
  categoryLevelsColumnNames?: string[]
  categoryDescriptionLevelsColumnNames?: string[]
  sourceFieldNames?: string[] // Add this to store all source field names
}

/**
 * Hook to fetch the settings for a product catalog
 */
const useGetProductCatalogSettingsQuery = ({
  productCatalogId,
  region,
}: UseGetProductCatalogSettingsProps) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  return useQuery<
    ProductCatalogSettingsResponse,
    Error,
    TransformedProductCatalogSettings
  >({
    queryKey: keys.productCatalogSettings({
      accountId,
      productCatalogId: productCatalogId || '',
      region,
    }),
    queryFn: () =>
      productCatalogId
        ? getProductCatalogSettings({ accountId, productCatalogId, region })
        : Promise.reject('No product catalog ID provided'),
    enabled: !!productCatalogId,
    select: (data) => {
      // Extract category and description fields from the mapping
      const categoryFields: string[] = []
      const categoryDescriptionFields: string[] = []

      // Determine if data is separated by columns or delimiter
      const separatedBy =
        data.mapping?.import_type === 'column' ? 'columns' : 'delimiter'

      // Get all source field names
      const sourceFieldNames =
        data.mapping?.fields?.map((field) => field.source_field_name) || []

      // Process mapping fields
      data.mapping?.fields?.forEach((field) => {
        if (
          field.destination_field_name === 'category' &&
          field.product_category_level
        ) {
          categoryFields[field.product_category_level - 1] =
            field.source_field_name
        } else if (
          field.destination_field_name === 'category_description' &&
          field.category_description_level
        ) {
          categoryDescriptionFields[field.category_description_level - 1] =
            field.source_field_name
        }
      })

      const productIdField = data.mapping?.fields?.find(
        (f) => f.destination_field_name === 'product_id'
      )
      const productNameField = data.mapping?.fields?.find(
        (f) => f.destination_field_name === 'product_name'
      )
      const productDescriptionField = data.mapping?.fields?.find(
        (f) => f.destination_field_name === 'product_description'
      )
      const separatorField = data.mapping?.fields?.find(
        (f) => f.product_category_delimiter !== undefined
      )

      const categoryDescriptionField = data.mapping?.fields?.find(
        (f) => f.destination_field_name === 'category_description'
      )

      return {
        ...data,
        // Map fields to match what CsvProductPreview expects
        productNameColumnName: productNameField?.source_field_name,
        productIdColumnName: productIdField?.source_field_name,
        productDescriptionColumnName:
          productDescriptionField?.source_field_name,
        separatedBy: separatedBy,
        categoryDelimiter: separatorField?.product_category_delimiter, // Default delimiter if needed
        categoryColumnName: separatorField?.source_field_name, // Primary category column for delimiter mode
        categoryDescriptionColumnName:
          categoryDescriptionField?.source_field_name, // Primary category description for delimiter mode
        categoryLevelsColumnNames:
          categoryFields.length > 0 ? categoryFields : [''],
        categoryDescriptionLevelsColumnNames:
          categoryDescriptionFields.length > 0
            ? categoryDescriptionFields
            : [''],
        sourceFieldNames, // Include all source field names from the mapping
      }
    },
    onError: (error) => {
      errorToast('Failed to load product catalog settings')
    },
  })
}

export default useGetProductCatalogSettingsQuery
