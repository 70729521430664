import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import cx from 'classnames'
import csvParser from 'papaparse'

import Button from 'common/components/button'
import { errorToast } from 'common/components/toastNotification'

type Props = {
  onUpload: ({
    data,
    headers,
    fileName,
    numberOfRows,
  }: {
    data: string[][]
    headers: string[]
    fileName: string
    numberOfRows: number
  }) => void
  onStartUpload: (file: File) => void
  progressInPercentage: number | undefined
  fileName: string | undefined
  hasHeaderColumn: boolean
  description?: string
}

const MAX_FILE_SIZE_BYTES = 10737418240 // 10 Gb in bytes

const uploadCsv = ({
  file,
  onUpload,
  onStartUpload,
  hasHeaderColumn,
}: {
  file: File
  onUpload: ({
    data,
    headers,
    fileName,
    numberOfRows,
  }: {
    data: string[][]
    headers: string[]
    fileName: string
    numberOfRows: number
  }) => void
  onStartUpload: any
  hasHeaderColumn: boolean
}) => {
  onStartUpload(file)
  return new Promise<string[][]>((resolve, reject) => {
    // big files work only using the step function but it does not inform when all the rows are parsed
    const LIMIT_SIZE = 50000000 // 50 MB

    let rowCount = 0
    csvParser.parse<string[]>(file, {
      header: false,
      skipEmptyLines: 'greedy',
      step: function () {
        rowCount++
      },
    })

    const partialFile = new File([file.slice(0, LIMIT_SIZE)], file.name)
    csvParser.parse<string[]>(partialFile, {
      header: false,
      skipEmptyLines: 'greedy',
      preview: 20,
      complete: function (results, file) {
        const data = results.data
        const errors = results.errors
        if (errors.length > 0) {
          return reject(new Error(errors[0].message ?? 'Unknown error'))
        }
        onUpload({
          data,
          headers: [],
          fileName: file?.name ?? '',
          numberOfRows: rowCount,
        })
        return resolve(results.data)
      },
      error: function (error, file) {
        errorToast(
          `An error occured while uploading the CSV file ${file?.name ?? ''}`
        )
        reject(new Error(error.message))
      },
    })
  })
}

const CsvUploader = forwardRef<{ open: () => void }, Props>(
  (
    {
      fileName,
      onStartUpload,
      onUpload,
      progressInPercentage,
      hasHeaderColumn,
      description = '',
    },
    ref
  ) => {
    const [isLoading, setIsLoading] = useState(false)

    const onDrop = useCallback(
      (acceptedFiles: File[], fileRejections: FileRejection[]) => {
        if (acceptedFiles.length > 0) {
          const csvFile = acceptedFiles[0]
          setIsLoading(true)
          uploadCsv({
            file: csvFile,
            onUpload,
            onStartUpload,
            hasHeaderColumn,
          })
            .catch((error: Error) => errorToast(error.message))
            .finally(() => setIsLoading(false))
        }
        if (fileRejections.length > 0) {
          fileRejections.forEach((file) => {
            file.errors.forEach((error) => {
              if (error.code === 'file-too-large') {
                errorToast(`Error: ${error.message}`)
              }

              if (error.code === 'file-invalid-type') {
                errorToast(`Error: ${error.message}`)
              }
            })
          })
        }
      },
      [onStartUpload, onUpload, hasHeaderColumn]
    )
    const isDisabled = isLoading || progressInPercentage !== undefined
    const { getRootProps, getInputProps, open } = useDropzone({
      accept: {
        'text/csv': ['.csv'],
        'application/vnd.ms-excel': ['.csv'], // for Windows
      },
      multiple: false,
      maxSize: MAX_FILE_SIZE_BYTES,
      onDrop,
      noClick: isDisabled,
      noDrag: isDisabled,
      noKeyboard: isDisabled,
    })
    useImperativeHandle(ref, () => ({
      open() {
        open()
      },
    }))

    return (
      <div
        className={cx({
          'cursor-pointer': !isDisabled,
          'cursor-not-allowed': isDisabled,
        })}
        {...getRootProps({})}
      >
        <input {...getInputProps()} />
        <Button variant="primary" loading={isLoading}>
          Create record
        </Button>
      </div>
    )
  }
)

export default CsvUploader
