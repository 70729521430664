import { StylesConfig } from 'react-select'

import { MultiSelectValue } from '../MultiSelect'

export const customStyles = (): StylesConfig<MultiSelectValue> => {
  return {
    menu: (defaultStyles) => {
      return {
        ...defaultStyles,
        minWidth: '450px',
      }
    },
  }
}
