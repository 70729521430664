import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { getCepTemplate } from '../api'
import { cepIntegrationsKeys } from '../queryKeys'

const useGetCepTemplateQuery = (
  accountId: string,
  options?: UseQueryOptions
) => {
  const getCepTemplateQuery = useQuery(
    cepIntegrationsKeys.listCepTemplates(),
    () => getCepTemplate(accountId),
    { enabled: options?.enabled }
  )
  return getCepTemplateQuery
}

export default useGetCepTemplateQuery
