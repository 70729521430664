import { Field, useFormState } from 'react-final-form'

import { SelectValue } from 'common/components/BaseSelect'
import CodeBlock from 'common/components/codeBlock'
import FormItem from 'common/components/formItem'
import { MergeTagListItem } from 'features/admin/accountSettings/api'

const validate = (value: string) => {
  if (!value) {
    return 'Required'
  }
  return undefined
}

type Props = {
  selectedCategory: SelectValue
  systemMergeTags?: MergeTagListItem[]
}

const CodeBlockField = ({ systemMergeTags, selectedCategory }: Props) => {
  const { values } = useFormState()

  const code = systemMergeTags?.find(
    (tag) => tag.id === selectedCategory?.value
  )?.code

  const initialValue = values['merge_tag_code'] ?? code ?? ''

  return (
    <Field<string>
      name="merge_tag_code"
      initialValue={initialValue}
      validate={validate}
    >
      {({ input, meta }) => (
        <FormItem
          htmlFor={input.name}
          className="flex-none"
          error={meta.touched ? meta.error : undefined}
        >
          <CodeBlock maxLength={100} rows={4} {...input} />
        </FormItem>
      )}
    </Field>
  )
}

export default CodeBlockField
