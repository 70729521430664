import { getDataSourceApiPerRegion } from 'common/api'

export type CustomerAttributesResponse = {
  name: string
  id: string
  description: string
}

export const updateCustomerAttributes = async ({
  accountId,
  attributeId,
  catalogueAttributeId,
  region,
  description,
}: {
  accountId: string
  region: string
  catalogueAttributeId: string
  attributeId: string
  description: string
}) => {
  const result = await getDataSourceApiPerRegion(region).patch<{
    items: ProfileAttributeItemResponse[]
  }>(
    `accounts/${accountId}/profile-attributes-catalogues/${catalogueAttributeId}/attributes/${attributeId}`,
    { description }
  )
  return result.data.items
}

export type ProductCatalogCategoriesResponse = {
  product_categories: Object
}

export const getProductCatalogCategories = async ({
  accountId,
  catalogId,
  region,
}: {
  accountId: string
  catalogId: string
  region: string
}): Promise<ProductCatalogCategoriesResponse | null> => {
  const api = getDataSourceApiPerRegion(region)

  const result = await api.get<ProductCatalogCategoriesResponse | null>(
    `accounts/${accountId}/product-catalogues/${catalogId}/categories`
  )

  return result.data
}

export type ProductCatalogSettingsResponse = {
  id: string
  name: string
  account_id: string
  created_at: string
  last_updated: string
  mapping: {
    import_type: string
    fields: {
      destination_field_name: string
      required: boolean
      source_field_name: string
      product_category_level?: number
      product_category_delimiter?: ';' | '>'
      category_description_level?: number
    }[]
  }
}

export const getProductCatalogSettings = async ({
  accountId,
  productCatalogId,
  region,
}: {
  accountId: string
  productCatalogId: string
  region: string
}): Promise<ProductCatalogSettingsResponse> => {
  const api = getDataSourceApiPerRegion(region)
  const result = await api.get<ProductCatalogSettingsResponse>(
    `accounts/${accountId}/product-catalogues/${productCatalogId}`
  )
  return result.data
}

type ProfileAttributesCatalogueResponse = {
  items: {
    account_id: string
    created_at: string
    description: string
    id: string
    name: string
    updated_at: null
  }[]
}

export interface ProfileAttribute {
  id: string
  name: string
  region: string
  updated: string
}

export const getCustomerAttributeRecords = async ({
  accountId,
  privacyRegions,
}: {
  accountId: string
  privacyRegions: string[]
}): Promise<ProfileAttribute[]> => {
  if (!privacyRegions || privacyRegions.length === 0) {
    throw new Error(
      "We're missing your region info for data privacy. Please contact support to resolve this. Thank you!"
    )
  }

  const results = await Promise.all(
    privacyRegions.map((region) =>
      getDataSourceApiPerRegion(region)
        .get<ProfileAttributesCatalogueResponse>(
          `accounts/${accountId}/profile-attributes-catalogues`
        )
        .then((response) => ({
          region,
          items: response.data.items,
        }))
    )
  )
  return results
    .map(({ region, items }) =>
      items.map(({ id, name, updated_at, created_at }) => ({
        id,
        name,
        region,
        updated: updated_at ?? created_at,
      }))
    )
    .flat()
}

export interface ProfileAttributeItemResponse {
  id: string
  profile_attribute_catalogue_id: string
  name: string
  description: string
  created_at: string
  updated_at: string
}

export const getCustomerAttributes = async ({
  accountId,
  catalogueAttributeId,
  region,
}: {
  accountId: string
  catalogueAttributeId: string
  region: string
}) => {
  const result = await getDataSourceApiPerRegion(region).get<{
    items: ProfileAttributeItemResponse[]
  }>(
    `accounts/${accountId}/profile-attributes-catalogues/${catalogueAttributeId}/attributes`
  )
  return result.data.items.map((item) => ({
    id: item.id,
    name: item.name,
    description: item.description,
  }))
}

export const createProfileAttributesCatalogue = async ({
  accountId,
  region,
  name,
}: {
  accountId: string
  region: string
  name: string
}): Promise<{ id: string; name: string; description: string }> => {
  const result = await getDataSourceApiPerRegion(region).post<{
    id: string
    name: string
    description: string
  }>(`accounts/${accountId}/profile-attributes-catalogues`, { name })
  return result.data
}
