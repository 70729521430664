import { useState } from 'react'
import cx from 'classnames'
import Dropdown from 'rc-dropdown'
import Menu, { Item as MenuItem } from 'rc-menu'

import Button from 'common/components/button/Button'
import ConfirmationModal from 'common/components/confirmationModal/ConfirmationModal'
import { Bin, Edit, MoreOptionsVertical } from 'common/icons'

import useDeleteMergeTagMutation from '../../../api/mutations/useDeleteMergeTagMutation'

type Props = {
  tagId: string
  tagName: string
  onEdit: () => void
}

const MergeTagsActions = ({ tagId, tagName, onEdit }: Props) => {
  const [showDeleteTagModal, setShowDeleteTagModal] = useState<boolean>(false)
  const { mutate, isLoading } = useDeleteMergeTagMutation({
    tagId,
    tagName,
    onSuccess: () => {
      setShowDeleteTagModal(false)
    },
  })

  return (
    <>
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu
            className="p-2 z-40 bg-gold-40 border-base-700"
            selectable={false}
          >
            <MenuItem
              onClick={() => {
                if (!isLoading) {
                  onEdit()
                }
              }}
              aria-label="Edit tag"
              disabled={isLoading}
              className="flex hover:bg-gold-300 text-xs text-base-700 cursor-pointer"
            >
              <Edit
                isDefaultColor={false}
                size={4}
                className="mr-4 text-base-700"
              />
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (!isLoading) {
                  setShowDeleteTagModal(true)
                }
              }}
              aria-label="Delete tag"
              disabled={isLoading}
              className={cx(
                {
                  'cursor-not-allowed': isLoading,
                  'cursor-pointer': !isLoading,
                },
                'flex hover:bg-gold-300 text-xs text-base-700'
              )}
            >
              <Bin
                isDefaultColor={false}
                size={4}
                className="mr-4 text-base-700"
              />
              Delete
            </MenuItem>
          </Menu>
        }
      >
        <Button
          variant="icon"
          data-testid="Tags actions"
          suffixIcon={
            <MoreOptionsVertical
              isDefaultColor={false}
              className="text-base-700"
            />
          }
        ></Button>
      </Dropdown>
      <ConfirmationModal
        data-testid="remove-tag-modal"
        title="Delete merge tag"
        confirmationText={`Are you sure you want to delete the ${tagName} merge tag? Existing content won't be updated, you will need to update existing content manually.`}
        confirmButtonText="YES, DELETE"
        cancelButtonText="CANCEL"
        open={showDeleteTagModal}
        hasConfirmationSlider={true}
        onConfirm={() => mutate()}
        onCancel={() => setShowDeleteTagModal(false)}
        isLoading={isLoading}
      />
    </>
  )
}

export default MergeTagsActions
