import {
  reportsDashboardPath,
  reportsDataSearchPath,
  reportsSpotterAiPath,
} from 'app/navigation/paths'

export interface Page {
  name: string
  path: string
}
export const PATH: Record<string, string> = {
  Dashboard: reportsDashboardPath,
  DataSearch: reportsDataSearchPath,
  SpotterAI: reportsSpotterAiPath,
}

export const PAGES: Page[] = [
  {
    name: 'Live Dashboard',
    path: PATH.Dashboard,
  },
  {
    name: 'Data Search',
    path: PATH.DataSearch,
  },
  {
    name: 'Spotter AI',
    path: PATH.SpotterAI,
  },
]
