import { useQuery } from '@tanstack/react-query'

import { useContent } from 'features/unifiedFlow/contentPage/hooks'
import { IntegrationRouting } from 'features/unifiedFlow/contentPage/integrations/centerSection/api/api'

import { getIntegrationsRoutingConfig } from '../api'
import { integrationKeys } from '../queryKeys'

const useGetIntegrationsRoutingConfigQuery = <T = IntegrationRouting[]>({
  select,
  enabled = true,
}: {
  select?: (data: IntegrationRouting[]) => T
  enabled?: boolean
} = {}) => {
  const { content } = useContent()

  const contentId = content?._id

  const getIntegrationsRoutingConfigQuery = useQuery<
    IntegrationRouting[],
    unknown,
    T
  >(
    integrationKeys.routingConfig(contentId!),
    () => getIntegrationsRoutingConfig({ contentId: contentId! }),
    {
      enabled: !!content?._id && enabled,
      select: select,
    }
  )

  return getIntegrationsRoutingConfigQuery
}

export default useGetIntegrationsRoutingConfigQuery
