import React from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import TextLoader from 'common/components/loaders/TextLoader'
import MetricItem from 'common/components/MetricItem'
import Widget, { WidgetProps } from 'common/components/Widget'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import {
  Calendar as CalendarIcon,
  CheckCampaign as CheckCampaignIcon,
  Complete as CompleteIcon,
  DocumentAwaiting as DocumentAwaitingIcon,
} from 'common/icons'
import { ArrowRight as ArrowRightIcon } from 'common/icons'
import { clickCreateCampaign } from 'features/campaigns/store/campaignSlice'
import useGetCampaignMetricsQuery from 'features/homePage/api/queries/useGetCampaignMetricsQuery'
import useGetContentLibraryQuery from 'features/homePage/hooks/useGetContentLibraryQuery'

interface Props extends WidgetProps {
  title?: React.ReactChild | React.ReactFragment
}

const MetricsCard = ({ title, ...rest }: Props) => {
  const flags = useFlags()
  const isUnifiedFlowVisible = flags.showUnifiedFlow
  const hasSwitchedToContentLibrary = flags.switchToContentLibrary
  const campaignMetricsQuery = useGetCampaignMetricsQuery({
    isEnabled: !hasSwitchedToContentLibrary,
  })
  const contentLibraryQuery = useGetContentLibraryQuery({
    isEnabled: hasSwitchedToContentLibrary,
  })
  const { data, status } = hasSwitchedToContentLibrary
    ? contentLibraryQuery
    : campaignMetricsQuery
  const { accountId } = useAppSelector((state) => state.authStates)
  const dispatch = useAppDispatch()
  const history = useHistory()

  const hasNoExperimentYet =
    data?.live === 0 && data?.needApproval === 0 && data?.completed === 0

  return (
    <Widget
      {...rest}
      defaultPadding={false}
      className="border-none"
      bgClassName="bg-transparent"
      data-testid="metrics-card"
    >
      {
        {
          loading: (
            <div
              data-testid="loader"
              data-cy="list-widget-loader"
              className="px-6 py-4"
            >
              <TextLoader />
            </div>
          ),
          error: <div className="px-4 py-2">Loading Metrics failed</div>,
          success: (
            <>
              <div className="flex justify-between items-center mb-4">
                <p className="text-base-700 font-normal text-lg">{title}</p>
                {hasNoExperimentYet && (
                  <button
                    className="group ml-autoflex items-center text-gold-600 uppercase underline hover:text-maroon-200"
                    onClick={() => {
                      history.push(generatePath('/campaigns', { accountId }))
                      dispatch(clickCreateCampaign())
                    }}
                  >
                    {isUnifiedFlowVisible
                      ? 'Create new content'
                      : 'Create an experiment'}
                    <ArrowRightIcon
                      className="ml-2 text-gold-600 group-hover:text-maroon-400"
                      isDefaultColor={false}
                      size={4}
                    />
                  </button>
                )}
              </div>
              {hasNoExperimentYet && (
                <div
                  className="box-border text-sm p-4 bg-gray-50 border-solid border border-gray-300 text-center mb-4"
                  data-testid="no-data-text"
                >
                  {hasSwitchedToContentLibrary
                    ? 'No content yet! Run your first one.'
                    : 'No experiments yet! Run your first experiment now.'}
                </div>
              )}
              <div className="grid grid-cols-2 gap-4">
                <MetricItem
                  icon={<CompleteIcon isDefaultColor={false} />}
                  text={`${data?.live ?? 0} Live`}
                />
                {!hasSwitchedToContentLibrary && (
                  <MetricItem
                    icon={<CalendarIcon isDefaultColor={false} />}
                    text={`${data?.['scheduled'] ?? 0} Scheduled`}
                  />
                )}
                <MetricItem
                  icon={<DocumentAwaitingIcon isDefaultColor={false} />}
                  text={`${data?.needApproval ?? 0} Awaiting approval`}
                />
                <MetricItem
                  icon={<CheckCampaignIcon isDefaultColor={false} />}
                  text={`${data?.completed ?? 0} Completed`}
                />
              </div>
            </>
          ),
        }[status]
      }
    </Widget>
  )
}

export default MetricsCard
