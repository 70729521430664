import { useReducer, useRef, useState } from 'react'
import classNames from 'classnames'

import { getRegionalizedIngestionUrl } from 'common/api'
import ConfirmationModal from 'common/components/confirmationModal'
import EmptyState from 'common/components/emptyState'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import { errorToast, infoToast } from 'common/components/toastNotification'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import {
  fileNameConfirmed,
  fileProcessError,
  productUploadCompleted,
  productUploadProgress,
  productUploadStart,
} from '../store/dataSourceSlice'
import { Uploader } from '../upload'

import CsvProductPreview from './CsvProductPreview'
import { csvProductInitialState, csvProductReducer } from './csvProductReducer'
import ProductCatalogTable from './ProductCatalogTable'
import SourceUploader from './SourceUploader'

type Props = {
  productCatalogs: {
    name: string
    id: string
    createdAt: string
    lastUpdated: string
    region: string
  }[]
}

const ProductCatalog = ({ productCatalogs }: Props) => {
  const csvUploaderProductRef = useRef<{ open: () => void }>(null)
  const fileRef = useRef<File | undefined>(undefined)
  const uploaderRef = useRef<Uploader | undefined>(undefined)
  const dispatch = useAppDispatch()
  const [csvProductState, csvProductDispatch] = useReducer(
    csvProductReducer,
    csvProductInitialState
  )
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const progressInPercentage = useAppSelector(
    (state) => state.dataSource.fileStates.product.progressPercentage
  )
  const isProcessing = useAppSelector(
    (state) => state.dataSource.fileStates.product.isProcessing
  )

  const [hasHeaderColumn, setHasHeaderColumn] = useState(true)
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false)

  const [selectedProductCatalogId, setSelectedProductCatalogId] = useState<
    string | undefined
  >(productCatalogs[0]?.id)

  return (
    <div className="flex flex-col gap-12">
      <PageHeader>
        <div className="flex justify-between flex-1">
          <div className="pt-9">
            <PageTitle title="Product catalogue" />
            <div className="text-coolGray-400">
              Configure the below settings to let Jacquard know what we should
              or should not use in the generation of your content.
            </div>
          </div>
        </div>
      </PageHeader>
      {productCatalogs.length > 0 &&
        selectedProductCatalogId &&
        isProcessing === false &&
        progressInPercentage === undefined && (
          <ProductCatalogTable
            catalogId={selectedProductCatalogId}
            productCatalogs={productCatalogs}
            onCatalogChange={setSelectedProductCatalogId}
            onUploadClick={() => csvUploaderProductRef.current?.open()}
          />
        )}
      {progressInPercentage !== undefined && (
        <EmptyState
          title="Uploading your file"
          description={`Uploading is ${progressInPercentage}% complete`}
        />
      )}
      {isProcessing && (
        <EmptyState
          title="The file is being processed"
          description="This may take a few minutes. You will be notified when it is completed."
        />
      )}
      <div
        className={classNames({
          hidden:
            productCatalogs.length > 0 || progressInPercentage !== undefined,
        })}
      >
        <EmptyState
          title="Start creating your first record"
          description="We don't have any records stored."
          action={
            <SourceUploader
              ref={csvUploaderProductRef}
              fileName={undefined}
              progressInPercentage={undefined}
              hasHeaderColumn={hasHeaderColumn}
              onStartUpload={(file) => {
                fileRef.current = file
              }}
              onUpload={({ data, headers, fileName, numberOfRows }) => {
                csvProductDispatch({
                  type: 'upload',
                  data,
                  headers,
                  fileName,
                  numberOfRows,
                })
                dispatch(
                  productUploadStart({
                    fileName,
                    data,
                  })
                )
              }}
            />
          }
        />
      </div>

      {csvProductState.isPreviewOpen && fileRef.current && (
        <CsvProductPreview
          isOpen={csvProductState.isPreviewOpen}
          fileName={csvProductState.fileName}
          fileSize={fileRef.current.size}
          hasHeaderColumn={hasHeaderColumn}
          onChangeHeaderColumn={setHasHeaderColumn}
          numberOfRows={csvProductState.numberOfRows}
          data={csvProductState.data ?? []}
          onCancel={() => {
            setIsConfirmationVisible(true)
          }}
          onImport={(values) => {
            infoToast(
              'The upload has started. You will be notified when it is completed. Meanwhile, you can carry on with your work.'
            )

            csvProductDispatch({
              type: 'clickImport',
            })
            dispatch(
              fileNameConfirmed({
                fileName: values.productCatalogName,
              })
            )
            const file = fileRef.current

            if (file && values.privacyRegion) {
              const dataIngestionBaseUrl = getRegionalizedIngestionUrl(
                values.privacyRegion
              )
              uploaderRef.current = new Uploader({
                api: dataIngestionBaseUrl,
                fileName: values.productCatalogName,
                file: file,
                accountId,
              })
                .onProgress(({ percentage: newPercentage }) => {
                  if (newPercentage === 100) {
                    csvProductDispatch({ type: 'uploadCompleted' })
                    dispatch(productUploadCompleted())
                  } else if (newPercentage !== 100) {
                    csvProductDispatch({
                      type: 'uploadProgress',
                      percentage: newPercentage,
                    })
                    dispatch(
                      productUploadProgress({
                        percentage: newPercentage,
                      })
                    )
                  }
                })
                .onError(() => {
                  csvProductDispatch({ type: 'errorUpload' })
                  dispatch(fileProcessError())
                  errorToast(
                    'Something went wrong while uploading the file. Please try again or contact support.'
                  )
                })
              uploaderRef.current.start({
                data: {
                  useDataSources: true,
                  productCatalogueName: values.productCatalogName,
                  productCatalogueId: values.productCatalogId,

                  productIdColumnName: values.productIdColumn,
                  productNameColumnName: values.productNameColumn,
                  productDescriptionColumnName: values.descriptionColumn,

                  separatedBy: values.dataSeparator,
                  categoryDelimiter: values.delimiter,

                  ...(values.dataSeparator === 'delimiter' && {
                    categoryColumnName: values.categoryColumn,
                    categoryDescriptionColumnName:
                      values.categoryColumnDescription,
                  }),

                  ...(values.dataSeparator === 'columns' && {
                    ...removeEmptyDataFromArray(
                      values.categoryLevel,
                      values.categoryLevelDescription
                    ),
                  }),

                  columnsToExclude: values.columnsToExclude,
                },
              })
            }
          }}
          productCatalogs={productCatalogs}
        />
      )}
      <ConfirmationModal
        open={isConfirmationVisible}
        title="Cancel preview"
        confirmationText="Are you sure you want to cancel the preview?"
        onCancel={() => setIsConfirmationVisible(false)}
        onConfirm={() => {
          csvProductDispatch({ type: 'cancelPreview' })
          setIsConfirmationVisible(false)
        }}
        confirmButtonText="Yes, cancel"
        cancelButtonText="No, keep previewing"
      />
    </div>
  )
}

export default ProductCatalog

function removeEmptyDataFromArray(
  categoryLevel: string[],
  categoryLevelDescription: string[]
) {
  return {
    categoryLevelsColumnNames: categoryLevel.filter(
      (item, index) => item !== '' || categoryLevelDescription[index] !== ''
    ),
    categoryDescriptionLevelsColumnNames: categoryLevelDescription.filter(
      (item, index) => item !== '' || categoryLevel[index] !== ''
    ),
  }
}
