import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorToast, successToast } from 'common/components/toastNotification'
import useContent from 'common/hooks/useContent'
import useVariantsToApproveQuery from 'features/unifiedFlow/contentPage/hooks/useVariantsToApproveQuery'

import {
  PersonalizationRegion,
  ResultsResponse,
  updateVariantStatus,
} from '../api'
import { personalizationResultsKeys } from '../queryKeys'

const useUpdateVariantStatusMutation = ({
  accountId,
  personalizationId,
  personalizationRegion,
  resultType,
}: {
  accountId: string
  personalizationId: string | undefined
  personalizationRegion: PersonalizationRegion | undefined
  resultType: 'mature' | 'raw'
}) => {
  const queryClient = useQueryClient()
  const { invalidateAll: invalidateAllVariantsToApproveCountQuery } =
    useVariantsToApproveQuery()

  const { refetch: refetchContent } = useContent()

  const variantsResultsQueryKey = personalizationResultsKeys.variants({
    accountId,
    personalizationId,
    personalizationRegion,
    resultType,
  })

  return useMutation(updateVariantStatus, {
    onMutate: (variables) => {
      queryClient.cancelQueries(variantsResultsQueryKey)

      queryClient.setQueryData<ResultsResponse>(
        variantsResultsQueryKey,
        (old) => {
          if (!old) {
            return old
          }

          return {
            ...old,
            items: old.items.map((variant) =>
              variant.variantId === variables.variantId
                ? { ...variant, variantStatus: variables.status }
                : variant
            ),
          }
        }
      )
    },
    onSuccess: () => {
      invalidateAllVariantsToApproveCountQuery()
      refetchContent()
      successToast('Variant status updated successfully.')
    },
    onSettled: () => {
      queryClient.invalidateQueries(variantsResultsQueryKey)
    },
    onError: (error) => {
      const errorMessage =
        error instanceof Error
          ? error.message
          : 'Something went wrong while updating the variant status'
      errorToast(errorMessage)
    },
  })
}

export default useUpdateVariantStatusMutation
