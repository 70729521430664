import { connectInternalApi, getDataSourceApiPerRegion } from './api'

export const fetchAccountPrivacyRegions = async (accountId: string) => {
  const response = await connectInternalApi.get<{ data: Region[] }>(
    `v1/core/monorail/accounts/${accountId}/privacy-regions`
  )
  return response.data.data
}

export type Region = 'EU' | 'US' | 'UK'

type ProductCatalogsResponse = {
  id: string
  name: string
  created_at: string
  last_updated: string
}[]

export const getProductCatalogs = async ({
  accountId,
  region,
}: {
  accountId: string
  region: Region | undefined
}) => {
  if (!region) {
    throw new Error('Region is not defined')
  }
  if (!accountId) {
    throw new Error('Account id id not defined for this content')
  }
  const response = await getDataSourceApiPerRegion(
    region
  ).get<ProductCatalogsResponse>(`/accounts/${accountId}/product-catalogues`)
  return response.data
}
