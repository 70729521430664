import Button from 'common/components/button'
import { Add } from 'common/icons'

type Props = {
  onAddClick: () => void
}

const EmptyState = ({ onAddClick }: Props) => {
  return (
    <div className="flex flex-col justify-center items-center h-full">
      <h2 className="text-xl font-medium mb-4">Content Integrations</h2>
      <p className="text-base text-coolGray-400 font-medium mb-12">
        Get started on adding available integrations for content generation
      </p>
      <Button
        className="mb-8"
        variant="primary"
        prefixIcon={<Add isDefaultColor={false} />}
        onClick={onAddClick}
      >
        Integration
      </Button>
    </div>
  )
}

export default EmptyState
