import { ReactNode } from 'react'

import RadialProgress from 'common/components/RadialProgress'

interface Props {
  value: number
  children: ReactNode
}

const MilestoneItem = ({ value, children }: Props) => (
  <div className="flex items-center gap-3 min-h-9">
    <RadialProgress value={value} size="sm" className="flex-shrink-0" />
    <span className="text-xs text-base-700 flex gap-2.5">{children}</span>
  </div>
)

export default MilestoneItem
