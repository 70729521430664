import React from 'react'
import cx from 'classnames'

import Widget from 'common/components/Widget'

interface Props {
  icon: React.ReactNode
  text: string
  hasNotAccess?: boolean
  onClick: () => void
}

const QuickCard = ({ icon, text, hasNotAccess = false, onClick }: Props) => {
  return (
    <Widget
      type="table"
      defaultPadding={false}
      className={cx(
        'rounded-sm border-gold-500 border-t-0 border-b-0 border-r-0 border-l-2 flex-1'
      )}
      data-testid="quick-card"
    >
      <button
        onClick={onClick}
        className="flex group place-items-center bg-gold-100 hover:bg-gold-300 h-full relative px-6 py-5"
      >
        {hasNotAccess && (
          <div
            className="text-coolGray-50 font-bold text-xs py-0.5 px-1 bg-coolGray-500 absolute -top-2 left-4"
            data-testid="quick-card-upgrade"
          >
            Upgrade
          </div>
        )}
        <div className="text-base-700">{icon}</div>
        <div className="text-base-700 font-normal ml-4 text-base">{text}</div>
      </button>
    </Widget>
  )
}

export default QuickCard
