import { useCallback, useMemo, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import type { IntegrationProvider } from 'common/api/integrationsApi'
import { getIntegrationName } from 'common/cepUtils'
import AnimatedOverlay from 'common/components/animatedOverlay'
import ErrorPage from 'common/components/error/ErrorPage'
import Footer from 'common/components/layout/Footer'
import Loader from 'common/components/loaders/Loader'
import { useAppSelector } from 'common/hooks/redux'
import useGetAvailableIntegrationsQuery from 'features/admin/accountSettings/api/queries/useGetAvailableIntegrationsQuery'
import useGetCepTemplateQuery from 'features/admin/accountSettings/api/queries/useGetCepTemplateQuery'

import { CepTemplate } from '../../api'

import IntegrationsSearchbox from './IntegrationSearchbox'
import IntegrationsList from './IntegrationsList'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const SelectIntegrationOverlay = ({ isOpen, onClose }: Props) => {
  const flags = useFlags()
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const isSimplifiedProjectIntegrationOn = flags?.simplifiedProjectIntegrations

  const { data: integrationData, status: integrationStatus } =
    useGetAvailableIntegrationsQuery({
      enabled: !isSimplifiedProjectIntegrationOn,
    })
  const { data: cepTemplates, status: cepStatus } = useGetCepTemplateQuery(
    accountId,
    { enabled: isSimplifiedProjectIntegrationOn }
  )

  const data = isSimplifiedProjectIntegrationOn ? cepTemplates : integrationData
  const status = isSimplifiedProjectIntegrationOn
    ? cepStatus
    : integrationStatus
  const [searchValue, setSearchValue] = useState<string>('')

  const filterByName = useCallback(
    (item: IntegrationProvider | CepTemplate) => {
      const name = isSimplifiedProjectIntegrationOn
        ? getIntegrationName((item as CepTemplate).cep_name)
        : (item as IntegrationProvider).name
      return name
        .replaceAll(' ', '')
        .toLowerCase()
        .includes(searchValue.replaceAll(' ', '').toLowerCase())
    },
    [searchValue, isSimplifiedProjectIntegrationOn]
  )

  const filteredIntegrations = useMemo(() => {
    return (data ?? []).filter(filterByName)
  }, [data, filterByName])

  const getOptions = useMemo(() => {
    if (!data) {
      return []
    }
    return isSimplifiedProjectIntegrationOn
      ? (data as CepTemplate[]).map((template) => ({
          value: template._id,
          label: getIntegrationName(template.cep_name),
        }))
      : (data as IntegrationProvider[]).map((template) => ({
          value: template.id,
          label: template.name,
        }))
  }, [data, isSimplifiedProjectIntegrationOn])

  return (
    <AnimatedOverlay
      title="Integrations"
      className="overflow-y-auto"
      onClose={onClose}
      isVisible={isOpen}
    >
      {
        {
          success: (
            <div className="w-full h-full">
              <div className="flex flex-col px-6 bg-gold-40">
                <div className="relative flex flex-col mb-8 mt-12">
                  <div className="flex justify-end mb-8">
                    <IntegrationsSearchbox
                      placeholder="Find integration"
                      data-cy="integrations-search-box"
                      data-testid="integrations-search-box"
                      onSearch={setSearchValue}
                      options={getOptions}
                    />
                  </div>
                  <div>
                    {filteredIntegrations?.length === 0 &&
                    searchValue !== '' ? (
                      <div className="text-5xl font-bold text-coolGray-800 leading-normal">
                        There are no integrations matching your search, please
                        try again
                      </div>
                    ) : (
                      <IntegrationsList
                        filteredIntegrations={
                          filteredIntegrations as
                            | CepTemplate[]
                            | IntegrationProvider[]
                        }
                        onSuccess={onClose}
                      />
                    )}
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          ),
          loading: <Loader />,
          error: <ErrorPage />,
        }[status]
      }
    </AnimatedOverlay>
  )
}

export default SelectIntegrationOverlay
