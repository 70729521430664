import { routesPermissions } from 'common/auth/permissions'
import {
  Account,
  Accounts as AccountsIcon,
  Campaigns as CampaignsIcon,
  Dashboard as DashboardIcon,
  Edit as EditIcon,
  Insights,
  Performance,
  Profile as ProfileIcon,
  Reports as ReportsIcon,
} from 'common/icons'
import Accounts from 'features/accounts'
import Admin from 'features/admin'
import PersonalizedContentSwitch from 'features/admin/personalizedContent/PersonalizedContentSwitch'
import AdminRegionSettings from 'features/admin/regions/RegionSettings'
import Campaigns from 'features/campaigns/Campaigns'
import AdminNodes from 'features/content/nodes'
import Dashboard from 'features/dashboard/Dashboard'
import DemoPage from 'features/demo'
import FuturamaContent from 'features/futurama'
import HomePage from 'features/homePage'
import Personalization from 'features/personalization'
import Profile from 'features/profile/Profile'
import Projects from 'features/projects'
import ReportsDashboard from 'features/reports/dashboard'
import ReportsInsights from 'features/reports/insights'
import ReportsPerformance from 'features/reports/performance'
import Reports from 'features/reports/Reports'

import { StaticRoute, TabbedNavRoute } from './interfaces'
import { dispatchClickNavItem } from './navigationSlice'
import {
  accountsPath,
  adminAccountPath,
  adminPath,
  campaignsPath,
  contentPath,
  createProjectPath,
  dashboardPath,
  demoPath,
  homePath,
  nodesPath,
  personalizedContentPath,
  profilePath,
  projectContentPath,
  projectNodesPath,
  projectSettingsPath,
  projectsPath,
  regionSettingsPath,
  reportsDashboardPath,
  reportsInsightsPath,
  reportsPath,
  reportsPerformancePath,
  reportsPersonalizationPath,
  selectedCampaignPath,
} from './paths'
import { adminSubRoute } from './staticSubRoutes.admin'
import { futuramaContentsPageSubRoute as futuramaContentSubRoutes } from './staticSubRoutes.futurama'

export const regionSettingsRoute: TabbedNavRoute = {
  key: 'region',
  name: 'Region settings',
  to: regionSettingsPath,
  component: AdminRegionSettings,
  accessPermission: routesPermissions.contentView,
}

export const nodesRoute: TabbedNavRoute = {
  key: 'nodes',
  name: 'Nodes',
  to: nodesPath,
  component: AdminNodes,
}

export const personalizedContentRoute: TabbedNavRoute = {
  key: 'personalizedContent',
  name: 'Personalised Content',
  to: personalizedContentPath,
  component: PersonalizedContentSwitch,
  accessFlags: { flags: ['showLanguagePersonalizedContent'], type: 'hide' },
  accessPermission: routesPermissions.personalizedContentView,
}

export const languageSubroutes: TabbedNavRoute[] = [
  regionSettingsRoute,
  nodesRoute,
  personalizedContentRoute,
]

export const projectsRoute: StaticRoute = {
  key: 'projects',
  component: Projects,
  path: [
    projectsPath,
    createProjectPath,
    projectSettingsPath,
    projectContentPath,
    projectNodesPath,
  ],
  name: 'projects',
  to: projectsPath,
  icon: CampaignsIcon,
  accessPermission: routesPermissions.projectsPageView,
  shouldValidateAccountAccess: true,
  accessFlags: {
    flags: ['projectScreens'],
    type: 'hide',
  },
}

export const campaignsRoute: StaticRoute = {
  key: 'campaigns',
  component: Campaigns,
  path: [campaignsPath, selectedCampaignPath],
  name: 'experiments',
  to: campaignsPath,
  icon: CampaignsIcon,
  accessLevel: 'hero',
  denyFlags: {
    flags: ['switchToContentLibrary'],
    type: 'hide',
  },
  accessPermission: routesPermissions.experimentsView,
  shouldValidateAccountAccess: true,
}

export const reportsRoute: StaticRoute = {
  key: 'reports',
  component: Reports,
  path: ['/reports', reportsPath],
  name: 'reports',
  to: reportsPath,
  icon: ReportsIcon,
  accessLevel: 'hero',
  accessPermission: routesPermissions.reportsView,
  shouldValidateAccountAccess: true,
  subroutes: [
    {
      icon: ReportsIcon,
      key: 'dashboard',
      name: 'Dashboard',
      component: ReportsDashboard,
      to: reportsDashboardPath,
      onClick: dispatchClickNavItem,
      accessFlags: {
        flags: ['showReportingV1'],
        type: 'hide',
      },
    },
    {
      icon: Performance,
      key: 'performance',
      name: 'Performance',
      component: ReportsPerformance,
      to: reportsPerformancePath,
      onClick: dispatchClickNavItem,
    },
    {
      icon: Insights,
      key: 'insights',
      name: 'Insights',
      component: ReportsInsights,
      to: reportsInsightsPath,
      onClick: dispatchClickNavItem,
    },
    {
      key: 'personalization',
      component: Personalization,
      icon: EditIcon,
      name: 'Personalised Campaigns',
      to: reportsPersonalizationPath,
      onClick: dispatchClickNavItem,
      accessFlags: {
        flags: ['showPersonalizationDataImport'],
        type: 'hide',
      },
    },
  ],
}

export const accountsRoute: StaticRoute = {
  key: 'accounts',
  path: accountsPath,
  isExact: true,
  component: Accounts,
  name: 'accounts',
  to: accountsPath,
  icon: AccountsIcon,
  accessLevel: 'hero',
  accessPermission: routesPermissions.accountsView,
  accessFlags: {
    flags: ['showAccounts'],
    type: 'hide',
  },
}

export const dashboardRoute: StaticRoute = {
  key: 'dashboard',
  path: ['/', dashboardPath],
  isExact: true,
  component: Dashboard,
  name: 'home',
  to: '/dashboard',
  onClick: dispatchClickNavItem,
  icon: DashboardIcon,
  accessLevel: 'hero',
}

export const homeRoute: StaticRoute = {
  key: 'home',
  path: ['/', homePath],
  isExact: true,
  component: HomePage,
  name: 'home',
  to: homePath,
  onClick: dispatchClickNavItem,
  icon: DashboardIcon,
  accessLevel: 'hero',
}

export const profileRoute: StaticRoute = {
  key: 'profile',
  path: profilePath,
  isExact: true,
  component: Profile,
  name: 'profile',
  to: profilePath,
  icon: ProfileIcon,
  accessLevel: 'hero',
}

export const adminRoute: StaticRoute = {
  key: 'admin',
  path: adminPath,
  component: Admin,
  name: 'admin',
  to: adminAccountPath,
  icon: Account,
  accessLevel: 'superhero',
  subroutes: adminSubRoute,
  shouldValidateAccountAccess: false,
}

export const futuramaContentRoute: StaticRoute = {
  key: 'content',
  path: contentPath,
  component: FuturamaContent,
  icon: EditIcon,
  name: 'content',
  to: '/content',
  accessLevel: 'superhero',
  denyFlags: {
    flags: ['switchToContentLibrary', 'showUnifiedFlow'],
    type: 'hide',
  },
  subroutes: futuramaContentSubRoutes,
}

export const demoRoute: StaticRoute = {
  key: 'demo',
  path: demoPath,
  component: DemoPage,
  icon: EditIcon,
  name: 'demo',
  to: demoPath,
  accessLevel: 'superhero',
  accessPermission: routesPermissions.demoPageView,
}

const staticRoutes: StaticRoute[] = [
  dashboardRoute,
  campaignsRoute,
  accountsRoute,
  reportsRoute,
  adminRoute,
  profileRoute,
  projectsRoute,
  futuramaContentRoute,
  demoRoute,
]

export const allPaths = [
  selectedCampaignPath,
  ...staticRoutes
    .map(({ to, subroutes }) => {
      if (subroutes) {
        return subroutes.map(({ to, tabbedNavRoutes }) => {
          if (tabbedNavRoutes) {
            return tabbedNavRoutes.map(({ to }) => to)
          } else {
            return to
          }
        })
      } else {
        return to
      }
    })
    .flat(2),
]

export { staticRoutes }
