import { useMemo, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { unifiedFlowPaths } from 'app/navigation/paths'
import BannerContainer from 'workflow/common/bannerContainer/BannerContainer'
import MachineOpensSummary from 'workflow/common/machineOpensSummary'
import NewVariantsBanner from 'workflow/common/newVariantsBanner/NewVariantsBanner'
import RawDataTabBanner from 'workflow/common/rawDataTabBanner'

import Autocomplete from 'common/components/autocomplete'
import EmptyState from 'common/components/emptyState'
import ErrorPage from 'common/components/error/ErrorPage'
import Loader from 'common/components/loaders/Loader'
import TabWidget from 'common/components/tabWidget'
import Tooltip from 'common/components/Tooltip'
import useContent from 'common/hooks/useContent'
import useSelectedComponentElement from 'common/hooks/useSelectedComponentElement'

import useGetResultsQuery, {
  VariantResult,
} from './api/queries/useGetResultsQuery'
import PersonalizedResultsTable from './components/PersonalizedResultsTable'

const TAB_NAMES = {
  MATURE_DATA: 'MATURE DATA',
  RAW_DATA: 'RAW DATA',
}

const tabs = [
  {
    tabName: TAB_NAMES.MATURE_DATA,
    tabId: 'mature',
    tabIndex: '0',
    tabTooltip:
      'Data is 2+ days old. The open rate and click rate have stabilized and are therefore representative of final performance.',
  },
  {
    tabName: TAB_NAMES.RAW_DATA,
    tabId: 'raw',
    tabIndex: '1',
    tabTooltip:
      'All data is shown. This may include emails that have been sent recently. In this case, the open and click rate metrics for some variants may underestimate their final value.',
  },
]

const PersonalizationSummary = () => {
  const [activeTab, setActiveTab] = useState('0')
  const [variantSearchValue, setVariantSearchValue] = useState('')
  const [isMachineOpensTicked, setIsMachineOpensTicked] = useState(true)

  const history = useHistory()
  const { content } = useContent()
  const { data: selectedComponentElement } = useSelectedComponentElement()

  const variantsToBeApprovedCount =
    selectedComponentElement?.personalized_ids_to_approve?.length ?? 0

  const personalizationRegion =
    selectedComponentElement?.personalization_settings?.configuration
      .privacy_region

  const {
    data: { personalizedVariants, machineSummary } = {},
    ...personalizedVariantsQuery
  } = useGetResultsQuery({
    personalizationId: selectedComponentElement?.personalization_id ?? '',
    personalizationRegion,
    resultType: tabs[activeTab].tabId,
  })

  const searchOptions = useMemo(
    () =>
      personalizedVariants?.map(({ variant }) => ({
        label: variant,
        value: variant,
      })) ?? [],
    [personalizedVariants]
  )

  const filteredVariants = useMemo(() => {
    const filterByVariantName = ({ variant }: VariantResult) => {
      return variantSearchValue.trim().length > 0
        ? variant.toLowerCase().includes(variantSearchValue.toLowerCase())
        : true
    }

    return (
      personalizedVariants?.filter((variant) => filterByVariantName(variant)) ??
      []
    )
  }, [personalizedVariants, variantSearchValue])

  const areVariantsAvailable =
    personalizedVariants?.length === 0 || !personalizedVariants

  const isSearchNoResults =
    filteredVariants.length === 0 && !!variantSearchValue

  const tableContent = personalizedVariantsQuery.isLoading ? (
    <Loader className="min-h-149" />
  ) : areVariantsAvailable ? (
    <EmptyState title="Variant data is not available yet" />
  ) : isSearchNoResults ? (
    <EmptyState title="There are no variants matching your search, please try again" />
  ) : (
    <>
      <PersonalizedResultsTable
        resultType={tabs[activeTab].tabId}
        data={filteredVariants}
        personalizationRegion={personalizationRegion}
        isMachineOpensVisible={isMachineOpensTicked}
      />
      <MachineOpensSummary
        machineSummary={machineSummary}
        isMachineOpensVisible={isMachineOpensTicked}
        onChangeMachineOpensVisible={() =>
          setIsMachineOpensTicked(!isMachineOpensTicked)
        }
      />
    </>
  )

  if (personalizedVariantsQuery.isError) {
    return <ErrorPage />
  }

  return (
    <div className="flex-1-0 bg-gold-40 pb-10">
      {variantsToBeApprovedCount > 0 && (
        <BannerContainer>
          <NewVariantsBanner
            variantsToBeApprovedCount={variantsToBeApprovedCount}
            onClick={() => {
              history.push(
                generatePath(unifiedFlowPaths.review, {
                  contentId: content?._id,
                })
              )
            }}
          />
        </BannerContainer>
      )}
      <TabWidget
        activeTabKey={activeTab}
        data-testid="personalizationResultsWidget"
        destroyInactiveTabPane
        onTabClick={(tab) => {
          setActiveTab(tab)
        }}
        tabs={tabs.map(({ tabName, tabTooltip }) => ({
          name: (
            <Tooltip
              overlay={
                <span className="max-w-xs inline-block">{tabTooltip}</span>
              }
              placement="top"
            >
              <span>{tabName}</span>
            </Tooltip>
          ),
          content: (
            <>
              <hr className="w-full my-8 border-gold-400" />
              {tabName === TAB_NAMES.RAW_DATA && (
                <BannerContainer>
                  <RawDataTabBanner />
                </BannerContainer>
              )}
              <div className="flex justify-end">
                <Autocomplete
                  data-testid="search-variants-input"
                  aria-label="Search variants"
                  placeholder="Search variants"
                  options={searchOptions}
                  onSearch={setVariantSearchValue}
                  inputValue={variantSearchValue}
                  backspaceRemovesValue={true}
                  className="w-85 mb-6"
                />
              </div>
              {tableContent}
            </>
          ),
        }))}
        firstUse={false}
      />
    </div>
  )
}

export default PersonalizationSummary
