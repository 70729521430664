import { useEffect, useState } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import cx from 'classnames'

import { getIntegrationName } from 'common/cepUtils'
import Button from 'common/components/button'
import FormItem from 'common/components/formItem'
import Input from 'common/components/input'
import PasswordInput from 'common/components/PasswordInput'
import RadioGroup from 'common/components/radioGroup'
import SingleSelect from 'common/components/singleSelect'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { DistributionChannel } from 'common/interfaces/projects'
import { getFieldError } from 'features/admin/brandVoice/helpers'
import { FormCardTitle } from 'features/projects/components/projectFormCards/formCard'
import TimeZoneSelect from 'features/projects/components/timeZoneSelect'
import {
  DistributionTypeOptionValue,
  TestingMethod,
} from 'features/projects/interface'

import { CepTemplate } from '../../api'

type Props = {
  className?: string
  cepTemplate?: CepTemplate | undefined
  onClose: () => void
  onSubmit?: (payload: any) => void
}

const messageTypeMap = {
  alwayson: 'Triggered',
  broadcast: 'Broadcast',
}

const distributionChannelMap = {
  email: 'Email',
  email_in_body: 'Email in Body',
  push_notification: 'Push Notification',
  sms: 'SMS',
  social_ad: 'Social Ad',
  web_and_app: 'Web and App',
  in_app_message: 'In-App Message',
  content_card: 'Content Card',
  web_push: 'Web Push',
}

const CreateCepIntegrationWidget = ({
  className,
  onClose,
  cepTemplate,
  onSubmit,
}: Props) => {
  const formState = useFormState()
  const form = useForm()
  const { change: formChange } = form
  const { distributionChannel, testingMethod, distributionType } =
    formState.values
  const [isDistributionChannelSelected, setIsDistributionChannelSelected] =
    useState(false)
  const distributionChannels = cepTemplate?.distribution_channel?.map(
    (value) => value.name
  )
  const selectedDistributionChannel = cepTemplate?.distribution_channel?.find(
    (item) => item.name === distributionChannel
  )
  useEffect(() => {
    const updates = {
      distributionChannel: distributionChannel,
      testingMethod: testingMethod,
      messageType: testingMethod ? distributionType : undefined,
    }

    Object.entries(updates).forEach(([key, value]) => {
      if (value) {
        formChange(key, value)
      }
    })
  }, [formChange, distributionChannel, testingMethod, distributionType])

  const validateName = (value?: string) => {
    return !value ? 'Please enter the integration name.' : true
  }

  return (
    <div className="max-w-[800px] w-full">
      <Widget type="basic" className={cx('bg-white p-4', className)}>
        <div className="relative">
          <button
            onClick={onClose}
            className="absolute top-0 right-0 text-gray-500 hover:text-gray-700 transition-colors"
            aria-label="Close"
          >
            ✕
          </button>
          <WidgetHeader
            title={`${getIntegrationName(
              cepTemplate?.cep_name!
            )} integration settings`}
            className="text-3xl font-serif"
          />
        </div>

        <div className="mb-4 text-gray-700">
          Fill in your details and credentials and select 'ADD' to link account.
        </div>
        <form
          onSubmit={async (e) => {
            e.preventDefault()
            onClose()
            onSubmit?.({
              ...formState.values,
              cep_name: cepTemplate?.cep_name,
              trackingMetrics: selectedDistributionChannel?.tracking_metrics,
            })
          }}
        >
          <div>
            <FormCardTitle title="Details" />
            <Field<string> name="name" validate={validateName}>
              {({ input, meta }) => (
                <FormItem
                  label="Enter name"
                  htmlFor="name"
                  className="mt-4"
                  error={getFieldError(meta)}
                >
                  <Input
                    data-cy="integration-name"
                    data-testid="integration-name"
                    className="w-full p-3 border rounded-md"
                    type="text"
                    variant="default"
                    id="name"
                    name="name"
                    placeholder="Name of integration should be specific to your use-case"
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                  />
                  <div className="text-3">Mention which channel it's for.</div>
                </FormItem>
              )}
            </Field>

            <Field<DistributionChannel> name="distributionChannel">
              {({ input, meta }) => (
                <FormItem label="Channel" error={getFieldError(meta)}>
                  <SingleSelect
                    className="w-full"
                    placeholder="Select..."
                    value={input.value}
                    onChange={(distributionChannel: any) => {
                      input.onChange(distributionChannel)
                      setIsDistributionChannelSelected(!!distributionChannel)
                      form.restart({
                        ...formState.values,
                        distributionChannel: distributionChannel.value,
                      })
                    }}
                    options={distributionChannels?.map((value) => ({
                      label: distributionChannelMap[value],
                      value,
                    }))}
                  />
                </FormItem>
              )}
            </Field>

            {isDistributionChannelSelected &&
              selectedDistributionChannel?.message_type && (
                <>
                  <Field<DistributionTypeOptionValue> name="distributionType">
                    {({ input, meta }) => (
                      <FormItem
                        label="Distribution type"
                        htmlFor="distributionType"
                        error={getFieldError(meta)}
                      >
                        <RadioGroup
                          data-cy="distribution-type"
                          data-testid="distribution-type"
                          value={input.value}
                          name="distribution-type"
                          options={selectedDistributionChannel?.message_type.map(
                            ({ value }) => {
                              return {
                                label: messageTypeMap[value!],
                                value,
                              }
                            }
                          )}
                          onChange={input.onChange}
                        />
                      </FormItem>
                    )}
                  </Field>
                </>
              )}
            {isDistributionChannelSelected &&
              selectedDistributionChannel?.testing_methodology && (
                <>
                  <Field<TestingMethod> name="testingMethod">
                    {({ input, meta }) => (
                      <FormItem className="mb-6" error={getFieldError(meta)}>
                        <SingleSelect
                          data-cy="testing-method-select"
                          data-testid="testing-method-select"
                          className="max-w-xs"
                          label="Testing methodology"
                          name="testingMethod"
                          value={input.value}
                          options={
                            selectedDistributionChannel?.testing_methodology
                              ? Object.keys(
                                  selectedDistributionChannel?.testing_methodology ||
                                    {}
                                ).map((key) => ({
                                  // Reformating the key to be more readable
                                  label: key
                                    .split('_')
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(' '),
                                  value: key,
                                }))
                              : []
                          }
                          onChange={(val) => {
                            val && input.onChange(val.value)
                            // Force form to update all values
                            form.restart({
                              ...formState.values,
                              testingMethod: val?.value,
                            })
                          }}
                        />
                      </FormItem>
                    )}
                  </Field>
                </>
              )}
            {testingMethod && (
              <>
                {selectedDistributionChannel?.testing_methodology?.[
                  String(testingMethod)
                ]?.credentials?.map(({ name, label, type }) => (
                  <Field key={name} name={`integrationCredentials.${name}`}>
                    {({ input, meta }) => (
                      <FormItem
                        label={label}
                        htmlFor={name}
                        error={getFieldError(meta)}
                      >
                        {
                          {
                            text: (
                              <Input
                                data-cy={`${name}-input`}
                                data-testid={`${name}-input`}
                                className="max-w-xs"
                                type="text"
                                variant="default"
                                id={name}
                                name={name}
                                value={input.value || ''}
                                onChange={(val) => {
                                  val && input.onChange(val.target.value)
                                  form.restart({
                                    ...formState.values,
                                    integrationCredentials: {
                                      ...formState.values
                                        .integrationCredentials,
                                      [name]: val?.target.value,
                                    },
                                  })
                                }}
                              />
                            ),
                            timezone: (
                              <TimeZoneSelect
                                data-cy={`${name}-select`}
                                data-testid={`${name}-select`}
                                value={input.value || ''}
                                onChange={(val) => {
                                  val && input.onChange(val)
                                  form.restart({
                                    ...formState.values,
                                    integrationCredentials: {
                                      ...formState.values
                                        .integrationCredentials,
                                      [name]: val,
                                    },
                                  })
                                }}
                              />
                            ),
                            password: (
                              <PasswordInput
                                data-cy={`${name}-input`}
                                data-testid={`${name}-input`}
                                className="max-w-xs"
                                id={name}
                                name={name}
                                value={input.value || ''}
                                onChange={(val) => {
                                  val && input.onChange(val.target.value)
                                  form.restart({
                                    ...formState.values,
                                    integrationCredentials: {
                                      ...formState.values
                                        .integrationCredentials,
                                      [name]: val?.target.value,
                                    },
                                  })
                                }}
                              />
                            ),
                          }[type]
                        }
                      </FormItem>
                    )}
                  </Field>
                ))}

                <Field name="integrationCredentials">
                  {({ meta }) => <FormItem error={getFieldError(meta)} />}
                </Field>
              </>
            )}
          </div>

          <div className="flex justify-end items-center mt-8 space-x-4">
            <Button
              data-cy="cancel-button"
              onClick={onClose}
              className="px-6 py-2 text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md"
            >
              CANCEL
            </Button>
            <Button
              type="submit"
              data-cy="add-button"
              variant="primary"
              className="px-6 py-2 bg-yellow-400 text-black rounded-md hover:bg-yellow-500"
              disabled={
                !formState.values.name?.trim() ||
                !formState.values.distributionChannel ||
                !formState.values.distributionType ||
                !formState.values.testingMethod
              }
            >
              ADD
            </Button>
          </div>
        </form>
      </Widget>
    </div>
  )
}

export default CreateCepIntegrationWidget
