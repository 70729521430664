import { ProjectConfigurationOptionsQueryParams } from '@phrasee/phrasee-typings/typings/project/project-configuration.types'
import { AxiosResponse, CancelTokenSource } from 'axios'
import { gql } from 'graphql-request'
import { cloneDeep } from 'lodash'

import { connectInternalApi, fetchGraphQL } from 'common/api'

import {
  ProjectConfig,
  ProjectConfigResponse,
  ProjectOptionsConfig,
  ProjectOptionsResponse,
  ProjectTypeOptionsResponse,
  ResponseProject,
  UpdateProjectPayload,
} from '../interface'

import {
  CampaignsResponse,
  CreateProjectResponse,
  SaveProjectError,
  ValidatonErrors,
} from './interface'

// TODO : When moving all the interface to lovejoy lets update the ProjectConfigurationOptionsQueryParams
interface ProjectConfigurationOptionsQueryParamsV2
  extends ProjectConfigurationOptionsQueryParams {
  cepIntegrationId?: string
}

export async function fetchProjects(
  accountId: string,
  source: CancelTokenSource
): Promise<ResponseProject[]> {
  const query = gql`
    query projectsNav($filter: ProjectFilter) {
      data: projects(filter: $filter) {
        _id
        name
        created
        last_updated
        deleted
        archived
        project_configuration {
          campaign_configurations {
            distribution_channel
            distribution_type
          }
        }
      }
    }
  `

  const variables = {
    filter: {
      accountId,
      includeDeleted: true,
      includeArchived: true,
    },
  }

  return fetchGraphQL<ResponseProject[]>(
    { query, variables },
    {
      cancelToken: source.token,
    }
  )
}

export async function fetchProjectCampaigns(
  accountId: string,
  projectId: string,
  source: CancelTokenSource
): Promise<CampaignsResponse> {
  const query = gql`
    query projectUpdateCampaigns(
      $filter: CampaignFilter
      $pageSize: Int
      $page: Int
    ) {
      data: campaigns(page: $page, pageSize: $pageSize, filter: $filter) {
        campaigns {
          _id
          name
          created
          send_date
        }
        count
      }
    }
  `

  const variables = {
    filter: {
      accountId,
      projectId,
      notSent: true,
    },
    // fetch all campaigns in one page
    pageSize: 100000,
    pageCount: 1,
  }

  return fetchGraphQL<CampaignsResponse>(
    { query, variables },
    {
      cancelToken: source.token,
    }
  )
}

export const getProjectTypeOptions = async (
  accountId: string
): Promise<AxiosResponse<ProjectTypeOptionsResponse>> => {
  return connectInternalApi.get<ProjectTypeOptionsResponse>(
    `v1/core/marge/accounts/${accountId}/projects/configuration/options`
  )
}

export const getProjectOptions = async (
  accountId: string,
  config: ProjectOptionsConfig
): Promise<AxiosResponse<ProjectOptionsResponse>> => {
  const {
    projectType,
    distributionChannel,
    distributionType,
    integrationType,
    splitSettingsType,
    languageGenerationMethod,
    testingMethod,
    selectionMetric,
    cepIntegrationId,
  } = config

  const searchParams: ProjectConfigurationOptionsQueryParamsV2 = {
    projectType,
    distributionChannel,
    distributionType,
    integrationType,
    splitSettingsType:
      splitSettingsType === 'none' ? undefined : splitSettingsType,
    languageGenerationMethod,
    testingMethod,
    selectionMetric,
    cepIntegrationId,
  }
  const filteredSearchParams: Record<string, string> = {}

  Object.keys(searchParams).forEach((key) => {
    const parameter = searchParams[key]

    if (parameter && parameter.length) {
      filteredSearchParams[key] = parameter
    }
  })

  return connectInternalApi.get<ProjectOptionsResponse>(
    `v1/core/marge/accounts/${accountId}/projects/configuration/options?${new URLSearchParams(
      filteredSearchParams
    )}`
  )
}

const formatProjectConfig = (config: ProjectConfig): ProjectConfig => {
  const configCopy = cloneDeep(config)

  if (configCopy.splitSettingsType && configCopy.splitSettingsType === 'none') {
    configCopy.splitSettingsType = undefined
  }

  return configCopy
}

const handleSaveProjectError = (
  err: unknown
): SaveProjectError | ValidatonErrors => {
  const error = err as SaveProjectError
  const validationErrors = error?.response?.data?.msg

  if (validationErrors) {
    // promise must resolve, not reject with submission errors for react-final-from
    return { state: 'validationError', validationErrors }
  } else {
    return { ...error, state: 'error' }
  }
}

export const createProject = async ({
  accountId,
  config,
}: {
  accountId: string
  config: ProjectConfig
}): Promise<CreateProjectResponse> => {
  try {
    const result = await connectInternalApi.post(
      `v1/core/marge/accounts/${accountId}/projects`,
      formatProjectConfig(config)
    )

    return { state: 'success', ...result }
  } catch (err: unknown) {
    return handleSaveProjectError(err)
  }
}

export const getProjectConfig = async (
  accountId: string,
  projectId: string
): Promise<AxiosResponse<ProjectConfigResponse>> => {
  return connectInternalApi.get<ProjectConfigResponse>(
    `v1/core/marge/accounts/${accountId}/projects/${projectId}?returnMappedUIFields=1`
  )
}

export const updateProject = async ({
  accountId,
  projectId,
  config,
}: {
  accountId: string
  projectId: string
  config: UpdateProjectPayload
}): Promise<CreateProjectResponse> => {
  try {
    const result = await connectInternalApi.patch(
      `v1/core/marge/accounts/${accountId}/projects/${projectId}`,
      formatProjectConfig(config)
    )

    return { state: 'success', ...result }
  } catch (err: unknown) {
    return handleSaveProjectError(err)
  }
}

export const updateProjectAction = async ({
  action,
  value,
  accountId,
  projectId,
  source,
}: {
  action: 'deleted' | 'archived'
  value: boolean
  accountId: string
  projectId: string
  source: CancelTokenSource
}): Promise<AxiosResponse> =>
  connectInternalApi.patch(
    `/v1/core/marge/accounts/${accountId}/projects/${projectId}?action_type=set_${action}`,
    {
      [action]: value,
    },
    {
      cancelToken: source.token,
    }
  )

export const fetchAccountProjects = async (accountId: string) => {
  const response = await connectInternalApi.get(
    `/v1/core/monorail/accounts/${accountId}/projects`
  )

  return response.data
}
