// Project paths
export const projectsPath = '/accounts/:accountId/projects'
export const projectSettingsPath = '/accounts/:accountId/projects/:projectId'
export const projectContentPath =
  '/accounts/:accountId/projects/:projectId/content'
export const projectNodesPath = '/accounts/:accountId/projects/:projectId/nodes'
export const createProjectPath = '/accounts/:accountId/projects/create'

// Brand voice paths
export const brandVoicePath = '/admin/accounts/:accountId/brand-voice'
export const toneOfVoicePath =
  '/admin/accounts/:accountId/brand-voice/:brandVoiceId/tone-of-voice'
export const generateToneOfVoicePath =
  '/admin/accounts/:accountId/brand-voice/:brandVoiceId/tone-of-voice/generate'
export const editToneOfVoicePath =
  '/admin/accounts/:accountId/brand-voice/:brandVoiceId/tone-of-voice/edit'
export const styleGuidePath =
  '/admin/accounts/:accountId/brand-voice/:brandVoiceId/style-guide'

// Admin settings paths
export const adminPath = '/admin'
export const adminAccountPath = '/admin/accounts/:accountId?'

export const integrationSettingsPath = '/admin/accounts/:accountId/integrations'

export const imageLibraryPath = '/admin/accounts/:accountId/image-library'

export const mergeTagsPath = '/admin/accounts/:accountId/merge-tags'
export const revenueSettingsPath =
  '/admin/accounts/:accountId/revenue-reporting'
export const accountsTokensPaths = '/admin/accounts/:accountId/api-tokens'

// Admin language paths
export const adminLanguagePath = '/admin/language'
export const regionSettingsPath = '/admin/language/region'
export const nodesPath = '/admin/language/nodes'
export const personalizedContentPath = '/admin/language/personalized-content'
export const personalizationCombinationPerIdPath =
  '/admin/language/personalized-content/:personalizationId'
export const combinationPerPersonalizationPath =
  '/admin/language/personalized-content/:personalizationId/combinations/:combinationId'

export const campaignsPath = '/accounts/:accountId/campaigns'

export const selectedCampaignPath =
  '/accounts/:accountId/campaigns/:campaignId?'

export const reportsInsightsPath = '/accounts/:accountId/reports/insights'
export const reportsPerformancePath = '/accounts/:accountId/reports/performance'
export const reportsPersonalizationPath =
  '/accounts/:accountId/reports/personalization'
export const reportsDashboardPath = '/accounts/:accountId/reports/dashboard'
export const reportsDataSearchPath = '/accounts/:accountId/reports/data-search'
export const reportsSpotterAiPath = '/accounts/:accountId/reports/spotter-ai'
export const personalizationPerIdPath =
  '/accounts/:accountId/reports/personalization/:id'

export const reportsPath = '/accounts/:accountId/reports'
export const accountsPath = '/accounts'
export const dashboardPath = '/dashboard'
export const homePath = '/home'
export const profilePath = '/profile'
export const contentPath = '/content'
export const demoPath = '/demo'

export const unifiedFlowPaths = {
  home: '/unified-flow',
  content: '/unified-flow/:contentId',
  generate: '/unified-flow/:contentId/generate',
  integrations: '/unified-flow/:contentId/integrations',
  launch: '/unified-flow/:contentId/launch',
  review: '/unified-flow/:contentId/review',
  results: '/unified-flow/:contentId/results',
}

export const dataSourcesPaths = {
  customerAttributes:
    '/admin/accounts/:accountId/data-sources/customer-attributes',
  productCatalog: '/admin/accounts/:accountId/data-sources/product-catalogue',
}
