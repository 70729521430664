import {
  createAction,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit'

import { changeAccountId } from 'features/auth/store/authSlice'

type DataIngestionState = {
  fileStates: {
    product: {
      fileName: string
      created: string
      data: Object[]
      columns: string[]
      type: string
      isUploading: boolean
      hasError: boolean
      progressPercentage?: number
      isProcessing: boolean
    }
  }
}

const initialState: DataIngestionState = {
  fileStates: {
    product: {
      fileName: '',
      created: '',
      data: [],
      columns: [],
      type: '',
      isUploading: false,
      hasError: false,
      isProcessing: false,
    },
  },
}

const dataSourceSlice = createSlice({
  name: 'dataSource',
  initialState,
  reducers: {
    productUploadStart: (
      state,
      action: PayloadAction<{ data: Object[]; fileName: string }>
    ) => {
      state.fileStates.product.fileName = action.payload.fileName
      state.fileStates.product.data = action.payload.data
      state.fileStates.product.hasError = false
    },
    productUploadProgress: (
      state,
      action: PayloadAction<{ percentage: number }>
    ) => {
      state.fileStates.product.progressPercentage = action.payload.percentage
    },
    productUploadCompleted: (state) => {
      state.fileStates.product.isUploading = false
      state.fileStates.product.progressPercentage = undefined
      state.fileStates.product.isProcessing = true
    },
    fileNameConfirmed: (
      state,
      { payload: { fileName } }: PayloadAction<{ fileName: string }>
    ) => {
      state.fileStates.product.fileName = fileName
      state.fileStates.product.progressPercentage = 1
      state.fileStates.product.isUploading = true
    },
    fileProcessError: (state) => {
      state.fileStates.product.isUploading = false
      state.fileStates.product.hasError = true
      state.fileStates.product.progressPercentage = undefined
      state.fileStates.product.isProcessing = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeAccountId.fulfilled, () => initialState)
    builder.addMatcher(
      isAnyOf(fileProcessSuccess, fileProcessCancel),
      (state) => {
        state.fileStates.product = initialState.fileStates.product
      }
    )
  },
})

export const {
  productUploadStart,
  fileNameConfirmed,
  fileProcessError,
  productUploadProgress,
  productUploadCompleted,
} = dataSourceSlice.actions

export const fileProcessSuccess = createAction('dataSource/fileProcessSuccess')
export const fileProcessCancel = createAction('dataSource/fileProcessCancel')

export default dataSourceSlice.reducer
