import { Field } from 'react-final-form'

import useGetBrandVoicesQuery from 'common/api/queries/useGetBrandVoicesQuery'
import FormItem from 'common/components/formItem'
import IconTitleGroup from 'common/components/iconTitleGroup'
import SingleSelect, { SelectValue } from 'common/components/singleSelect'

type Props = {
  brandVoiceId: string
  isDisabled: boolean
}

const BrandVoiceSettings = ({ brandVoiceId, isDisabled }: Props) => {
  const { data: brandVoices } = useGetBrandVoicesQuery()

  const brandVoicesOptions = brandVoices?.map(({ id, name }) => ({
    value: id,
    label: name,
  }))

  if (!brandVoices || brandVoices.length === 0) {
    return null
  }

  const defaultValue = brandVoicesOptions?.find(
    (item) => item.value === brandVoiceId
  )

  return (
    <div className="flex flex-col w-150">
      <IconTitleGroup title="Brand voice" />
      <span className="text-base-700 text-base mt-1 font-regular">
        This will set the language settings for the generated content. For more
        details, go to Brand Voice.
      </span>
      <Field<SelectValue>
        name="brand_voice"
        render={({ input }) => (
          <FormItem htmlFor="brand_voice">
            <SingleSelect
              className="mt-6"
              data-cy="brand-voice-select"
              data-testid="brand-voice-select"
              name="brand_voice"
              placeholder="Select your brand voice"
              value={input.value.value || defaultValue?.value}
              onChange={input.onChange}
              onBlur={input.onBlur}
              options={brandVoicesOptions}
              isDisabled={isDisabled}
            />
          </FormItem>
        )}
      />
    </div>
  )
}

export default BrandVoiceSettings
