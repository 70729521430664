import {
  PRODUCT_CATALOGS_PATH,
  ROOT_PATH,
} from 'common/api/queryKeys/dataSourcesKeys'
import { TreeNode } from 'common/components/catalog/Catalog'
import { CustomerAttributes } from 'features/personalization/api'

export const keys = {
  customerAttributes: ({
    accountId,
    catalogueAttributeId,
    region,
  }: {
    accountId: string
    catalogueAttributeId: string
    region: string
  }) => [
    ROOT_PATH,
    'customer-attributes',
    accountId,
    catalogueAttributeId,
    region,
  ],
  customerAttributeRecords: ({ accountId }: { accountId: string }) => [
    ROOT_PATH,
    'customer-attributes-records',
    accountId,
  ],
  allProductCatalogQueries: [ROOT_PATH, PRODUCT_CATALOGS_PATH],
  productCatalogCategories: ({
    accountId,
    region,
    catalogId,
  }: {
    accountId: string | undefined
    region: string | undefined
    catalogId: string | undefined
  }) => [ROOT_PATH, PRODUCT_CATALOGS_PATH, accountId, region, catalogId],
  productCatalogs: ({
    accountId,
    region,
  }: {
    accountId: string | undefined
    region: string | undefined
  }) => [ROOT_PATH, PRODUCT_CATALOGS_PATH, accountId, region],
  productCatalogSettings: ({
    accountId,
    productCatalogId,
    region,
  }: {
    accountId: string
    productCatalogId: string
    region: string
  }) => [
    ROOT_PATH,
    PRODUCT_CATALOGS_PATH,
    'settings',
    accountId,
    productCatalogId,
    region,
  ],
  categoryPerformance: ({
    accountId,
    customerAttributes,
    personalizationId,
    productCategories,
  }: {
    accountId: string
    personalizationId: string
    customerAttributes: CustomerAttributes | undefined
    productCategories: TreeNode | undefined
  }) => [
    ROOT_PATH,
    'category-performance',
    accountId,
    personalizationId,
    customerAttributes,
    productCategories,
  ],
  account: ({ accountId, userId }: { accountId: string; userId: string }) => [
    ROOT_PATH,
    'account-info',
    accountId,
    userId,
  ],
} as const
