import { useEffect, useState } from 'react'

import ConfirmationModal from 'common/components/confirmationModal'
import Drawer from 'common/components/drawer'
import type { MergeTagListItem } from 'features/admin/accountSettings/api/api'
import useUpdateMergeTagMutation from 'features/admin/accountSettings/api/mutations/useUpdateMergeTagMutation'

import ManageMergeTagCancelButton from './ManageMergeTagCancelButton'
import ManageMergeTagForm from './ManageMergeTagForm'
import ManageMergeTagSubmitButton from './ManageMergeTagSubmitButton'

type Props = {
  initialValues: MergeTagListItem
  isOpen: boolean
  onOpenChange: (value: boolean) => void
}

const EditMergeTagDrawer = ({ isOpen, onOpenChange, initialValues }: Props) => {
  const [isFormDirty, setFormDirty] = useState<boolean>(false)
  const [showEditTagModal, setShowEditTagModal] = useState<boolean>(false)

  const updateMergeTagMutation = useUpdateMergeTagMutation({
    tagId: initialValues?.id,
  })

  const handleUpdate = (values) => {
    updateMergeTagMutation.mutate(values, {
      onSuccess: () => {
        onOpenChange(false)
      },
    })
  }

  useEffect(() => {
    // The Drawer adds pointer-events to the body, which prevents any actions from being clickable
    // when the confirmation modal is opened.
    // https://github.com/emilkowalski/vaul/issues/492
    if (!showEditTagModal) {
      return
    }

    const originalPointerEvents = document.body.style.pointerEvents

    const requestAnimation = window.requestAnimationFrame(() => {
      document.body.style.pointerEvents = 'auto'
    })

    return () => {
      window.cancelAnimationFrame(requestAnimation)
      document.body.style.pointerEvents = originalPointerEvents
    }
  }, [showEditTagModal])

  return (
    <>
      <Drawer
        isOpen={isOpen}
        title="Edit merge tag"
        dismissible={!showEditTagModal}
        onOpenChange={(e) => {
          onOpenChange(e)
        }}
        content={
          <ManageMergeTagForm
            initialValues={initialValues}
            isEditMode
            onChange={(props) => {
              const dirtyFields = Object.keys(props.dirtyFields)

              setFormDirty(dirtyFields.length > 0)
            }}
            onSubmit={handleUpdate}
            footer={
              <>
                <ManageMergeTagCancelButton
                  onClick={() => {
                    if (isFormDirty) {
                      setShowEditTagModal(true)
                    } else {
                      onOpenChange(false)
                    }
                  }}
                  data-testid="edit-merge-tag-drawer-cancel-button"
                />
                <ManageMergeTagSubmitButton
                  data-testid="edit-merge-tag-drawer-add-button"
                  isLoading={updateMergeTagMutation.isLoading}
                >
                  Update
                </ManageMergeTagSubmitButton>
              </>
            }
          ></ManageMergeTagForm>
        }
      />

      <ConfirmationModal
        title="Are you sure you want to leave without saving?"
        confirmationText="Any unsaved changes will be lost."
        confirmButtonText="YES, I WANT TO LEAVE"
        cancelButtonText="GO BACK"
        open={showEditTagModal}
        hasConfirmationSlider={false}
        onConfirm={() => {
          onOpenChange(false)
          setShowEditTagModal(false)
        }}
        onCancel={() => {
          setShowEditTagModal(false)
        }}
      />
    </>
  )
}

export default EditMergeTagDrawer
