import { createApi } from 'common/api'

interface UploaderOptions {
  api: ReturnType<typeof createApi>
  file: File
  fileName: string
  accountId: string
  chunkSize?: number
  threadsQuantity?: number
}

interface ProgressCache {
  [key: number]: number
}

interface Part {
  PartNumber: number
  ETag: string
  signedUrl: string
}

export class Uploader {
  readonly chunkSize: number
  readonly threadsQuantity: number
  readonly file: File
  readonly fileName: string
  readonly accountId: string | undefined
  private aborted: boolean
  private uploadedSize: number
  private progressCache: ProgressCache
  private activeConnections: { [key: number]: XMLHttpRequest }
  readonly parts: Part[]
  readonly uploadedParts: Omit<Part, 'signedUrl'>[]
  private fileId: string | null
  private fileKey: string | null
  private fileChunks: { [key: number]: Blob }
  private onProgressFn: (options: {
    sent: number
    total: number
    percentage: number
  }) => void
  private onErrorFn: (error: Error) => void
  readonly api: ReturnType<typeof createApi>

  constructor(options: UploaderOptions) {
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.chunkSize = options.chunkSize ?? 1024 * 1024 * 5
    // number of parallel uploads
    this.threadsQuantity = Math.min(options.threadsQuantity ?? 5, 15)
    this.file = options.file
    this.fileName = options.fileName
    this.aborted = false
    this.uploadedSize = 0
    this.progressCache = {}
    this.activeConnections = {}
    this.parts = []
    this.uploadedParts = []
    this.fileId = null
    this.fileKey = null
    this.fileChunks = {}
    this.onProgressFn = () => {}
    this.onErrorFn = () => {}
    this.accountId = options.accountId
    this.api = options.api
  }

  async start({ data }: { data: any }) {
    await this.initialize({ data })
  }

  async initialize({ data }: { data: any }) {
    try {
      let fileName = this.fileName
      const ext = this.file.name.split('.').pop()
      if (ext) {
        fileName += `.${ext}`
      }

      const removeEmptyValues = (data) => {
        return Object.fromEntries(
          Object.entries(data).filter(
            ([_, v]) =>
              v !== null &&
              v !== undefined &&
              !(Array.isArray(v) && v.length === 0)
          )
        )
      }

      const initializedResponse = await this.api.post(
        `/accounts/${this.accountId}/data/upload/product/csv/initialize`,
        removeEmptyValues({
          fileName: fileName,
          fileSizeBytes: this.file.size,
          ...data,
        })
      )

      const response = initializedResponse.data
      this.fileId = response.uploadId
      this.fileKey = response.fileKey
      const newParts = response.parts
      this.parts.push(...newParts)

      this.sendNext()
    } catch (error: any) {
      await this.complete(error)
    }
  }

  sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length

    if (activeConnections >= this.threadsQuantity) {
      return
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete()
      }

      return
    }

    const part = this.parts.pop()
    if (this.file && part) {
      const sentSize = (part.PartNumber - 1) * this.chunkSize
      this.fileChunks[part.PartNumber] = this.file.slice(
        sentSize,
        sentSize + this.chunkSize
      )

      const sendChunkStarted = () => {
        this.sendNext()
      }

      this.sendChunk(part.PartNumber, part, sendChunkStarted)
        .then(() => {
          this.sendNext()
        })
        .catch((error) => {
          this.parts.push(part)
        })
    }
  }

  async complete(error?: Error) {
    if (error && !this.aborted) {
      this.onErrorFn(error)
      return
    }

    if (error) {
      this.onErrorFn(error)
      return
    }

    try {
      await this.sendCompleteRequest()
    } catch (error: any) {
      this.onErrorFn(error)
    }
  }

  async sendCompleteRequest() {
    if (this.fileId && this.fileKey) {
      const videoFinalizationMultiPartInput = {
        uploadId: this.fileId,
        fileKey: this.fileKey,
        parts: this.uploadedParts,
      }

      await this.api?.post(
        `/accounts/${this.accountId}/data/upload/product/csv/finalize`,
        {
          ...videoFinalizationMultiPartInput,
        }
      )
    }
  }

  sendChunk(partNumber: number, part: Part, sendChunkStarted) {
    return new Promise((resolve, reject) => {
      this.upload(partNumber, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error('Failed chunk upload'))
            return
          }

          resolve(true)
        })
        .catch((error) => {
          reject(new Error(error))
        })
    })
  }

  handleProgress(part, event) {
    if (this.file) {
      if (
        event.type === 'progress' ||
        event.type === 'error' ||
        event.type === 'abort'
      ) {
        this.progressCache[part] = event.loaded
      }

      if (event.type === 'uploaded') {
        this.uploadedSize += this.progressCache[part] || 0
        delete this.progressCache[part]
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((total, id) => total + this.progressCache[id], 0)

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size)

      const total = this.file.size

      const percentage = Math.round((sent / total) * 100)

      if (event.type === 'progress') {
        this.onProgressFn({
          sent: sent,
          total: total,
          percentage: percentage,
        })
      }
    }
  }

  upload(partNumber: number, part: Part, sendChunkStarted: () => void) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      if (this.fileId && this.fileKey) {
        this.activeConnections[part.PartNumber - 1] = new XMLHttpRequest()
        const xhr = this.activeConnections[part.PartNumber - 1]

        sendChunkStarted()

        const progressListener = this.handleProgress.bind(
          this,
          part.PartNumber - 1
        )

        xhr.upload.addEventListener('progress', progressListener)

        xhr.addEventListener('error', progressListener)
        xhr.addEventListener('abort', progressListener)
        xhr.addEventListener('loadend', progressListener)

        xhr.open('PUT', part.signedUrl)

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            const ETag = xhr.getResponseHeader('ETag')

            if (ETag) {
              const uploadedPart = {
                PartNumber: part.PartNumber,
                ETag: ETag.replaceAll('"', ''),
              }

              this.uploadedParts.push(uploadedPart)

              resolve(xhr.status)
              delete this.activeConnections[part.PartNumber - 1]
              delete this.fileChunks[partNumber]
            }
          }
        }

        xhr.onerror = (error) => {
          reject(error)
          delete this.activeConnections[part.PartNumber - 1]
          delete this.fileChunks[partNumber]
        }

        xhr.onabort = () => {
          reject(new Error('Upload canceled by user'))
          delete this.activeConnections[part.PartNumber - 1]
          delete this.fileChunks[partNumber]
        }

        xhr.send(this.fileChunks[partNumber])
      }
    })
  }

  onProgress(onProgress) {
    this.onProgressFn = onProgress
    return this
  }

  onError(onError) {
    this.onErrorFn = onError
    return this
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort()
      })

    this.aborted = true
  }
}
