import {
  ChangeEvent,
  cloneElement,
  forwardRef,
  InputHTMLAttributes,
  isValidElement,
  ReactNode,
  useState,
} from 'react'
import cx from 'classnames'

import { AlertCircleInverted, Check, CloseSquare, Search } from 'common/icons'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  type:
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week'
  value?: string
  className?: string
  'data-cy'?: string
  'data-testid'?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  iconClassName?: string
  textColorClassName?: string
  clear?: () => void
  isValid?: boolean
  hasError?: boolean
  variant?: 'default' | 'small'
  suffixIcon?: ReactNode
  height?: string | number | undefined
}

const ICON_WIDTH = 16
const ICON_SPACING = 8
const BASE_PADDING = 32

const calculateInputPaddingRight = (icons: boolean[]) => {
  const iconsCount = icons.filter(Boolean).length

  if (iconsCount === 0) {
    return undefined
  }

  const iconsWidth = iconsCount * ICON_WIDTH
  const spacingWidth = (iconsCount - 1) * ICON_SPACING
  const totalPadding = iconsWidth + spacingWidth + BASE_PADDING

  return totalPadding
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    type,
    value,
    className,
    textColorClassName,
    'data-cy': dataCy,
    'data-testid': dataTestId,
    onChange,
    clear,
    isValid,
    hasError,
    variant,
    disabled,
    suffixIcon,
    style,
    height = 'h-10',
    ...rest
  } = props

  const [isFocused, setIsFocused] = useState<boolean>(false)

  const hasIconOnLeft = type === 'search'

  const totalInputPaddingRight = calculateInputPaddingRight([
    !!clear,
    !!suffixIcon,
    hasError !== isValid,
  ])

  const hasIconOnRight = clear || hasError || isValid || suffixIcon

  const textColor = textColorClassName || 'text-base-700 placeholder-gold-700'
  const inputClasses = cx(
    `border-1 rounded-sm leading-4 w-full h-full outline-none ${textColor}`,
    {
      'border-gold-400 hover:border-gold-500 focus:border-base-700 active:border-base-700':
        !hasError && !disabled && !isValid,
      'border-RAGred': hasError,
      'border-RAGGreen': isValid,
      'border-gold-400 cursor-not-allowed': disabled,
      'pl-10': hasIconOnLeft,
      'pr-4':
        !hasIconOnRight &&
        !className?.includes('pr-') &&
        !className?.includes('px-'),
      'pl-4':
        !hasIconOnLeft &&
        !className?.includes('pl-') &&
        !className?.includes('px-'),
      'py-3': !className?.includes('py-'),
      'text-sm': !className?.includes('text-'),
      'font-normal': !className?.includes('font-'),
      'bg-gold-40': !className?.includes('bg-'),
    },
    className
  )

  return (
    <div
      className={cx(`relative flex-1 flex items-center ${height}`, {
        'opacity-50 cursor-not-allowed': disabled,
      })}
    >
      {hasIconOnLeft && (
        <div className="absolute ml-4 flex">
          <Search
            state={isFocused ? 'selected' : 'default'}
            className={cx('text-base-700', { 'opacity-100': isFocused })}
            size={4}
          />
        </div>
      )}
      <input
        className={inputClasses}
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        ref={ref}
        type={type}
        data-cy={dataCy}
        data-testid={dataTestId}
        data-lpignore="true"
        disabled={disabled}
        style={{
          paddingRight: totalInputPaddingRight,
          ...style,
        }}
        {...rest}
      />
      {hasIconOnRight && type !== 'hidden' && (
        <div className="grid gap-x-2 grid-flow-col items-center absolute right-0 mr-4 z-10">
          {hasError && !isValid && (
            <AlertCircleInverted
              isDefaultColor={false}
              className="text-base-700"
              size={4}
            />
          )}
          {isValid && !hasError && (
            <Check isDefaultColor={false} className="text-base-700" size={4} />
          )}
          {isValidElement(suffixIcon) &&
            cloneElement(suffixIcon, {
              className: cx({
                'text-gold-700': !hasError && !isValid,
                'text-base-700': hasError || isValid,
              }),
            } as {
              className: string
            })}
          {clear && value && (
            <button
              aria-label="Clear input"
              onMouseDown={clear}
              disabled={disabled}
              className="flex"
            >
              <CloseSquare
                state={isFocused ? 'selected' : 'default'}
                size={4}
                className={cx('text-base-700', {
                  'opacity-100': isFocused,
                  'cursor-not-allowed': disabled,
                })}
              />
            </button>
          )}
        </div>
      )}
    </div>
  )
})

export default Input
