import React from 'react'
import prettyBytes from 'pretty-bytes'

import Tooltip from 'common/components/Tooltip'

interface Props {
  fileName: string
  fileSize: number
  numberOfRows: number
  numberOfColumns: number
}

const FileInfo: React.FC<Props> = ({
  fileName,
  fileSize,
  numberOfRows,
  numberOfColumns,
}) => {
  return (
    <div className="text-base-700 inline-flex gap-4 p-2 bg-gold-40 rounded-sm ">
      <Tooltip overlay={fileName}>
        <div
          className="text-base truncate max-w-40 md:max-w-xs"
          title={fileName}
        >
          {fileName}
        </div>
      </Tooltip>
      <div className="text-sm ">
        <div>Size: {prettyBytes(fileSize)}</div>
        <div>Rows: {numberOfRows}</div>
        <div>Columns: {numberOfColumns}</div>
      </div>
    </div>
  )
}

export default FileInfo
