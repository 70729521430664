import { Route } from 'react-router'
import { dataSourcesPaths } from 'app/navigation/paths'
import CustomSwitch from 'app/router/Switch'
import cx from 'classnames'

import Drawer from 'common/components/drawer/Drawer'
import ErrorPage from 'common/components/error/ErrorPage'
import Footer from 'common/components/layout/Footer'
import Loader from 'common/components/loaders/Loader'
import PageContainer from 'common/components/PageContainer'
import { useDocumentTitle } from 'common/hooks/custom'

import useGetProductCatalogsQuery from './api/queries/useGetProductCatalogsQuery'
import CustomerAttributes from './components/CustomerAttributes'
import Header from './components/Header'
import ProductCatalog from './components/ProductCatalog'

const DataSources = () => {
  useDocumentTitle('Data sources| Jacquard')

  const productCatalogQuery = useGetProductCatalogsQuery()

  const getState = () => {
    if (productCatalogQuery.isLoading) {
      return 'loading'
    } else if (productCatalogQuery.isError) {
      return 'error'
    } else {
      return 'success'
    }
  }

  const state = getState()

  return (
    <>
      <Header />
      <Drawer.PushContainer>
        <PageContainer
          className={cx('overflow-y-auto h-full overflow-x-hidden px-6')}
        >
          {
            {
              loading: <Loader />,
              success: (
                <>
                  <CustomSwitch>
                    <Route path={dataSourcesPaths.customerAttributes}>
                      <CustomerAttributes />
                    </Route>
                    <Route path={dataSourcesPaths.productCatalog}>
                      <ProductCatalog
                        productCatalogs={productCatalogQuery.data}
                      />
                    </Route>
                  </CustomSwitch>

                  <Footer />
                </>
              ),
              error: <ErrorPage />,
            }[state]
          }
        </PageContainer>
      </Drawer.PushContainer>
    </>
  )
}

export default DataSources
