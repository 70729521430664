import { useQuery } from '@tanstack/react-query'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { MachineSummary } from 'workflow/interface'

import { useAppSelector } from 'common/hooks/redux'

import {
  fetchPersonalizationResults,
  PersonalizationRegion,
  ResultsResponse,
} from '../api'
import { personalizationResultsKeys } from '../queryKeys'

type BaseVariant = {
  _id: string
  variant: string
  variant_status: string
  open_rate_uplift_machine_opens_excluded: string
  open_rate: string
  click_rate: string
  num_recipients: number
  num_opens: number
  num_clicks: number
  num_machine_opens: number
  open_uplift: string
  click_uplift: string
  open_rate_excluded_machine_opens: string
}

type ControlVariant = BaseVariant & { ownsl: true }

export type VariantResult = BaseVariant | ControlVariant

export type PersonalizationResults = {
  personalizedVariants: VariantResult[]
  machineSummary: MachineSummary
}

const statusOrder = ['live', 'approved', 'pending', 'dropped']

const useGetResultsQuery = ({
  personalizationId,
  personalizationRegion,
  resultType,
}: {
  personalizationId: string | undefined
  personalizationRegion: PersonalizationRegion | undefined
  resultType: 'mature' | 'raw'
}) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const flags = useFlags()

  const query = useQuery<ResultsResponse, unknown, PersonalizationResults>(
    personalizationResultsKeys.variants({
      accountId,
      personalizationId,
      resultType,
      personalizationRegion,
    }),
    () =>
      fetchPersonalizationResults({
        accountId,
        personalizationId,
        resultType,
        ...(flags.switchToRegionalizedData && {
          personalizationRegion: personalizationRegion?.toLocaleLowerCase(),
        }),
      }),
    {
      enabled: !!accountId && !!personalizationId && !!personalizationRegion,
      refetchOnWindowFocus: false,
      select: ({ items, opensSummary }) => ({
        personalizedVariants: items
          .map((item) => {
            return {
              _id: item.variantId ?? '',
              variant: item.variantText ?? '',
              variant_status: item.variantStatus ?? '',
              open_rate_uplift_machine_opens_excluded:
                item.openUpliftMachineOpensExcluded ?? '',
              open_rate: item.openRate ?? '',
              click_rate: item.clickRate ?? '',
              num_recipients: item.sends ?? 0,
              num_opens: item.opens ?? 0,
              num_clicks: item.clicks ?? 0,
              num_machine_opens: item.machineOpens ?? 0,
              open_uplift: item.openUplift ?? '',
              click_uplift: item.clickUplift ?? '',
              open_rate_excluded_machine_opens:
                item.openRateExcludedMachineOpens ?? '',
              ownsl: item.isHoldout ?? false,
            }
          })
          .sort((a, b) => {
            if (a.ownsl && !b.ownsl) {
              return -1
            }
            if (!a.ownsl && b.ownsl) {
              return 1
            }

            const statusA = a.variant_status
            const statusB = b.variant_status

            return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB)
          }),
        machineSummary: {
          total_machine_opens: opensSummary.totalMachineOpens ?? 0,
          total_opens: opensSummary.totalOpens ?? 0,
          total_other_opens: opensSummary.totalOtherOpens ?? 0,
          other_opens_percent: opensSummary.otherOpensPercent ?? '',
          machine_opens_percent: opensSummary.machineOpensPercent ?? '',
        },
      }),
    }
  )

  return query
}

export default useGetResultsQuery
