import { createContext, useContext } from 'react'

interface IntegrationContextType {
  hasIntegrationConfiguration: boolean
  cepIntegrationId?: string
}

export const IntegrationContext =
  createContext<IntegrationContextType | undefined>(undefined)

export const useHasIntegrationContext = () => {
  const context = useContext(IntegrationContext)
  if (context === undefined) {
    // if the context is not available, it means this is a new project then we can set it as true
    return { hasIntegrationConfiguration: true }
  }
  return context
}
