import { generatePath } from 'react-router-dom'
import { dataSourcesPaths } from 'app/navigation/paths'

import { queryClient } from 'common/api'
import { errorToast, successToast } from 'common/components/toastNotification'
import { useAppDispatch } from 'common/hooks/redux'
import { keys } from 'features/admin/dataSources/api/queryKeys'
import {
  fileProcessError,
  fileProcessSuccess,
} from 'features/admin/dataSources/store/dataSourceSlice'
import useDataIngestionWebsocketStatus, {
  WsResponse,
} from 'features/admin/dataSources/useDataIngestionWebsocketStatus'

const useDataIngestionStatusNotification = () => {
  const dispatch = useAppDispatch()

  const handleSuccess = ({
    accountId,
    wsResponse,
  }: {
    accountId: string
    wsResponse: WsResponse
  }) => {
    dispatch(fileProcessSuccess())
    successToast(
      <div>
        File {wsResponse.filename} has been successfully processed |{' '}
        <a href={generatePath(dataSourcesPaths.productCatalog, { accountId })}>
          Show me
        </a>
      </div>,
      { autoClose: false }
    )
    queryClient.invalidateQueries([keys.allProductCatalogQueries])
  }

  const handleFailure = ({
    accountId,
    wsResponse,
  }: {
    accountId: string
    wsResponse: WsResponse
  }) => {
    dispatch(fileProcessError())
    errorToast(
      <div>
        File {wsResponse.filename} process un-successful |{' '}
        <a href={generatePath(dataSourcesPaths.productCatalog, { accountId })}>
          {' '}
          Show me
        </a>
      </div>,
      { autoClose: false }
    )
  }

  useDataIngestionWebsocketStatus({
    topic: 'product',
    onSuccess: handleSuccess,
    onFailure: handleFailure,
  })
}

export default useDataIngestionStatusNotification
