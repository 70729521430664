import { ReactNode } from 'react'
import cx from 'classnames'

type Props = {
  onClick: () => void
  children: ReactNode
  className?: string
  'data-testid'?: string
}

const ListWidgetItem = ({
  onClick,
  children,
  className,
  'data-testid': dataTestId,
}: Props) => {
  return (
    <li
      data-testid={dataTestId}
      className={cx(
        'flex px-4 py-2 break-words group hover:bg-coolGray-50',
        className
      )}
    >
      <button onClick={onClick} className="flex ml-2 text-left gap-1">
        {children}
      </button>
    </li>
  )
}

export default ListWidgetItem
