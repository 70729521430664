import { ReactNode } from 'react'
import cx from 'classnames'

interface Props {
  children: ReactNode
  className?: string
}

const MilestoneContainer = ({ children, className = '' }: Props) => (
  <div
    className={cx(
      'bg-gold-100 px-5 py-4 rounded-sm w-full min-h-25',
      className
    )}
  >
    {children}
  </div>
)

export default MilestoneContainer
